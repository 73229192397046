/**
 * Created by Jelmer on 29-5-2016
 * deze controller vangt de events uit de templatepicker op.
 * $uibModalInstance injectable to access the modal instance. - zodat we $uibModalInstance.close() kunnen gebruiken om het scherm
 * te sluiten via een knop of in een andere functie.
 */
dynalearn.controller('templatePickerController', ['$scope', '$uibModalInstance', '$translate', 'sihwlog', 'data',
    function ($scope, $uibModalInstance, $translate, sihwlog, data) {
        var $log = sihwlog.logLevel('warn');
        //we splitsen de templates op norm of niet

        $scope.picklist = {
            templates: [],
            normmodels: []
        };
        $scope.sublist = 'templates';

        data.templates.forEach(function (t) {
            $scope.picklist[t.norm ? 'normmodels' : 'templates'].push(t);
        });

        $scope.sublist = "-"; //standaard niets


        $scope.selected = false;

        /**
         * Tabkiezer
         * @param sl
         */
        $scope.setSublist = function (sl) {
            $scope.sublist = sl;
        }

        $scope.openTemplate = function () {
            if ($scope.selected) {
                $uibModalInstance.close($scope.selected); //geven deze terug
            }
            else {
                newModel();
            }
        };

        function newModel() {
            $uibModalInstance.close({
                id: false,
                titel: $translate.instant("NEWMODEL")
            }); //geen een leeg model terug met de defaulttitle
        }

        $scope.newModel = newModel; //ook in scope

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        /**
         * Geklikt op regel met model - we selecteren. Als laden true is laden we ook (dubbelklik)
         * @param $event
         * @param model
         * @param laden
         */
        $scope.clickTemplate = function ($event, template, laden) {
            if (laden) {
                //sluiten en gaan
                $uibModalInstance.close(template);
                return;
            }
            $scope.selected = template;

        }

    }]);
