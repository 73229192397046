/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 17-8-2016
 * Time: 21:08
 *
 * Nested controller voor de code rond het modelbouwen. Werkt veel samen met parent via de parentscope
 * en de model-service. Publieke interface op publicI.i('modelctrl').
 *
 * Dit zou een service moeten zijn met een directive voor het canvas
 *
 * De menu-elementen in de html zitten niet binnen de div van deze controller, maar worden gecloned en $compiled met onze scope.
 *
 *
 * Alle voor ons relevante elementen zijn nodes
 *
 * Bij nodes slaan we het volgende op:
 *
 * In de DATA
 * - het type
 * - de nodename
 * - id van parent (we gebruiken parentid, want parent wordt intern door cy gebruikt voor compound)
 * - ids van children (childids)
 * - automovegroup
 * - bij QS-element: isZero
 * - id van bijbehorende afgeleide of qs (bij qs-elementen)
 * - bij relaties: from en to (een id)
 * - ballondata voor docentenfeedback (tekst, auteur)
 *
 * In de scratch
 * - de definitie (voor snelheid)
 * - een array met ids van qtips, voor weghalen
 * - werkdata zoals prevpos *
 *
 */

dynalearn.controller("cytocanvas.modelview",
    ['$scope', '$rootScope', '$translate', '$templateCache', '$compile', '$timeout', 'api', 'sihwlog', 'publicI', 'cytoscape', 'model', 'elementService', '$uibModal', 'sihwconfirm',
        function ($scope, $rootScope, $translate, $templateCache, $compile, $timeout, api, sihwlog, publicI, cytoscape, model,
                  elementService, $uibModal, sihwconfirm) {
            let log = sihwlog.logLevel('debug');

            let cy = null; //de cytoscape-instance met canvas
            let currentAddAction = null; //de toe te voegen actie
            let savelock = 0; //semafore voor tijdens opbouwen graaf obv model: dan niet saven, zie lockSave...

            let pub = {}; //dit wordt onze public interface
            let simctrl; //de simulatiecontroller

            //paar helpers
            //filter voor alle ineqs
            let q_ineqfilter = '[type="q_ineq_lt"],[type="q_ineq_lte"],[type="q_ineq_eq"],[type="q_ineq_gte"],[type="q_ineq_gt"]'; //selector
            let d_ineqfilter = '[type="d_ineq_lt"],[type="d_ineq_lte"],[type="d_ineq_eq"],[type="d_ineq_gte"],[type="d_ineq_gt"]'; //selector
            let ineqfilter = q_ineqfilter + ',' + d_ineqfilter;

            /**
             * Init canvas, scope en registreer onze public interface. Aangeroepen onderaan de functie
             */
            function init() {
                $scope.cy = cy = cytoscape.lib({
                    container: $('#cytocanvas')[0],
                    layout: {
                        name: 'preset'
                    },
                    // interaction options:
                    /*				minZoom: 0.3,
                     maxZoom: 4,*/
                    zoomingEnabled: true,
                    userZoomingEnabled: true,
                    // enableSelectUsingLabel: true, //bestaat niet meer?
                    panningEnabled: true,
                    userPanningEnabled: true,
                    boxSelectionEnabled: false,
                    selectionType: 'single',
                    touchTapThreshold: 8,//default 8 (sane value -Larger values will almost certainly have undesirable consequences.)
                    desktopTapThreshold: 10,//default 4 (sane value - Larger values will almost certainly have undesirable consequences.)
                    autolock: false,
                    autoungrabify: false,
                    autounselectify: false,
                    //hideEdgesOnViewport: true,
                    hideLabelsOnViewport: true,
                    motionBlur: true, //optimalisatie
                    textureOnViewport: true, //optimalisatie bij pan/zoom
                    //wheelSensitivity:0.4,
                    pixelRatio: 'auto',
                    ready: function () {
                        /*onze eigen styling voor cytoscape inladen en setten. check
                         * cytoscape hanteert andere css rules
                         * kijk voor info op: http://js.cytoscape.org/#core/style en http://js.cytoscape.org/#style
                         */
                        //let op: cy is nog niet gezet als deze sync wordt uitgevoerd, we gebruiken this
                        let self = this;
                        $.ajax({
                            type: 'GET',
                            url: "content/style/cytostyle.cytocss",
                            cache: false
                        }).done(function (response) {
                            self.style(response.toString()).update();
                        });
                    }
                });

                //features vanuit onze algemene cytoscape-service: automove
                cytoscape.initFeatures(cy);

                initCyEvents(); //afsplitsing

                //scopevars

                /*
                Conditieset:
                conditionele_tags: object van alle in het geladen model beschikbare conditionele tags. Ze worden niet apart in het model opgeslagen, maar alleen als eigenschap van nodes. Ze verdwijnen dus als alle nodes met die tag weg zijn en het model wordt herladen. Keys zijn de tags, values is of ze momenteel zichtbaar zijn
                condtag: de intern actieve tag. Object {tag: <naam>, state: <cond/cons>}. Voor toevoegcode en ng-show e.d. Null als niets.
                conditional_open: ui. Menu is open of niet.
                 */
                $scope.conditionele_tags = {}; //de in het geladen model beschikbare conditionele tags plus of ze nu zichtbaar zijn
                $scope.condtag = null; //anders {tag: ..., state: <cond/cons>}

                //simfeedback
                $scope.simfeedback = new Simfeedbackengine(log, model, cy, api, elementService);

                //foutmodel uit normmatch uitgewerkt
                $scope.foutbeschrijving = []; //altijd een array
                $scope.selectedfout = null; //er is geen fout

                //ballonnen met feedback van de docent
                $scope.ballonnen = []; //zie updateBallondisplay

                //en de scope-events
                initScopeEvents();

                //we exporteren onze publieke functies in de 'modelctrl' publicI-interface
                publicI.registerFromScope('modelctrl', $scope, pub);

                //we luisteren naar samenwerkberichten, want bij samenwerking worden acties altijd via de leider uitgevoerd
                $scope.$on('api.notify.samenwerkbericht', function (_e, data) {
                    onSamenwerkbericht(data);
                });

                //we luisteren naar relevante modelchanged events van clients waarmee we samenwerken
                //en de notify van een geregistreerde wijziging
                //soms is dat een reload
                $scope.$on('api.notify.modelchanged', function (_e, data) {
                    //pas als we klaar zijn met saven kijken of we er iets aan hebben
                    model.onNotSaving().then(function () {
                        onSamenwerkenModelchanged(data);
                    });

                });

                $scope.$on('api.notify.clientOnline', function () {
                    //even centreren zodat het samenwerken logischer voelt? (Kan denk ik weg)
                    cy.center();
                });

                //initialiseer de simctrl zodra hij er is
                publicI.when('simctrl').then(_ => {
                    simctrl = publicI.i('simctrl')
                });
            }

            /**
             * Initialiseer de .on events
             */
            function initCyEvents() {

                /**
                 * Handle tap op node
                 */
                cy.on('tap', 'node', function (_evt) {
                    //buiten de scope om, want in canvas, dus:
                    let $this = this;
                    log.debug(this);
                    if (log.logtOpLevel('debug')) {
                        logMapping(this); //log de foutinterpretatiemapping
                    }
                    $scope.$apply(function () {
                        resetFoutbeschrijving();
                        //heeft hij al een nodemenu?
                        if ((!$this.scratch('nodemenu')) && (!inAddRelation()) && (!$this.hasClass('simulation'))) {
                            createNodeMenu($this); //maak en toon
                        }
                        checkAddAction($this);
                    });
                });

                /**
                 * Handle tap op canvas - we checken of dit toch niet in de boundingbox van een node valt ivm klik op label
                 */
                cy.on('tap', function (event) {
                    //buiten de scope om, want in canvas, dus

                    // log.debug(model && model.samenwerking);

                    let p = event.position;
                    //bubblecheck
                    if (event.target !== cy) {
                        //eventueel al afgehandeld
                        return;
                    }

                    //check of dit toch niet in de bb van een node valt (klik ok whitespace in label, bijvoorbeeld)
                    //loop over de nodes
                    let node = null;
                    cy.nodes().forEach(function (ele) {
                        let box = ele.boundingBox();
                        if (box.x1 < p.x && box.x2 > p.x && box.y1 < p.y && box.y2 > p.y) {
                            node = ele;
                            return false; //break
                        }
                    });
                    if (node) {
                        node.emit('tap');
                        return;
                    }
                    //anders gewoon canvas
                    $scope.$apply(function () {
                        resetFoutbeschrijving();
                        let tapPoint = event.position;
                        //checken of we een node moeten toevoegen of dat er gewoon op t canvas is gedrukt? bijv om een actie te annuleren.
                        if (currentAddAction) {
                            //werk aan de winkel
                            if (inAddRelation()) {
                                //die wordt natuurlijk gecancelled, want klik in het niets
                                cancelAddAction();
                                hideSubmenu(); //ook weg
                                return;
                            }
                            //we hebben iets toe te voegen
                            planAddNode({x: tapPoint.x, y: tapPoint.y});
                        }
                    });
                });

                //jquery voor een tap in het niets van een qtip-menu, dat sturen we ook door naar de cy
                $('body').on('mousedown touchstart', '.dynalearnNodeMenu .qtip-content', function (e) {
                    //is het echte target hetzelfde als de qtip-content, dan is het lege content
                    if (this === e.target) {
                        //herbereken de positie
                        let pos = {},
                            offset = $(cy.container()).offset();
                        pos.x = e.pageX - offset.left;
                        pos.y = e.pageY - offset.top;
                        //emit een eventobject. Ongedocumenteerd dat het ook een heel object kan zijn
                        //gaat door de new Event contructor van cytoscape heen
                        cy.emit({
                            type: 'tap',
                            position: cy.renderedToModelCoords(pos), //uit onze cytoscape service
                            originalEvent: e.originalEvent
                        });

                        //sluit de tooltip, dat kan via de this (hopelijk)
                        let tooltip = $(this).closest('.dynalearnNodeMenu');
                        tooltip.qtip('api').hide();
                    }
                });

                /**
                 * wanneer de node selectie verliest hiden we de deleteknop en het submenu
                 */
                cy.on('unselect', 'node', function () {
                    hideSubmenu();
                });

                cy.on('tap', 'edge', function () {
                    if (log.logtOpLevel("debug")) {
                        logMapping(this); //log de foutinterpretatiemapping
                    }
                    resetFoutbeschrijving();
                    log.debug(this.json().classes);
                    log.debug(this.data());
                });

                cy.on('select', 'node', function () {
                    //this.updateVisual(false);
                    // hideSubmenu();
                });

                //automove
                cy.on('endAutomove', function (_e, data) {
                    //cy.on('endAutomove', 'node', function (e,data) {
                    repositionEdgeHandles();
                    ///we loopen sowieso even over de verplaatste nodes, om te zien of we moeten opslaan
                    //kunnen we voor het samenwerken ook de acties bepalen
                    let actie = null;
                    let nietsimulatiemove = false;

                    if (data && data.mainnode) {
                        //samenwerken
                        //posities gaan iets anders dan "echte" acties
                        //leiders / lokaal saven gelijk en sturen het naar volgers om uit te voeren
                        //volgers hebben het nou eenmaal ook al gedaan, en sturen het naar leiders om verder rond te sturen (dus krijgen het ook weer terug)
                        //feitelijk sturen zowel leiders als volgers dus een samenwerkbericht
                        //ook over simulatie-objecten, want die hebben repliceerbare id's

                        actie = {
                            actie: 'positie',
                            posities: []
                        };

                        actie.posities.push({
                            id: data.mainnode.data('id'),
                            pos: data.mainnode.position()
                        });
                        if (!data.mainnode.hasClass("simulation")) {
                            nietsimulatiemove = true; //we hebben een niet simulatiemove
                        }

                        if (data.movegroup) {
                            data.movegroup.forEach(function (node) {

                                actie.posities.push({
                                    id: node.data('id'),
                                    pos: node.position()
                                });
                                if (!(node.hasClass("simulation") || node.data('type') === 'edgeHandle')) {
                                    nietsimulatiemove = true; //een echte node in het model
                                }
                            });
                        }

                        if ((model.werkmodus !== 'lokaal') && actie.posities.length) {
                            model.samenwerkbericht([actie]);
                        }
                    }

                    //positie opslaan als we niet volgen
                    //maar alleen als we iets hebben gedaan dat niet met simulatie te maken had
                    if (nietsimulatiemove && model.werkmodus !== 'volg') {
                        saveToModel(false); //niet relevant
                    }
                });

                //verwijderen van een edge: even checken of er een edgehandle aan zit
                cy.on('remove', 'edge', checkEdgeHandleOnDeleteEdge);
            }

            /**
             * Init events op onze scope, dus van de parent of rootscope
             */
            function initScopeEvents() {
                //en de modelevents
                $scope.$on('model.changed', reloadModel);

                //check classes en samenwerking als modelprops gewijzigd zijn
                $scope.$on('model.propsChanged', onModelpropsChanged);

                //en als de interpretatie wijzigt
                $scope.$on('model.norminterpretatieChanged', _ => {
                    updateFoutweergave();
                });

                //we willen bij bij samenwerking ook zorgen dat de ux klopt
                $scope.$on('model.werkmodusChanged', syncSamenwerkUX);
                $scope.$on('api.notify.clientOnline', syncSamenwerkUX);

            }

            //pubfuncties voor start en stop van simulatie
            /**
             * Doe alles wat nodig is bij het openen van het simulatiescherm
             */
            function startSimulatie() {
                //check de simulatiefeedback, en maak het zichtbaar
                runSimfeedback();
            }

            pub.startSimulatie = startSimulatie;

            /**
             * Doe alles wat nodig is bij het sluiten van de simulatie
             */
            function stopSimulatie() {
                clearSimfeedback();
            }

            pub.stopSimulatie = stopSimulatie;

            /**
             * lock het saven van het model uit tijdens de handeling in fn
             * @param {function} fn
             */
            function lockSave(fn) {
                savelock++;
                let tothrow = null;
                try {
                    fn();
                } catch (_e) {
                    tothrow = _e;
                }
                savelock--;
                if (tothrow) {
                    throw(tothrow);
                }
            }

            /**
             * Reageer op model.changed event: het model is opnieuw geladen of er is een nieuw model. We beginnen dus opnieuw
             * @param {event} _event
             * @param {Object} changedArgs bevat .other: die is true als er een ander model is geladen, anders false, en data: de modeldata
             */
            function reloadModel(_event, changedArgs) {
                lockSave(function () { //niet saven, want we gaan heropbouwen op basis van modeldata
                    let graph = changedArgs.data;
                    log.debug(`** reloadModel`, graph);
                    if (!(graph.meta && graph.cy && graph.nodes && graph.model)) {
                        log.error("Missende data in opgeslagen graph", graph);
                        return;
                    }
                    cy.batch(function () {
                        clearSingleSelection();
                        cancelAddAction(); //wat we ook aan het doen waren
                        hideSubmenu();
                        cy.json({elements: []}); //alles weg, anders mapt cy op nieuwe elementen met zelfde id
                        //conditiesets
                        //in modellen die na 21-3-2021 zijn opgeslagen zitten ze in de modeldata
                        //in oudere modellen alleen expliciet in de data van de nodes
                        //we werken voor de zekerheid met allebei
                        //undo/redo werkt alleen goed in de nieuwe opzet

                        $scope.conditionele_tags = graph.condtags || {}; //overnemen als het er is

                        $('.qtip').remove();
                        if (changedArgs.other) {
                            //dan is er een heel ander model en zetten we de zoom:
                            cy.json({
                                zoom: (graph.cy && graph.cy.zoom) || 0.5
                            });
                        }

                        //loop over de nodes
                        $.each(graph.nodes, function (id, node) {
                            //we vinden de data in het model
                            node.data = graph.model.elements[id];

                            //FIXES

                            //missend type
                            if (!(node.data.type && node.data.type !== 'noElement')) {
                                //niet maken
                                log.warn('Skip node zonder type', node);
                                return; //continue
                            }

                            //test: skip edgehandles, die hergenereren we wel bij het maken van de edges
                            if (elementService.isSubtypeOf(node.data.type, 'edgeHandle')) {
                                return; //volgende
                            }

                            //missende parent
                            if (node.data.parentId && (!graph.model.elements[node.data.parentId])) {
                                log.warn('Skip element met missende parent', node);
                                return;
                            }

                            //missende argumenten bij een relatie
                            if ((node.data.from && (!graph.model.elements[node.data.from])) ||
                                (node.data.to && (!graph.model.elements[node.data.to]))) {
                                log.warn('Skip relatie met missend argument', node);
                                return; //continue
                            }

                            //classes worden altijd opnieuw gedaan
                            node.classes = nodeClasses(node.data.type);
                            //en het modelleerniveau ook
                            node.data.mln = model.effectief_modelleerniveau;


                            //definitie halen we wel als nodig, zie definition()
                            //aanmaken. Qtips doen we in een latere loop

                            let newnode = cy.add(node);

                            //speciaal: niet opgevangen in nodeclasses
                            //afgesproken met Bert Bredeweg d.d. 7-2-2016:
                            //als één van de args een calc is, laten we het geheel staan in simulate

                            if (elementService.isSubtypeOf(node.data.type, 'relation')) {
                                let starttype = graph.model.elements[node.data.from].type;
                                let endttype = graph.model.elements[node.data.to].type;
                                if (elementService.isSubtypeOf(starttype, 'calc') ||
                                    elementService.isSubtypeOf(endttype, 'calc')
                                ) {
                                    newnode.removeClass('hide_in_simulate');
                                }
                            }

                            //conditionele tags?
                            if (node.data.condtag) {
                                if (!($scope.conditionele_tags.hasOwnProperty(node.data.condtag))) {
                                    $scope.conditionele_tags[node.data.condtag] = false; //nu wel, maar standaard niet zichtbaar
                                }
                            }

                            updateNodelabel(newnode); //toon label
                        });

                        //hergenereer alle edges, we gebruiken geen edges uit een opgeslagen model
                        cy.nodes().forEach(function (node) {
                            if (!node.data('type')) {
                                log.warn('Node zonder type', node.data);
                                return true;
                            }
                            let nodetype = node.data('type');
                            let nodedef = node.definition();

                            if (node.data('parentId') && nodedef.connecttoparent) {
                                let parent = cy.getElementById(node.data('parentId'));
                                let edgetype = 'parentchild'; //gewoonlijk
                                if (
                                    (elementService.isSubtypeOf(parent.data('type'), 'quantity_space_element') ||
                                        elementService.isSubtypeOf(parent.data('type'), 'derivative_element'))
                                    &&
                                    (elementService.isSubtypeOf(node.data('type'), 'quantity_space_element') ||
                                        elementService.isSubtypeOf(node.data('type'), 'derivative_element'))
                                ) {
                                    edgetype = 'normal';
                                }

                                addEdgeFromTo(cy.getElementById(node.data('parentId')), node, edgetype, "model");
                            }

                            //is dit een relatie?
                            if (elementService.isSubtypeOf(nodetype, 'relation')) {
                                //dus de relatieedges
                                // lijnen trekken als de relatie geplaatst is
                                addEdgeFromTo(cy.getElementById(node.data('from')), node, "source", "model");
                                addEdgeFromTo(cy.getElementById(node.data('to')), node, "target", "model");
                            }
                        });

                        //conditiesets
                        //de geselecteerde condtag + state nog zetten
                        $scope.condtag = graph.condtag || null;

                        //conditional_open laten we zoals het was

                        //conditionele classes in één klap toevoegen
                        updateConditieclasses();

                        //en de normclasses
                        updateFoutweergave();

                        //simfeedback
                        clearSimfeedback();

                        //ballonnen
                        updateBallondisplay();

                    }); //einde batch, als niet alles getekend, dan de addnode maar weer buiten de batch (add node ging niet altijd goed in de batch)

                    cy.resize(); //voor de zekerheid
                    repositionEdgeHandles(); //regel de edgehandles
                    //alleen bij een ander model dan waar we mee bezig waren rommelen we met de panning

                    cy.forceRender();
                    if (changedArgs.other) {
                        cy.fitToMaxZoom(3, true); //alleen als nodig
                        cy.center();
                    }
                });
            }

            /************************ ACTIES PLANNEN ************************************/

            /*
            acties gaan tweestaps: via plan... worden ze bekeken, zie hieronder.
            Afhankelijk van de werkmodus in een eventuele samenwerking gaan ze dan direct of via de leider van de samenwerking
            Dat beslissen we hier, en dan laten we de actie eventueel uitvoeren via een interne functie, die ook gebruikt wordt voor binnenkomende acties
             */

            /**
             * Plan het plaatsen van een node die al geselecteerd is in een menu (currentAction)
             * @param position
             */
            function planAddNode(position) {
                if (!currentAddAction) {
                    return false; //niets te doen
                }
                let addThis = currentAddAction;
                cancelAddAction();

                if (model.werkmodus === 'volg') {
                    model.samenwerkbericht([{
                        actie: "addNode",
                        position: position,
                        node: addThis.node ? addThis.node.id() : null,
                        type: addThis.type,
                        data: addThis.data,
                        useCondtag: $scope.condtag //zodat het ook bij ux uit sync klopt
                    }]);
                } else {
                    //uitvoeren
                    lokaalAddNode(position, addThis);
                }
                clearSingleSelection(); //we doen net alsof het is uitgevoerd
            }

            /**
             * Plan het toevoegen van een relatie, lokaal of via leider
             * @param van
             * @param naar
             * @param type
             */
            function planAddRelation(van, naar, type) {
                //aanroeper heeft al cancelAddAction gedaan enzo
                if (model.werkmodus === 'volg') {
                    model.samenwerkbericht([{
                        actie: 'addRelation',
                        van: van.id(),
                        naar: naar.id(),
                        type: type,
                        useCondtag: $scope.condtag //zodat het ook bij ux uit sync klopt
                    }
                    ]);
                } else {
                    //doen we ook async, omdat dit allemaal runt vanuit een tap-event, en die wordt nog gevolgd door select
                    //niet echt heel erg nodig, maar wel handig zo
                    $timeout(function () {
                        lokaalAddRelation(van, naar, type, false, true);
                    });
                }
            }

            /**
             * Wissel de argumenten van een element om. Ux heeft gecheckt of het mag
             * @param event
             */
            function switchArgs(event) {
                cancelAddAction(); //cancel alles
                let node = nodeForMenuItem($(event.target));

                if (model.werkmodus === "volg") {
                    //niet uitvoeren, maar melden
                    model.samenwerkbericht([{
                        actie: 'switchArgs',
                        node: node.id()
                        //geen invloed op conditionele sets
                    }]);
                } else {
                    lokaalSwitchArgs(node);
                }
                clearSingleSelection();
                hideItemMenu(event);
            }

            $scope.switchArgs = switchArgs;

            /**
             * Wijzig een elementtype in een ander type. Ux heeft gecheckt of het mag
             * @param event
             * @param data
             */
            function changeAction(event, data) {
                cancelAddAction(); //cancel alles
                let node = nodeForMenuItem($(event.target));
                let type = data.type;

                if (model.werkmodus === "volg") {
                    //niet uitvoeren, maar melden
                    model.samenwerkbericht([{
                        actie: 'changeNode',
                        node: node.id(),
                        type: type
                        //geen invloed op conditiesets
                    }]);
                } else {
                    lokaalChangeNode(node, type);
                }
                clearSingleSelection();
                hideItemMenu(event);
            }

            $scope.changeAction = changeAction;

            /**
             * Plan het toggelen of toevoegen van een waarde. Verzoek van volger is atomische actie. Leider doet delete/addNode acties
             * @param node
             */
            function planToggleQValue(node) {
                if (model.werkmodus === 'volg') {
                    model.samenwerkbericht([{
                        actie: 'toggleQValue',
                        node: node.id(),
                        useCondtag: $scope.condtag //zodat het ook bij ux uit sync klopt
                    }]);
                } else {
                    lokaalToggleQValue(node);
                }
            }

            /**
             * Plan het toggelen of toevoegen van een d-waarde. Verzoek van volger is atomische actie. Leider doet delete/addNode acties
             * @param node
             */
            function planToggleDValue(node) {
                if (model.werkmodus === 'volg') {
                    model.samenwerkbericht([{
                        actie: 'toggleDValue',
                        node: node.id(),
                        useCondtag: $scope.condtag //zodat het ook bij ux uit sync klopt
                    }]);
                } else {
                    lokaalToggleDValue(node);
                }
            }

            /**
             * Plan het toevoegen van een quantity space element bij een qs
             * @param node
             * @param topOrBottom
             */
            function planNewQSE(node, topOrBottom) {
                if (model.werkmodus === 'volg') {
                    //naar de leider
                    model.samenwerkbericht([{
                        actie: 'addQSE',
                        positie: topOrBottom,
                        qs: node.id() //de qs
                        //nooit in condset
                    }]);
                } else {
                    lokaalNewQSE(node, topOrBottom);
                }
            }

            /**
             * Plan het zetten van een qs-punt als zero
             * @param node
             */
            function planQSPointZero(node) {
                if (model.werkmodus === 'volg') {
                    //naar de leider
                    model.samenwerkbericht([{
                        actie: 'qsZero',
                        node: node.id() //qspoint
                        //nooit in condset
                    }]);
                } else {
                    lokaalQSPointZero(node);
                }
            }

            /**
             * Plan het toggelen van een exogeen. Volger stuurt atomische actie, leider stuurt delete / create
             * @param node
             * @param exoType
             */
            function planToggleExogeen(node, exoType) {
                if (model.werkmodus === 'volg') {
                    model.samenwerkbericht([{
                        actie: 'toggleExogeen',
                        node: node.id(),
                        type: exoType
                        //nooit in condset
                    }]);
                } else {
                    lokaalToggleExogeen(node, exoType);
                }
            }

            /**
             * Plan het toggelen van een allewaarden. Volger stuurt atomische actie, leider stuurt delete / create
             * @param node
             */
            function planToggleAllewaarden(node) {
                if (model.werkmodus === 'volg') {
                    model.samenwerkbericht([{
                        actie: 'toggleAllewaarden',
                        node: node.id()
                        //nooit in condset
                    }]);
                } else {
                    lokaalToggleAllewaarden(node);
                }
            }

            /**
             * Plan het wijzigen van de naam van een node
             * @param node
             * @param naam
             */
            function planWijzigNaam(node, naam) {
                if (model.werkmodus === 'volg') {
                    //via de leider
                    model.samenwerkbericht([{
                        actie: 'wijzigNaam',
                        node: node.id(),
                        naam: naam
                        //geen invloed op condset
                    }]);
                } else {
                    lokaalWijzigNaam(node, naam);
                }
            }

            /**
             * Plan het wijzigen van delete
             * @param node
             */
            function planDelete(node) {
                //hide de deletebuttons
                $('.nodeDeletebtn').hide();

                if (model.werkmodus === 'volg') {
                    //uitbesteden aan leider
                    model.samenwerkbericht([{
                        actie: 'delete',
                        node: node.id()
                        //gaat vanzelf goed in condset
                    }]);
                } else {
                    lokaalDelete(node);
                }
            }

            /************************ verwerken van samenwerkberichten **************************/
            /**
             * Data.acties bevat 0 of meer acties, die in volgorde afgehandeld zouden moeten worden om een kloppend geheel te krijgen
             * @param data
             */
            function onSamenwerkbericht(data) {
                //we wachten eventuele saves even af

                //sanity
                if (data.model !== model.notifyId ||
                    model.werkmodus === 'lokaal' ||
                    model.werkmodus === data.brontype //de een moet volg zijn, de ander leid
                ) {
                    log.warn("Binnenkomend samenwerkbericht niet compatibel");
                    return;
                }
                let node; //tmpvar
                let stapnode;

                //elementen vooraf
                let hadNogGeenNodes = cy.nodes().empty();

                //berichten voor de leider
                if (model.werkmodus === 'leid') {
                    //niet weg, want het gebeurt ergens anders
                    /* hideQtips();
                     clearSingleSelection(); //geen qtip remove, want dat gaat niet goed*/
                    data.acties.forEach(function (stap) {
                        //vaak nodig
                        stapnode = stap.node ? getElement(stap.node) : null;
                        if (stapnode && stapnode.empty()) {
                            stapnode = null; //dan niet
                        }
                        //speciaal: er kan een condtag in de stap zitten zitten
                        //omdat we zeker willen weten dat de ux niet uit sync is geraakt
                        //sinds het versturen van een bericht, waardoor iets in de
                        //verkeerde condtag kan terechtkomen, checken we dat hier

                        if (('useCondtag' in stap) && ((stap.useCondtag && (!$scope.condtag)) || ((!stap.useCondtag) && ($scope.condtag)) || (stap.useCondtag && $scope.condtag && (stap.useCondtag.tag !== $scope.condtag.tag || stap.useCondtag.state !== $scope.condtag.state)))) {
                            //nu wel
                            log.warn(`SAMENWERKBERICHT - ANDERE CONDITIESET: ${stap.useCondtag.tag} ipv ${$scope.condtag ? $scope.condtag.tag : '-'}`);
                            $scope.condtag = stap.useCondtag;
                        }

                        switch (stap.actie) {
                            case 'addNode':
                                //in args zit iets wat weer moet gaan passen op lokaalAddNode
                                //we vervangen stap.node dus door het hele element
                                if (stap.node) {
                                    if (!(stap.node = stapnode)) {
                                        log.warn("addNode request op ontbrekende parentnode geskipt", stap);
                                        return;
                                    }
                                }
                                //de rest doet het wel, dus lokaalAddNode doet de rest, inclusief volgers inlichten
                                lokaalAddNode(stap.position, stap, data.afzender);
                                break;
                            case 'addRelation':
                                stap.van = getElement(stap.van);
                                stap.naar = getElement(stap.naar);
                                if (!(stap.van && stap.naar)) {
                                    log.warn('addRelation request op ontbrekende argumenten geskipt', stap);
                                    return;
                                }
                                lokaalAddRelation(stap.van, stap.naar, stap.type, false, false, data.afzender);
                                break;
                            case 'switchArgs':
                                //richting wordt gewijzigd
                                lokaalSwitchArgs(stapnode, data.afzender);
                                break;
                            case 'changeNode':
                                //een node wordt gewijzigd
                                lokaalChangeNode(stapnode, stap.type, data.afzender);
                                break;
                            case 'addQSE':
                                //voeg een QSE toe aan stap.qs
                                lokaalNewQSE(getElement(stap.qs), stap.positie, data.afzender);
                                break;
                            case 'qsZero':
                                //set de node op zero
                                if (!stapnode) {
                                    log.warn('qsZero request op ontbrekende node', stap);
                                    return;
                                }
                                lokaalQSPointZero(stapnode, data.afzender); //regelt de rest
                                break;
                            case 'toggleQValue':
                                //is er alleen bij leid. We doen het en sturen een stapel delete / create in het rond
                                if (!stapnode) {
                                    log.warn('toggleQValue request op ontbrekende node', stap);
                                    return;
                                }
                                lokaalToggleQValue(stapnode, data.afzender);
                                break;
                            case 'toggleDValue':
                                //is er alleen bij leid. We doen het en sturen een stapel delete / create in het rond
                                if (!stapnode) {
                                    log.warn('toggleDValue request op ontbrekende node', stap);
                                    return;
                                }
                                lokaalToggleDValue(stapnode, data.afzender);
                                break;
                            case 'toggleExogeen':
                                //is er alleen bij leid. We doen het en sturen delete / create in het rond
                                if (!stapnode) {
                                    log.warn('toggleExogeen request op ontbrekende node', stap);
                                    return;
                                }
                                lokaalToggleExogeen(stapnode, stap.type, data.afzender);
                                break;
                            case 'toggleAllewaarden':
                                //is er alleen bij leid. We doen het en sturen delete / create in het rond
                                if (!stapnode) {
                                    log.warn('toggleAllewaarden request op ontbrekende node', stap);
                                    return;
                                }
                                lokaalToggleAllewaarden(stapnode, data.afzender);
                                break;
                            case 'wijzigNaam':
                                //wijzig de naam van een node
                                if (!stapnode) {
                                    log.warn("wijzigNaam request op ontbrekende node geskipt", stap);
                                    return;
                                }
                                lokaalWijzigNaam(stapnode, stap.naam, false, data.afzender);
                                break;
                            case 'delete':
                                if (!stapnode) {
                                    log.warn("delete request op ontbrekende node geskipt", stap);
                                    return;
                                }
                                lokaalDelete(stapnode, false, data.afzender);
                                break;
                            case 'positie':
                                //volger heeft positie gewijzigd. Wij wijzigen mee, slaan op en sturen naar volgers
                                lokaalSetPosities(stap.posities, true);
                                break;
                            case 'undo':
                                //volger wil undo doen. Dat gaan wij dus verder regelen
                                if (model.canUndo()) {
                                    lokaalUndoRedo(false, data.afzender); //volger mee voor actionlog
                                }
                                break;
                            case 'redo':
                                //volger wil redo doen. Dat gaan wij dus verder regelen
                                if (model.canRedo()) {
                                    lokaalUndoRedo(true, data.afzender); //volger mee voor actionlog
                                }
                                break;
                            case 'modelprops':
                                //volger heeft modelprops gewijzigd. Wij wijzigen mee
                                model.setProperties(stap.props, 'volg'); //die stuur weer een broadcast, met het opgegeven argument - de bron
                                break;
                            case 'directsave':
                                //speciaal verzoek van een volger om meteen op te slaan, zodat de volger kan reloaden
                                saveToModel(true, true);
                                break;
                            case 'selectInterpretatie':
                                //volger klikt op selectinterpretatie (vraagteken). Wij zetten het in sync
                                model.selectInterpretatie(stap.interpretatieIndex);
                                break;
                            case 'resetFoutniveau':
                                //volger laat foutniveau naar 0 gaat
                                log.debug(`ACTIE resetFoutniveau`);
                                model.resetFoutniveau();
                                break;
                            case 'highlight_fout':
                                highlight_fout(stap.foutindex, stap.elementindex);
                                break;
                            case 'showConditionalTools':
                                //verzoek tot show/hide conditionals
                                showConditionalTools(stap.show);
                                break;
                            case 'setViewtag':
                                setViewtag(stap.condtag, stap.show, false, data.afzender);
                                break;
                            case 'setCondtag':
                                setCondtag(stap.condtag, stap.state, false, data.afzender);
                                break;
                            case 'changeCondtag':
                                changeCondtag(stap.oldname, stap.newname, false, data.afzender);
                                break;
                            case 'setBallon':
                                lokaalSetBallon(stap.ballon, stap.node, data.afzender);
                                break;
                            default:
                                log.warn("samenwerkingsbericht, onbekende actie", stap);
                                break;
                        }
                    });
                }
                if (model.werkmodus === 'volg') {
                    try {
                        data.acties.forEach(function (stap) {
                            stapnode = stap.node ? getElement(stap.node) : null;
                            if (stapnode && stapnode.empty()) {
                                stapnode = null; //dan niet
                            }
                            //speciaal: er kan een condtag in de stap zitten zitten
                            //omdat we zeker willen weten dat de ux niet uit sync is geraakt
                            //sinds het versturen van een bericht, waardoor iets in de
                            //verkeerde condtag kan terechtkomen, checken we dat hier

                            if (('useCondtag' in stap) && ((stap.useCondtag && (!$scope.condtag)) || ((!stap.useCondtag) && ($scope.condtag)) || (stap.useCondtag && $scope.condtag && (stap.useCondtag.tag !== $scope.condtag.tag || stap.useCondtag.state !== $scope.condtag.state)))) {
                                //nu wel
                                log.warn(`SAMENWERKBERICHT - ANDERE CONDITIESET: ${stap.useCondtag.tag} ipv ${$scope.condtag ? $scope.condtag.tag : '-'}`);
                                $scope.condtag = stap.useCondtag;
                            }

                            switch (stap.actie) {
                                case 'create':
                                    saveUndoState(); //want wij kunnen ook undoen

                                    //we gebruiken addCyNode, zodat die alles regelt met definities en edges etc.
                                    let parent = null;
                                    let parentId = stap.node.data.parentId;
                                    if (parentId) {
                                        //dan zijn wij een child
                                        parent = getElement(stap.node.data.parentId);
                                        if (parent.empty()) {
                                            //PANIEK
                                            throw('UNMATCH');
                                        }
                                    }
                                    //maak de node, en selecteer hem als wij hem oorspronkelijk maakten en selectie nodig is
                                    node = addCyNode(stap.node, parent, stap.select_in_origineel && data.originele_afzender_jij);

                                    $rootScope.$broadcast('model.contentChanged'); //gewijzigd
                                    break;
                                case 'addRelation':
                                    //ook gewoon de lokale versie
                                    stap.van = getElement(stap.van);
                                    stap.naar = getElement(stap.naar);

                                    if (!(stap.van && stap.naar)) {
                                        throw('UNMATCH');
                                    }
                                    //lokaal toevoegen en kijken of we moeten selecteren
                                    lokaalAddRelation(stap.van, stap.naar, stap.type, stap.id,
                                        stap.select_in_origineel && data.originele_afzender_jij);
                                    break;
                                case 'switchArgs':
                                    //de leider heeft arg gewijzigd
                                    //wij doen lokaal
                                    lokaalSwitchArgs(stapnode);
                                    break;
                                case 'changeNode':
                                    //leider heeft een node gewijzigd
                                    //we doen de lokale versie
                                    lokaalChangeNode(stapnode, stap.type);
                                    break;
                                case 'addQSE':
                                    saveUndoState(); //want wij kunnen ook undoen
                                    addQuantitySpaceElement(getElement(stap.qs), stap.positie, stap.select_in_origineel && data.originele_afzender_jij, stap.elementType, stap.id); //elementType bij aanmaken qs...
                                    $rootScope.$broadcast('model.contentChanged'); //gewijzigd
                                    break;
                                case 'qsZero':
                                    //kunnen gewoon de lokale doen
                                    if (!stapnode) {
                                        throw('UNMATCH');
                                    }
                                    lokaalQSPointZero(stapnode);
                                    $rootScope.$broadcast('model.contentChanged'); //gewijzigd
                                    break;

                                case 'wijzigNaam':
                                    if (!stapnode) {
                                        throw('UNMATCH');
                                    }
                                    lokaalWijzigNaam(stapnode, stap.naam, true); //alleen lokaal
                                    $rootScope.$broadcast('model.contentChanged'); //gewijzigd
                                    break;
                                case 'delete':
                                    if (!stapnode) {
                                        throw('UNMATCH');
                                    }
                                    lokaalDelete(stapnode, true); //alleen lokaal
                                    $rootScope.$broadcast('model.contentChanged'); //gewijzigd
                                    break;
                                case 'positie':
                                    //leider heeft positie gewijzigd. Wij wijzigen mee
                                    lokaalSetPosities(stap.posities, false);
                                    break;
                                case 'modelprops':
                                    //leider heeft modelprops gewijzigd. Wij wijzigen mee
                                    model.setProperties(stap.props, 'leid'); //die stuur weer een broadcast, met het opgegeven argument - de bron
                                    break;
                                case 'setModeldata':
                                    //leider stuur expliciet nieuwe data (na undo/redo bijvoorbeeld)
                                    model.setData(stap.data);
                                    break;
                                case 'norm':
                                    //nieuwe norminterpretatiedata, regelt het model
                                    log.debug(`NORM binnen`, stap)
                                    model.setNormdata(stap.interpretaties, stap.telling);
                                    break;
                                case 'selectInterpretatie':
                                    //selecteer een andere interpretatie
                                    model.selectInterpretatie(stap.interpretatieIndex, true, stap.foutniveau);
                                    break;
                                case 'resetFoutniveau':
                                    //leider zet foutniveau op 0
                                    log.debug(`ACTIE resetFoutniveau`);
                                    model.resetFoutniveau(true);
                                    break;
                                case 'highlight_fout':
                                    highlight_fout(stap.foutindex, stap.elementindex);
                                    break;
                                case 'syncConditiesets':
                                    //leider doet een full sync op conditieset-data
                                    onSyncConditiesets(stap);
                                    break;
                                case 'showConditionalTools':
                                    //verzoek tot show/hide conditionals
                                    showConditionalTools(stap.show, true);
                                    break;
                                case 'setViewtag':
                                    setViewtag(stap.condtag, stap.show, true);
                                    break;
                                case 'setCondtag':
                                    setCondtag(stap.condtag, stap.state, true);
                                    break;
                                case 'changeCondtag':
                                    changeCondtag(stap.oldname, stap.newname, true);
                                    break;
                                case 'setBallon':
                                    lokaalSetBallon(stap.ballon, stap.node);
                                    break;
                                default:
                                    //onbekende actie
                                    log.warn("samenwerkingsbericht, onbekende actie", stap.actie, stap);
                                    throw("ONBEKEND");
                            }
                        });
                        //nog geen throw, dus we slaan deze change op, zodat we bij de modelchanged kunnen checken of het klopt
                        if (data.changeId) {
                            model.samenwerkchange(data.changeId);
                        }
                    } catch (e) {
                        log.error('Error bij verwerking samenwerkingsbericht van leider', e);
                        //we wachten op een save van de master:
                        model.versie = 0; //dus altijd reloaden
                        //en dat aan de parent laten weten
                        model.samenwerkbericht([{
                            actie: 'directsave' //svp direct opslaan zodat wij kunnen reloaden
                        }])
                    }
                }
                //als er nog geen nodes waren, maar nu wel, dan centreren we even
                if ((hadNogGeenNodes) && cy.nodes().nonempty()) {
                    cy.fitToMaxZoom(3);
                    //in plaats hiervan een fit naar een maxzoom en dat centreren
                    cy.reset();
                }
            }

            /**
             * Notificatie van backend dat een client die aan hetzelfde mastermodel werkt iets gewijzigd heeft.
             * @param data
             * Dat pikken we op als we volger zijn en er iets mis is gegaan bij een samenwerkingsvericht
             */
            function onSamenwerkenModelchanged(data) {
                //voor ons?
                if (!(model.id && model.notifyId && model.notifyId === data.model)) {
                    return;
                }

                if (!(model.werkmodus === 'volg' && data.samenwerking && data.samenwerking.changes && data.samenwerking.vanVersie === model.versie)) {
                    model.reload(false);
                }
                //hier komen we dus als we een volger zijn, en we hebben samenwerkingsdata en versies kloppen
                //komen de changes van ons model overeen met de changes in de samenwerking?
                let i;
                let allesok = true;
                for (i = 0; i < data.samenwerking.changes.length; i++) {
                    if (model.changes.indexOf[data.samenwerking.changes[i]] === -1) {
                        allesok = false;
                        break; //deze mist
                    }
                }
                if (allesok) {
                    model.resetSamenwerkchanges(); //weer leeg, want die zijn bijgewerkt
                    model.versie = data.samenwerking.naarVersie;
                    if (data.samenwerkfeedback) {
                        model.setSamenwerkfeedback(data.samenwerkfeedback); //ook even bijwerken
                    }
                } else {
                    model.reload(false);
                }
            }

            /**
             * Reageer op een wijziging van modelprops.
             * Voeg classes en data aan alle nodes toe ihkv bepaalde modelproperties
             * dit zijn dezelfde dingen als bij het aanmaken van een node
             */
            function onModelpropsChanged(_e, resultaat) {
                let nodes = cy.nodes();
                nodes.data('mln', model.effectief_modelleerniveau);

                //zijn we aan het samenwerken? Dan regelen we het een en ander
                //volger heeft het trouwens gewoon uitgevoerd (want dat gebeurt elders) maar niet gesaved
                //volger moet dus naar leider sturen, als het bij deze volger is uitgevoerd Dat doen we via resultaat.notifyArg
                if (model.werkmodus === 'leid' || (
                    model.werkmodus === 'volg' &&
                    resultaat.notifyArg !== 'leid' //niet al binnengekregen van de leider
                )) {
                    //hoe dan ook doorsturen
                    model.samenwerkbericht([{
                        actie: 'modelprops',
                        props: resultaat.gewijzigd
                    }]);
                }
                saveToModel(true); //en opslaan?
            }

            /**
             * Sla de huidige graaf op in het model, die dan een save naar de api plant
             * @param {boolean} relevant Er is een inhoudelijke wijziging
             * @param {boolean} [direct] zonder vertraging opslaan
             * @returns $q promise van model.save
             *
             */
            function saveToModel(relevant, direct) {
                //we skippen saven als we in een lockSave-functie zijn
                if (savelock) {
                    return $q.reject();
                }

                //img sturen we als generator mee naar save
                return model.save(modelData(), relevant, direct, false, function () {
                    return graphJpg();
                });
            }

            /******************** Undo / redo ********************************/
            /**
             * Undo / redo gebeurt vanuit de cytocanvas controller, die het via onze publicI-interface afhandelt
             * Verzoek van volger is atomische actie, leider doet een verzoek tot harde reload
             */

            pub.undo = function () {
                if (model.werkmodus === 'volg') {
                    //naar de master
                    model.samenwerkbericht([{actie: 'undo'}]);
                } else {
                    lokaalUndoRedo(false);
                }
            };

            pub.redo = function () {
                if (model.werkmodus === 'volg') {
                    //naar de master
                    model.samenwerkbericht([{actie: 'redo'}]);
                } else {
                    lokaalUndoRedo(true);
                }
            };

            /**
             * Private - aanroepen vóór logische wijzigingen - sla op in undobuffer
             */
            function saveUndoState() {
                //dit regelt het model
                model.setUndoPoint();
            }

            /*************************** ACTIES ****************************************/
            /**
             * klik op menu-element om iets toe te voegen
             * we slaan op dat we iets gaan toevoegen, en een volgende klik op het canvas is dan het toevoegen
             * eventuele bestaande toevoegacties vervallen. Als men nogmaals op dezelfde knop klikt dan betekent dat 'cancel'
             * @param event
             * @param data
             */
            function initAddAction(event, data) {

                //skip eventuele video
                $scope.cancelVideo();
                /* de addaction bestaat uit:
                 el: het menuitem dat gebruikt is (voor weergave) als jquery
                 node: de node waarbij het menuitem hoort
                 type: type node/rel dat wordt toegevoegd
                 data: eventuele extra data
                 mode: 'node' of 'relation': wat wordt er toegevoegd?
                 argNodes: in relationmode bevat dit de mogelijke nodes voor het andere argument
                 */
                let newAction = {
                    el: $(event.target),
                    node: nodeForMenuItem($(event.target)),
                    type: data.type,
                    data: data
                };
                let prevAction = currentAddAction; //even onthouden

                //sowieso cancellen
                cancelAddAction();
                if (prevAction && prevAction.el.is(newAction.el)) {
                    //zelfde knop nogmaals, dus cancel. Dat hebben we net gedaan
                    return;
                }

                //wat is de modus?
                newAction.mode = elementService.isSubtypeOf(newAction.type, 'relation') ? 'relation' : 'node';
                if (newAction.mode === 'relation') {
                    //we moeten de mogelijke argumenten aanduiden
                    let def = elementService.getDefinition(newAction.type);
                    let startType = newAction.node.data('type'); //het type van de ene kant vd relatie, wat past aan de andere kant?

                    //mogelijke args
                    let selectors = [];
                    $.each(def.possibleargs, function (i, relarray) {
                        let endType;
                        let leftList, rightList;
                        //specials:
                        let m;
                        if ((m = relarray[0].match(/^sub:(.*)$/))) {
                            leftList = elementService.alleSubs(m[1]); //alle subs
                        } else {
                            leftList = [relarray[0]]; //alleen deze
                        }
                        if ((m = relarray[1].match(/^sub:(.*)$/))) {
                            rightList = elementService.alleSubs(m[1]); //alle subs
                        } else {
                            rightList = [relarray[1]]; //alleen deze
                        }
                        if (leftList.indexOf(startType) !== -1) {
                            endType = rightList;
                        } else if (rightList.indexOf(startType) !== -1) {
                            endType = leftList;
                        } else {
                            return; //continue
                        }
                        //endType is nu een array met 1 of meer typen:
                        $.each(endType, function (i, posType) {
                            selectors.push('[type = "' + posType + '"]');
                        });
                    });
                    let argNodes = cy.$(selectors.join(', ')); //en vind de nodes

                    //skip onszelf als mogelijk argument
                    argNodes = argNodes.not(newAction.node);
                    if (!argNodes.length) {
                        //geen argumenten
                        //het feest gaat niet door. We hebben currentAddAction nog niet gezet, dus klaar
                        hideSubmenu();
                        return;
                    }
                    //eerst alle nodes dicht
                    cy.nodes(':grabbable').addClass('addactiongrabbable');
                    cy.nodes(':selectable').addClass('addactionselectable');
                    cy.nodes().addClass('node_disable').unselectify().ungrabify();
                    //alle edges ook
                    cy.edges().addClass('edge_disable');
                    //en de andere open
                    argNodes.removeClass('node_disable').selectify();
                    newAction.argNodes = argNodes;
                }
                newAction.el.addClass('selectedMenuItem'); //toon dat deze knop nu aan staat
                currentAddAction = newAction; //die staat klaar
                //submenus dicht
                hideSubmenu(); //die skipt altijd de currentaddAction
            }

            $scope.initAddAction = initAddAction;

            /**
             * Zijn we momenteel bezig een relatie toe te voegen?
             * @returns {boolean}
             */
            function inAddRelation() {
                return !!(currentAddAction && currentAddAction.mode === "relation");
            }

            //////////////////// Ballonnen ////////////////////////////////////////////
            //editBallon is alleen beschikbaar als dat mag, dus dat gaat
            //vanzelf goed
            $scope.editBallon = function (event) {
                let node = nodeForMenuItem($(event.target));
                log.debug(`Editballoon`, node.data("label"), api.userdata);

                hideItemMenu(event); //weg met het menu
                let ballondata = node.data('ballon');
                //open de editballon, maar laat eerst even bijwerken
                $scope.ballonEdit = {
                    node: node.id(),
                    tekst: ballondata?.tekst || ""
                };
                //even focussen
                $timeout(_ => {
                    angular.element("#ballonedit").focus();
                }, 500);
            };
            /**
             * Ballonedit is klaar, met opslaanknop
             */
            $scope.setBallon = function () {
                if (!$scope.ballonEdit) {
                    return; //klopt niet
                }
                let ballon = null;
                if ($scope.ballonEdit.tekst.length) {
                    ballon = {
                        tekst: $scope.ballonEdit.tekst,
                        auteur: api.userdata.displayname,
                        auteur_id: api.userdata.userdomein
                    };
                }
                //plan het toevoegen
                if (model.werkmodus === 'volg') {
                    model.samenwerkbericht([{
                        actie: 'setBallon',
                        ballon: ballon,
                        node: $scope.ballonEdit.node
                    }]);
                } else {
                    //uitvoeren
                    lokaalSetBallon(ballon, $scope.ballonEdit.node);
                }
                $scope.ballonEdit = null;
            };

            $scope.annuleerBallonEdit = function () {
                $scope.ballonEdit = null;
            }

            $scope.wegklikBallon = function (nodeId) {
                log.debug(`wegklikBallon`, nodeId);
                if (model.werkmodus === 'volg') {
                    model.samenwerkbericht([{
                        actie: 'setBallon',
                        ballon: null,
                        node: nodeId
                    }]);
                } else {
                    //uitvoeren
                    lokaalSetBallon(null, nodeId);
                }
            };

            /**
             * Zet of reset de ballon bij een node.
             * @param ballon Bevat tekst, auteur, auteur_id
             * @param nodeId
             * @param volger
             */
            function lokaalSetBallon(ballon, nodeId, volger) {
                log.debug(`lokaalsetBallon`, ballon, nodeId, volger);
                let node = getElement(nodeId);
                if (!(node && node.nonempty())) {
                    log.warn(`lokaalSetBallon voor missende node ${nodeId}`);
                    return; //is er niet
                }
                saveUndoState(); //vlak voor het doen
                if (ballon) {
                    node.data("ballon", {tekst: ballon.tekst, auteur: ballon.auteur});
                } else {
                    node.removeData('ballon');
                }
                updateBallondisplay();
                if (model.werkmodus === 'leid') {
                    model.samenwerkbericht([{
                            actie: 'setBallon',
                            ballon: ballon,
                            node: nodeId
                        }],
                        volger //originele afzender
                    );
                }
                if (model.werkmodus !== 'volg') {
                    //loggen en opslaan
                    saveToModel(true);
                    model.logaction(ballon ? 'ballon' : 'ballon_wegklik', route(node), volger, node.data('type'), ballon);
                }
            }

            function updateBallondisplay() {
                //we doen de ballonnen via een scopefunctie en repeat
                //niet meer via qtip

                $scope.ballonnen = []; //leeg
                cy.nodes("[ballon]").forEach(node => {
                    let ballon = node.data('ballon');
                    $scope.ballonnen.push({
                        nodeId: node.id(),
                        tekst: ballon.tekst,
                        auteur: ballon.auteur
                    });
                });
                log.debug(`Ballonnen`, $scope.ballonnen);
            }

            ////////////////////////////////////////////////////////////////////////////////////////

            /**
             * hide alle submenu's
             */
            function hideSubmenu() {
                let submenu = $('.nodemenu_sub');
                submenu.each(function (sm, menu) {
                    menu = $(menu);
                    if (currentAddAction && menu.find(currentAddAction.el).length) {
                        //in dit menu zit het actieve item, dus skippen
                        return;
                    }
                    menu.hide();
                });
                $scope.openedsubmenu = null;
            }

            function checkAddAction(targetNode) {
                //tap op node, kijk of we addRelation moeten doen
                if (!inAddRelation()) {
                    cancelAddAction(); //in elk geval
                    return; //niets te doen
                }

                //mogen wij het argument zijn?
                if (currentAddAction.argNodes.anySame(targetNode)) {
                    planAddRelation(currentAddAction.node, targetNode, currentAddAction.type);
                    //en de actie is klaar
                    cancelAddAction();
                }
            }

            /**
             * cancel lopende add-acties
             */
            function cancelAddAction() {

                //weg met de visuele hint
                $('.selectedMenuItem').removeClass('selectedMenuItem');
                //alles weer selectify als ze dat waren
                cy.nodes('.addactionselectable').removeClass('addactionselectable').selectify();
                //en grabbable als ze dat waren
                cy.nodes('.addactiongrabbable').removeClass('addactiongrabbable').grabify();
                //en weer visueel aan
                cy.nodes('.node_disable').removeClass('node_disable');
                cy.edges('.edge_disable').removeClass('edge_disable');

                currentAddAction = null;
            }

            /**
             * Publiek: Reset de state van hele view
             */
            function resetState() {
                clearSingleSelection();
                $('.qtip').remove();
            }

            pub.resetState = resetState;

            /**
             * Meld de canvas dat er resized is
             */
            function resizeCanvas() {
                if (cy) {
                    cy.resize();
                    cy.resize();
                }
            }

            pub.resizeCanvas = resizeCanvas;

            /**
             * alle nodes deselecteren
             * en currentAddAction resetten
             */
            function clearSingleSelection() {
                cy.$(":selected").unselect();
                cancelAddAction();
            }

            pub.clearSingleSelection = clearSingleSelection;

            $scope.snapNorm = function () {
                //we hebben bepaalde types die via hun mapping worden gezet, en andere anders
                const mapbaar = ['topelement', 'quantity', 'quantity_space', 'derivative', 'relation']
                log.debug('snapnorm', model.norm);
                if (!model.normmatch) {
                    return;
                }
                saveUndoState();
                log.debug(model.normmatch);
                let posities = []; //alle gewijzigde posities voor samenwerken
                //we gaan alle nodes af en zetten ze goed
                //de mapping vinden we door een rechtstreekse mapping, of door een mapping via de parent
                //voor de zekerheid maken we eerst even een map van hoe een object zich verhoudt tot zijn parent: alle posities van alle nodes
                let nodepos = {};
                cy.nodes().forEach(node => {
                    nodepos[node.id()] = Object.assign({}, node.position()); //kopie, want anders beweegt het mee
                });
                let naplaatsen = []; //nodes die later geplaatst worden
                cy.nodes().forEach(node => {
                    if (!mapbaar.some(t => elementService.isSubtypeOf(node.data('type'), t))) {
                        //skippen van derivative en qs waarden. Ook exogenen worden niet nageplaatst, maar uiteindelijk op logische plek gezet. NoElement (edgeHandles en andere hulpelementen) gaan ook anders
                        if (!['derivative_element', 'quantity_space_element', 'q_exo', 'quantity_allvalues', 'noElement'].some(t => elementService.isSubtypeOf(node.data('type'), t))) {
                            //later plaatsen
                            naplaatsen.push(node);
                        }
                        return; //continue
                    }
                    let normMapping = normMappingVoor(node.id());
                    if (normMapping?.position) {
                        node.position(Object.assign({}, normMapping.position));
                        posities.push({
                            id: node.id(),
                            pos: node.position()
                        });
                    } else {
                        //via positie proberen straks
                        naplaatsen.push(node);
                    }
                });
                //dvalues en qse moeten even eerst, zodat hun waarde goed meegaat
                //plaatsen van quantity_space_elementen
                cy.nodes('[type = "quantity_space"]').forEach(qs => {
                    //de eerste plaatsen we zelf
                    let eerste = nextQsItem(qs);
                    log.debug(eerste);
                    if (eerste.nonempty()) {
                        let qsPos = qs.position();
                        eerste.position({
                            x: qsPos.x,
                            y: qsPos.y + 50
                        });
                        //de rest doet bestaande code:
                        reorderQs(qs);
                        //en de posities bewaren
                        allQsItems(qs).forEach(node => {
                            posities.push({
                                id: node.id(),
                                pos: node.position()
                            })
                        });
                    }
                });
                cy.nodes('[type = "derivative"]').forEach(d => {
                    let dpos = d.position();
                    let sub = d.successors('[type = "derivative_plus"]');
                    //zelfde plaatsing als bij nieuwe
                    sub.position({
                        x: dpos.x,
                        y: dpos.y + 50
                    });
                    posities.push({id: sub.id(), pos: sub.position()});
                    sub = d.successors('[type = "derivative_zero"]');
                    sub.position({
                        x: dpos.x,
                        y: dpos.y + 80
                    });
                    posities.push({id: sub.id(), pos: sub.position()});
                    sub = d.successors('[type = "derivative_min"]');
                    sub.position({
                        x: dpos.x,
                        y: dpos.y + 110
                    });
                    posities.push({id: sub.id(), pos: sub.position()});
                });
                //naplaatsen: nodes zonder mapping, plaatsen we zo mogelijk tov een parent
                //of from/to
                let node;
                while (node = naplaatsen.shift()) {
                    //parent en positie?
                    let parentId = node.data('parentId');
                    log.debug(`naplaatsen`, node, parentId, node.data('type'));
                    if (parentId) {
                        //al gezet?
                        if (naplaatsen.some(n2 => n2.id() === parentId)) {
                            //later doen
                            log.debug(`naplaatsten uitgesteld. Parent moet zelf ook nog`);
                            naplaatsen.push(node);
                            continue;
                        }
                        //ok, we zetten het via het verschil
                        let parentPosition = cy.$id(parentId).position();
                        /*     log.debug(`parentPosition`, nodepos[parentId], parentPosition);
                             log.debug(`nodepositie`, node.position(), {
                                 x: parentPosition.x - (nodepos[parentId].x - nodepos[node.id()].x),
                                 y: parentPosition.y - (nodepos[parentId].y - nodepos[node.id()].y)
                             });*/
                        node.position({
                            x: parentPosition.x - (nodepos[parentId].x - nodepos[node.id()].x),
                            y: parentPosition.y - (nodepos[parentId].y - nodepos[node.id()].y)
                        });
                        posities.push({
                            id: node.id(),
                            pos: node.position()
                        });
                    } else {
                        log.warn(`Kan node echt niet plaatsen`, node);
                    }
                }
                //herplaats alle exo
                cy.nodes().forEach(node => {
                    if (['q_exo'].some(t => elementService.isSubtypeOf(node.data('type'), t))) {
                        let qpos = getQuantity(node).position();
                        node.position({
                            x: qpos.x - 60,
                            y: qpos.y - 60
                        });
                        posities.push({
                            id: node.id(),
                            pos: node.position()
                        });
                    }
                });
                cy.nodes().forEach(node => {
                    if (['quantity_allvalues'].some(t => elementService.isSubtypeOf(node.data('type'), t))) {
                        let qpos = getQuantity(node).position();
                        node.position({
                            x: qpos.x + 60,
                            y: qpos.y - 60
                        });
                        posities.push({
                            id: node.id(),
                            pos: node.position()
                        });
                    }
                });
                repositionEdgeHandles(); //meteen goed zetten
                //en fitten
                cy.fitToMaxZoom(3, true); //alleen als nodig
                cy.center();
                if ((model.werkmodus !== 'lokaal') && posities.length) {
                    model.samenwerkbericht([{
                        actie: 'positie',
                        posities: posities
                    }]);
                }
                saveToModel(false);
            }

            /**
             * vind een unieke mapping voor de gegeven node. Rechtstreeks of gegarandeerd goed via een parent
             * result is een object zoals het min of meer gesaved wordt, met id, position, label, type, parentId en childIds etc.
             * @param {string} nodeId
             * @returns element-omschrjving
             */
            function normMappingVoor(nodeId) {
                // debugger;
                if (!model.normmatch && model.norm.from.content.elements) {
                    log.warn(`normMappingvoor: ontbrekende data`);
                    return null;
                }
                let mapping = model.normmatch.mappings_op_werk[nodeId];
                if (mapping) {
                    return mapping.norm;
                }
                //parent?
                let node = cy.$id(nodeId);
                let parentId = node?.data('parentId');
                if (parentId) {
                    let parentmapping = normMappingVoor(parentId); //kan dus verder recursief
                    if (parentmapping) {
                        //ok, de parent is gemapt. kunnen we 1 op 1 een kind vinden?
                        //we kunnen dus niet via de mappings zoeken,want dit type wordt
                        //blijkbaar niet gemapt
                        let nodetype = node.data("type");
                        let mogelijk = (parentmapping.childIds || []).filter(normId =>
                            model.norm.from.content.elements[normId]?.type === nodetype
                        );
                        //in mogelijk zitten nu ids van normen die hetzelfde type zijn als het gezochte
                        //als dat er 1 is, hebben we een match, anders niet
                        if (mogelijk.length === 1) {
                            return model.norm.from.content.elements[mogelijk[0]]; //lijkt er genoeg op
                        } else {
                            log.debug(`${mogelijk.length} mogelijke mappings voor ${nodetype} ${nodeId} via parent`);
                        }
                    }
                }
                return null; //niet gevonden
            }

            /*********************** Lokale acties die meestal ook buitenom kunnen ***********************/
            /**
             * Uitvoeren van de addNode-actie
             * voeg één node toe aan het cytocanvas. Aangeroepen in planAddNode
             * die selecties al heeft geregeld. Het is wel altijd lokaal dit. We kunnen dus ook opslaan
             * @param {object} pos De positie van de node
             * @param {object} addThis info over de plaatsen node
             * @param {string} [volger] als gegeven, dan wordt hij door een volger geplaatst en dus geen selectie enzo
             */
            function lokaalAddNode(pos, addThis, volger) {

                //de node bij dit menu zit in de qtip

                switch (addThis.type) {
                    case 'quantity':
                        addQuantity(addThis.node, pos, volger);
                        break;
                    case 'quantity_space':
                        addQuantitySpace(addThis.node, pos, addThis.data.element, volger); //element is deftype point of interval
                        break;
                    default:
                        //voeg een node toe op de gegeven positie de x en y worden het middelpunt van deze node.
                        //in juiste conditieset, ui blokkeert als het goed is als het niet mag
                        let node = {
                            data: {
                                type: addThis.type
                            },
                            position: pos
                        };

                        saveUndoState(); //logische wijziging
                        let newnode = addCyNode(node, addThis.node, !volger); //alleen selecteren als wij hem geplaatst hebben
                        log.debug('Node gemaakt', newnode);
                        logcreate(newnode, {}, volger); //meld de komende wijziging aan model
                        //stuur het bericht naar de volgers, als we niet lokaal werken
                        if (model.werkmodus === 'leid') {
                            model.samenwerkbericht([{
                                    actie: "create",
                                    node: newnode.json(),
                                    select_in_origineel: true, //wij zouden deze selecteren als het lokaal begonnen was
                                    useCondtag: $scope.condtag //zodat het ook bij ux uit sync klopt
                                }],
                                volger); //id van originele afzender
                        }
                        saveToModel(true); //en opslaan inplannen
                        break;
                }
            }

            /**
             * Voeg een relatie toe tussen twee nodes. Kan lokaal, leid en volg (in opdracht van leid)
             * @param van
             * @param naar
             * @param type
             * @param {string | boolean} [nieuwe_id] als gegeven wordt dit de id van de relatienode
             * @param {boolean} [selecteer] Als gegeven en true, moeten we de relatienode selecteren. Regelt de aanroeper
             * @param {string} [volger] Als gegeven, dan wordt hij door een volger geplaatst en sturen we dat als leider weer mee
             */
            function lokaalAddRelation(van, naar, type, nieuwe_id, selecteer, volger) {
                let doSave = (model.werkmodus !== 'volg');
                let startNodepos,
                    endNodepos;

                startNodepos = van.position();
                endNodepos = naar.position();

                let dy = 100; //standaard
                //causaal en config juist omhoog
                if (elementService.isSubtypeOf(type, 'causal') || type === 'configuration') {
                    dy = -100;
                }
                //altijd er precies tussenin en iets lager dan het midden
                let x = startNodepos.x + (endNodepos.x - startNodepos.x) / 2; //gaat goed bij elke verhouding start/end
                let y = startNodepos.y + (endNodepos.y - startNodepos.y) / 2 + dy;

                saveUndoState(); //vlak voor het aanleggen
                if (selecteer) {
                    clearSingleSelection(); //oude weg
                }
                let relNode = addCyNode({
                    data: {
                        type: type,
                        from: van.id(),
                        to: naar.id(),
                        id: nieuwe_id || undefined
                    },
                    position: {
                        x: x,
                        y: y
                    }
                }, null, selecteer);
                if (doSave) {
                    //loggen
                    logcreate(relNode, {}, volger);
                }

                //speciaal: niet opgevangen in nodeclasses
                //afgesproken met Bert Bredeweg d.d. 7-2-2016:
                //als één van de args een calc is, laten we het geheel staan in simulate

                let starttype = van.data('type');
                let endttype = van.data('type');
                if (elementService.isSubtypeOf(starttype, 'q_calc') || elementService.isSubtypeOf(starttype, 'd_calc') ||
                    elementService.isSubtypeOf(endttype, 'q_calc') || elementService.isSubtypeOf(endttype, 'd_calc')
                ) {
                    relNode.removeClass('hide_in_simulate');
                }

                // lijnen trekken als de relatie geplaatst is
                addEdgeFromTo(van, relNode, "source", "model");
                addEdgeFromTo(naar, relNode, "target", "model");

                //en opslaan
                if (doSave) {
                    model.samenwerkbericht([{
                        actie: 'addRelation',
                        id: relNode.id(), //dat de edges niet gelijk zijn is geen ramp
                        van: van.id(),
                        naar: naar.id(),
                        type: type,
                        select_in_origineel: true,
                        useCondtag: $scope.condtag //zodat het ook bij ux uit sync klopt
                    }], volger);
                    saveToModel(true);
                }
            }

            /**
             * Draai de args van een node om. We gaan ervan uit dat het gewoon mag
             * @param node
             * @param [volger] als gegeven, dan wordt hij door een volger gewijzigd en dus geen selectie enzo
             */
            function lokaalSwitchArgs(node, volger) {
                log.debug('lokaalSwitchArgs', node, volger);
                let doSave = (model.werkmodus !== 'volg');
                saveUndoState(); //logische wijziging
                if (doSave) {
                    logmodify(node, {switchargs: 'switched'});
                }
                let oudefrom = getElement(node.data('from'));
                let oudeto = getElement(node.data('to'));
                log.debug(`from to was`, oudefrom, oudeto);

                deleteEdge(oudefrom, node);
                deleteEdge(oudeto, node);

                //en andersom
                node.data('from', oudeto.id());
                addEdgeFromTo(oudeto, node, "source", "model");
                node.data('to', oudefrom.id());
                addEdgeFromTo(oudefrom, node, 'target', 'model');

                if (model.werkmodus === 'leid') {
                    model.samenwerkbericht([{
                            actie: "switchArgs",
                            node: node.id()
                            //geen invloed op condsets
                        }],
                        volger); //originele afzender
                }

                if (doSave) {
                    saveToModel(true); //opslaan
                }
            }

            /**
             * Wijzig het type van een node. We gaan ervan uit dat dat gewoon mag
             * @param node
             * @param type
             * @param {string} [volger] als gegeven, dan wordt hij door een volger gewijzigd en dus geen selectie enzo
             */
            function lokaalChangeNode(node, type, volger) {
                log.debug('lokaalChangeNode', node, type, volger);
                let doSave = (model.werkmodus !== 'volg');
                saveUndoState(); //logische wijziging
                if (doSave) {
                    logmodify(node, {oldtype: node.data('type'), newtype: type});
                }

                let from;
                let to;
                //oude edges weg
                if (elementService.isRelation(node.data('type'))) {
                    from = getElement(node.data('from'));
                    to = getElement(node.data('to'));
                    deleteEdge(from, node);
                    deleteEdge(to, node);
                }

                node.data('type', type);
                node.classes(nodeClasses(type));

                //edges? //het moet een relatie zijn geweest, anders kan het niet
                if (elementService.isRelation(type) && from && to) {
                    addEdgeFromTo(from, node, "source", "model");
                    addEdgeFromTo(to, node, "target", "model");
                }


                if (model.werkmodus === 'leid') {
                    model.samenwerkbericht([{
                            actie: "changeNode",
                            node: node.id(),
                            type: type
                            //geen invloed op condset
                        }],
                        volger); //originele afzender
                }

                if (doSave) {
                    saveToModel(true); //opslaan
                }
            }

            /**
             * Voor toggelen van value uit, voor lokaal en leid (volg reageert op create/delete)
             * @param node
             * @param [volger] De clientid van de afzender
             */
            function lokaalToggleQValue(node, volger) {
                const doSave = (model.werkmodus !== 'volg');
                saveUndoState();
                let acties = [];
                const nodeId = node.id();
                //check op oude waarden
                const qsItems = allQsItems(qsNodeForItem(node)); //alle items in de qs
                let isDelete = false;
                let selector = `[type = "quantity_value"].inconditiestate`; //binnen dezelfde conditieset en -state (zie updateConditieclasses)

                $.each(qsItems, function (i, item) {
                    //zit hier een waarde aan (binnen dezelfde condstate)
                    let w = item.outElements(selector);
                    if (w.length) {
                        log.debug('Verwijder value bij', item.data('label'));
                        //deze heeft een waarde
                        if (doSave) {
                            logdelete(w[0], {}, volger);
                        }
                        //dit wordt een actie
                        acties.push({
                            actie: 'delete',
                            node: w[0].id(),
                            useCondtag: $scope.condtag //zodat het ook bij ux uit sync klopt
                        });
                        deleteNode(w[0]);
                        //als wij het zelf zijn, dan is het verwijderen en zijn we klaar
                        if (item.id() === nodeId) {
                            isDelete = true;
                            return false; //break uit de loopfunctie
                        }
                    }
                });
                //eventuele andere waarden zijn weg
                if (!isDelete) {
                    //als het niet alleen weghalen was komt er nu een nieuwe bij

                    //anders toevoegen
                    let newnode = addCyNode(
                        {
                            data: {
                                type: 'quantity_value',
                                automovegroup: node.data('automovegroup') //alle elementen van deze qs bewegen samen
                            },
                            position: {
                                x: node.position('x') - 30,
                                y: node.position('y')
                            }
                        }, node, false);
                    if (doSave) {
                        logcreate(newnode, {}, volger);
                    }
                    acties.push({
                        actie: 'create',
                        node: newnode.json(),
                        useCondtag: $scope.condtag //zodat het ook bij ux uit sync klopt
                    });
                }
                if (model.werkmodus === 'leid' && acties.length) {
                    //dit wordt dus het samenwerkbericht
                    model.samenwerkbericht(acties);
                }
                if (doSave) {
                    saveToModel(true);
                }
            }

            /**
             * Voor toggelen van d-value uit, voor lokaal en leid (volg reageert op create/delete)
             * @param node
             * @param [volger] de afzender van het bericht die dit laat uitvoeren, voor loggen
             */
            function lokaalToggleDValue(node, volger) {
                const doSave = (model.werkmodus !== 'volg');
                saveUndoState();
                let acties = [];
                const nodeId = node.id();
                let item = cy.$('#' + node.data('derivative'));
                //zoek de oude waarde, loop over elementen (een soort linkedlist)
                let next;
                let isDelete = false;
                while (next = item.outElements('[type = "derivative_plus"],[type = "derivative_zero"],[type = "derivative_min"]'), next.length) {
                    //zit hier een waarde aan?
                    item = next[0];
                    let w = item.outElements('[type = "derivative_value"].inconditiestate');//binnen dezelfde conditieset en -state (zie updateConditieclasses)
                    if (w.length) {
                        //deze heeft een waarde
                        if (doSave) {
                            logdelete(w[0], {}, volger);
                        }
                        //dit wordt een actie
                        acties.push({
                            actie: 'delete',
                            node: w[0].id()
                        });
                        deleteNode(w[0]);
                        //als wij het zelf zijn, dan is het verwijderen en zijn we klaar
                        if (item.id() === nodeId) {
                            isDelete = true;
                        }
                        break; //sowieso break, want maar 1 waarde mogelijk
                    }
                }
                if (!isDelete) {
                    //anders toevoegen
                    //is dit een globale (niet conditionele) d-value, dan exogenen weg
                    if (!$scope.condtag) {
                        let quantity = getQuantity(node);
                        //we moeten zoeken op verschillende exotypes
                        quantity.successors().forEach(subel => {
                            if (elementService.isSubtypeOf(subel.data("type"), 'q_exo')) {
                                if (doSave) {
                                    logdelete(subel, {}, volger);
                                }
                                //volgers moeten hem ook weggooien
                                acties.push({
                                    actie: 'delete',
                                    node: subel.id()
                                });
                                deleteNode(subel);
                            }
                        });
                    }
                    let newvalue = addCyNode(
                        {
                            data: {
                                type: 'derivative_value',

                                //id: getNewId('dv'),
                                automovegroup: node.data('automovegroup') //alle elementen van deze qs bewegen samen
                            },
                            position: {
                                x: node.position('x') - 30,
                                y: node.position('y')
                            }
                        }, node, false);
                    if (doSave) {
                        logcreate(newvalue, {}, volger);
                    }
                    acties.push({
                        actie: 'create',
                        node: newvalue.json()
                    });
                }
                if (model.werkmodus === 'leid' && acties.length) {
                    //dit wordt dus het samenwerkbericht
                    model.samenwerkbericht(acties);
                }
                if (doSave) {
                    saveToModel(true);
                }
            }

            /**
             * Voeg lokaal een nieuw quantityspaceelement toe, via interne functies. Niet gebruiken bij volgers (zie berichtafhandeling)
             * @param qsNode
             * @param topOrBottom
             * @param {string} [volger] als gegeven, dan wordt hij door een volger geplaatst en dus geen selectie enzo
             */
            function lokaalNewQSE(qsNode, topOrBottom, volger) {
                saveUndoState(); //undoable
                let qse = addQuantitySpaceElement(qsNode, topOrBottom, !volger); //niet selecteren als er een volger is
                if (model.werkmodus === 'leid') {
                    //naar de volgers
                    model.samenwerkbericht([{
                            actie: 'addQSE',
                            positie: topOrBottom,
                            qs: qsNode.id(),
                            id: qse.id(),
                            select_in_origineel: true
                            //niet in condset
                        }],
                        volger); //id van afzender mee ivm select
                }
                logcreate(qse, {location: topOrBottom}, volger);
                saveToModel(true); //opslaan
            }

            /**
             * Zet lokaal een QS-punt op zero. Kan door volger (in opdracht van leider) / leider /lokaal. Voert het uit. Inplannen via planQSPointZero
             * @param node
             * @param [volger] id van de volger-client waarin deze actie begonnen is
             */
            function lokaalQSPointZero(node, volger) {
                let doSave = (model.werkmodus !== 'volg');
                let qsItems = allQsItems(qsNodeForItem(node));
                let isToggle = false;
                saveUndoState(); //bewaren
                $.each(qsItems, function (i, item) {
                    if (item.data('isZero')) {
                        item.data('isZero', false);
                        item.data('skipName', false); //mag weer
                        updateNodelabel(item);
                        if (doSave) {
                            logmodify(item, {isZero: false}, volger); //na het zetten, zodat we de naam weer loggen
                        }
                        //is dat deze?
                        if (node.same(item)) {
                            //dan houden we er nu mee op
                            isToggle = true;
                            return false; //break uit de loopfunctie
                        }
                    }
                });
                if (!isToggle) {
                    //die moet dus gezet worden
                    if (doSave) {
                        logmodify(node, {isZero: true}, volger); //voor het zetten, zodat we de naam nog loggen
                    }
                    node.data('isZero', true);
                    node.data('skipName', true); //blokkeer naamwijziging
                    updateNodelabel(node);
                }
                //melden aan volgers
                if (model.werkmodus === 'leid') {
                    model.samenwerkbericht([{
                        actie: 'qsZero',
                        node: node.id()
                        //niet in condset
                    }]);
                }
                //opslaan
                if (doSave) {
                    saveToModel(true);
                }
            }

            /**
             * Voer toggelen van een exogeen uit, voor lokaal en leid
             * @param node
             * @param exoType
             * @param {string} [volger] id van volger als dit vanuit een volger komt
             */
            function lokaalToggleExogeen(node, exoType, volger) {
                //niet in condsets
                let doSave = (model.werkmodus !== 'volg'); //op zich is dit dus nooit zo?
                saveUndoState(); //bewaren
                let acties = [];
                //we gooien alle exos eronder weg
                $.each(node.outElements('node'), function (i, subnode) {
                    if (elementService.isSubtypeOf(subnode.data("type"), 'q_exo')) {
                        if (doSave) {
                            logdelete(subnode, {}, volger);
                        }
                        //volgers moeten hem ook weggooien
                        acties.push({
                            actie: 'delete',
                            node: subnode.id()
                        });
                        deleteNode(subnode);
                    }
                });
                //is er een d_value zonder conditieset? Die moet ook weg
                //het is er maar eentje, maar toch
                node.successors('[type = "derivative_value"][!condtag]').forEach(dval => {
                    if (doSave) {
                        logdelete(dval, {}, volger);
                    }
                    //volgers moeten hem ook weggooien
                    acties.push({
                        actie: 'delete',
                        node: dval.id()
                    });
                    deleteNode(dval);
                });

                //toevoegen
                /*
                 inschakelen als we willen dat de exogeen samen beweegt met quantity
                 let group;
                 if (!node.data('automovegroup')) {
                 let nodeId = node.id();
                 group = 'q_' + nodeId;
                 node.data('automovegroup', group);
                 }
                 else {
                 group = node.data('automovegroup');
                 }*/
                let newnode = addCyNode(
                    {
                        data: {
                            type: exoType
                            // automovegroup: group //deze beweegt de node ook
                        },
                        position: {
                            x: node.position('x') - 60,
                            y: node.position('y') - 60
                        }
                    }, node, !volger); //selecteren als dit niet uit een volger komt
                acties.push({
                    actie: 'create',
                    node: newnode.json(),
                    select_in_origineel: true //deze moet in de originele_afzender geselecteerd worden
                });
                //direct rondsturen
                if (model.werkmodus === 'leid') {
                    model.samenwerkbericht(acties, volger)
                }

                if (doSave) {
                    logcreate(newnode, {}, volger);
                    saveToModel(true);
                }
            }

            /**
             * Voer toggelen van genereer alle waarden uit, voor lokaal en leid
             * @param node
             * @param {string} [volger] id van volger als dit vanuit een volger komt
             */
            function lokaalToggleAllewaarden(node, volger) {
                let doSave = (model.werkmodus !== 'volg');
                let acties = [];
                let bestaand = node.outElements('[type="quantity_allvalues"]');
                if (bestaand.nonempty()) {
                    //oude weg
                    if (doSave) {
                        logdelete(bestaand), {}, volger;
                    }
                    //volgers moeten hem ook weggooien
                    acties.push({
                        actie: 'delete',
                        node: bestaand.id()
                    });
                    deleteNode(bestaand);
                } else {
                    //nieuwe maken
                    /*
                     inschakelen als we willen dat de exogeen samen beweegt met quantity
                     let group;
                     if (!node.data('automovegroup')) {
                     group = 'q_' + nodeId;
                     node.data('automovegroup', group);
                     }
                     else {
                     group = node.data('automovegroup');
                     }*/
                    let newnode = addCyNode(
                        {
                            data: {
                                type: 'quantity_allvalues'
                                // automovegroup: group //deze beweegt de node ook
                            },
                            position: {
                                x: node.position('x') + 60, //rechtsboven
                                y: node.position('y') - 60
                            }
                        }, node, !volger);
                    acties.push({
                        actie: 'create',
                        node: newnode.json(),
                        select_in_origineel: true //deze moet in de originele_afzender geselecteerd worden
                        //niet in condset
                    });
                    if (doSave) {
                        logcreate(newnode, {}, volger);
                    }
                }

                //direct rondsturen
                if (model.werkmodus === 'leid') {
                    model.samenwerkbericht(acties, volger)
                }

                if (doSave) {
                    saveToModel(true);
                }
            }

            /**
             * Wijzig de naam van een node, lokaal of na verzoek van volger
             * @param node
             * @param naam
             * @param {boolean} [geenSave] als gegeven, dan alleen lokaal uitvoeren
             * @param [volger] Afzender van oorspronkelijke actie
             */
            function lokaalWijzigNaam(node, naam, geenSave, volger) {
                //we doen het altijd, ook als het gelijk lijkt
                //het wordt immers expliciet verzocht
                //gewijzigde
                saveUndoState();
                let oldname = node.data("nodename");
                //fix de naam
                naam = naam.trim().replace(/\s{2,}/g, " ");
                node.data("nodename", naam);

                if (!geenSave) {
                    logmodify(node, {
                        oldname: oldname,
                        newname: node.data("nodename")
                    }, volger);
                    if (model.werkmodus === 'leid') {
                        model.samenwerkbericht([{
                            actie: 'wijzigNaam',
                            node: node.id(),
                            naam: naam
                            //geen invloed op condset
                        }]);
                    }
                    //opslaan inplannen
                    saveToModel(true);
                }

                updateNodelabel(node);
            }

            /**
             * Delete een node, lokaal of na verzoek van leider
             * @param node
             * @param {boolean} [geenSave] alleen lokaal uitvoeren, zonder save etc (we zijn volger en leider wil dat we uitvoeren)
             * @param [volger] clientid van de volger die de actie uitvoerde
             *  We zoeken de node op en kijken of er een aparte delete is ($scope.onDelete_<nodetype>).
             * als die er niet is doen we de algemene
             */
            function lokaalDelete(node, geenSave, volger) {
                let node_id = node.id();
                let fn = 'onDelete_' + node.data('type');
                if ((typeof ($scope[fn]) === "function")) {
                    $scope[fn](node, geenSave, volger);
                } else {
                    //dan ook hier loggen, maar alleen voor deze (niet voor de subs)
                    saveUndoState(); //bewaren
                    if (!geenSave) {
                        logdelete(node, {}, volger);
                    }
                    deleteNode(node); //intern
                    if (!geenSave) {
                        saveToModel(true);
                    }
                }
                //en door naar volgers? Los van aparte typen, volger splitst gewoon opnieuw
                if (model.werkmodus === 'leid') {
                    model.samenwerkbericht([{
                        actie: 'delete',
                        node: node_id
                        //gaan vanzelf goed in condset
                    }]);
                }
            }

            /**
             * Implementeert een samenwerkbericht dat bij een client posities zijn gewijzigd. Wij doen dat ook en als we als leider werken, slaan we op en melden we de volgers
             * @param {object[]} posities
             * @param {boolean} alsLeider
             */
            function lokaalSetPosities(posities, alsLeider) {
                let nietsimulatiemove = false; //is er een niet-simulatienode gewijzigd?
                posities.forEach(function (nodepos) {
                    let node = getElement(nodepos.id);
                    if (!node) {
                        log.warn("lokaalSetPosities: ontbrekende node geskipt", nodepos);
                        return; //continue
                    }
                    if (!node.hasClass("simulation")) {
                        nietsimulatiemove = true; //we hebben een niet simulatiemove
                    }
                    node.position(nodepos.pos);
                    //edgehandles ook
                    //heeft hij edgehandles?
                    cy.nodes('.edgeHandle[handleParent="' + node.id() + '"]').forEach(
                        function (eh) {
                            let offset = eh.scratch('offsets')[eh.scratch('currentOffset')];
                            //reset de positie
                            eh.position(
                                {
                                    x: nodepos.pos.x + offset.x,
                                    y: nodepos.pos.y + offset.y
                                }
                            );
                        }
                    );
                });
                repositionEdgeHandles(); //en nu nog een keer goed uitrekenen

                //als we leiden gaan we het doorgeven
                if (alsLeider) {
                    model.samenwerkbericht([{
                        actie: 'positie',
                        posities: posities
                        //staat los van condset
                    }]);
                }
                //opslaan als er iets is gewijzigd aan iets anders dan de simulatie
                if (nietsimulatiemove) {
                    saveToModel(false);
                }
            }

            /**
             * Voor een lokale undo uit, voor lokaal en leid
             * @param {boolean} redo Als true, dan is het redo, anders undo
             * @param {string} [volger] Eventueel afzender-id van  samenwerkingspartner, waarin dit begon
             */
            function lokaalUndoRedo(redo, volger) {
                if (redo) {
                    model.redo();
                } else {
                    model.undo();
                }
                $rootScope.$broadcast('model.contentChanged'); //laat weten dat er een verandering is

                //de modelacties doen al een broadcast van model.changed, dus reloaden we al
                model.logaction(redo ? 'redo' : 'undo', 'model', volger || null, 'model', {}, false);
                //als leider doen we de speciale setModeldata naar de volgers
                if (model.werkmodus === 'leid') {
                    //we doen een setModeldata met de huidige modeldata
                    //zodat volgers snel kunnen reloaden
                    model.samenwerkbericht([{
                        actie: 'setModeldata',
                        data: modelData()
                        //gaat dus inclusief condset
                    }]);
                }
                //sowieso saven
                saveToModel(true);
            }

            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            /**
             * Maak een nieuwe cy-node en voeg hem toe. achteraf zetten we het eea goed. Plant NIET het opslaan omdat er vaak nog meer logische stappen achter moeten komen, of we uberhaupt niet opslaan (volger). Dus op het goede moment saveToModel() aanroepen
             * @param {object} nodeData met in .data in elk geval een type., ook position is nodig De basics zetten we hier wel. id is optioneel
             * @param {cy.collection} parentNode. Evt parentnode (anders null).Regelt edges
             * @param {boolean} [select] Moeten we deze node selecteren?
             * @returns {cy.el} de node
             *
             * Wij zetten altijd de classes via nodeClasses(), en het effectie modelleerniveau in data.mln
             */
            function addCyNode(nodeData, parentNode, select) {
                //voeg de basisdingen toe
                nodeData.group = 'nodes';
                if (!nodeData.scratch) {
                    nodeData.scratch = {};
                }
                //definition in scratch, op te vragen via node.definition() (als api-extensie)
                let def = nodeData.scratch.definition = elementService.getDefinition(nodeData.data.type);
                nodeData.classes = nodeClasses(nodeData.data.type); //bepaal de classes die we op zo'n node zetten
                nodeData.data.mln = model.effectief_modelleerniveau;

                nodeData.data.childIds = [];
                if (parentNode) {
                    nodeData.data.parentId = parentNode.id();
                } else {
                    nodeData.data.parentId = false;
                }

                //is er een naam?
                if (!nodeData.data.nodename) {
                    nodeData.data.nodename = def.nodename;
                }

                //we gebruiken geen door cytoscape gegenereerde id, want dat zijn uuids en die gaan niet goed in prolog
                if (!nodeData.data.id) {
                    nodeData.data.id = getId('n');
                }

                //condities
                //speciaal: elementen die expliciet altijd buiten conditieset zitten (calcs)
                if (!def.neverConditional) {
                    nodeData.data.condtag = $scope.condtag ? $scope.condtag.tag : null;
                    nodeData.data.condstate = $scope.condtag ? $scope.condtag.state : null;
                } else {
                    //dan dus altijd in het scenario
                    nodeData.data.condtag = nodeData.data.condstate = null;
                }

                //nu aanmaken, gegenereerde id gebruiken
                let node = cy.add(nodeData);
                let nodeId = node.id();

                //conditieclasses
                updateConditieclasses(node);

                //bij de parent registreren
                if (parentNode) {
                    parentNode.data('childIds').push(nodeId);
                }

                let nodedef = nodeData.scratch.definition;
                //checken of er een lijn getekend moet worden
                if (parentNode && nodedef.connecttoparent) {
                    //het is een element dat verbonden moet zijn met een parentnode
                    addEdgeFromTo(parentNode, node, "parentchild", "model");
                }

                //naamlabel
                updateNodelabel(node);
                //dit is de laatstgeplaatste
                if (select) {
                    createNodeMenu(node); //maak en toon
                    node.select();
                }
                //als niet geselecteerd, dan pas bij het selecteren van de node
                return node;
            }

            /**
             * Geef gegeven een nodeType de juiste styleclasses (cytostyle) terug
             * zo maken we de classes onafhankelijk van lay-outnamen en kunnen wij bij
             * inlezen ook de classes opnieuw zetten gegeven de nieuwe inzichten
             * Classes zijn altijd: nodetype, en voor elke parent in de hierarchie isa_...
             * @param nodeType
             */
            function nodeClasses(nodeType) {
                let classes = [nodeType, 'model']; //deel van model, niet van simulate
                //het huidige modelleerniveau komt niet meer mee,dat is een data-eigenschap
                //classes.push('modelleerniveau_' + model.effectief_modelleerniveau);
                $.each(elementService.alleUps(nodeType), function (i, parent) {
                    classes.push("isa_" + parent);
                });
                if (elementService.getDefinition(nodeType).hideInSimulate) {
                    classes.push('hide_in_simulate'); //taggen om te kunnen hiden in simulate
                }
                return classes.join(" ");
            }

            /**
             * Voeg quantity toe, standaard gedrag, maar daarna een derivative
             * @param entity
             * @param pos
             * @param {string} [volger] is gegeven als de wijziging vanuit een volger komt. Dit is dan de id. Wij selecteren dan niet.
             * @returns {boolean}
             */
            function addQuantity(entity, pos, volger) {
                //we maken een node of relatie
                let nodeDef = elementService.getDefinition('quantity');
                if (!nodeDef) {
                    //nodedef bestaat niet.
                    return false;
                }

                //voeg een node toe op de gegeven positie de x en y worden het middelpunt van deze node.
                let node = {
                    data: {
                        //id: getNewId('q'),
                        type: 'quantity',
                    },
                    position: pos
                };
                saveUndoState(); //hier bewaren
                let qNode = addCyNode(node, entity, !volger);
                logcreate(qNode, {}, volger);
                //we vallen door naar de afgeleide. Aparte functie want we konden het eerder ook via menu doen
                let subnodes = addDerivative(qNode, {
                    x: pos.x - 45,
                    y: pos.y + 45
                });
                //een hele reeks acties
                if (model.werkmodus === 'leid') {
                    let acties = [{
                        actie: 'create',
                        node: qNode.json(),
                        select_in_origineel: true //deze moet in de orginele_afzender geselecteerd worden
                    }];
                    subnodes.forEach(function (subnode) {
                        acties.push({
                            actie: 'create',
                            node: subnode.json()
                            //niet in condset
                        })
                    });
                    //doorsturen naar de volgers
                    model.samenwerkbericht(acties, volger);
                }
                saveToModel(true); //en opslaan (als we geen volger zijn)
            }

            /**
             * Voeg een quantityspace toe (als hij er nog niet is)
             * @param qNode De quantitynode
             * @param {object} position
             * @param firstElementType type van het eerste element (point/interval volgens def)
             * @param {string} [volger] is gegeven als de wijziging vanuit een volger komt. Dit is dan de id. Wij selecteren dan niet.
             * @returns {boolean} succes
             */
            function addQuantitySpace(qNode, position, firstElementType, volger) {
                //is ie er al?
                if (qNode.outElements('[type = "quantity_space"]').length) {
                    //reset de selectedNode;
                    clearSingleSelection();
                    hideSubmenu();
                    //verder stilzwijgend
                    return false;
                }
                //geen QS, aanmaken
                //de quantity space node - voegen we straks als eerste toe
                saveUndoState();
                let qsNode = addCyNode({
                    data: {
                        //id: getNewId('qs'),
                        type: 'quantity_space',
                        automoveorder: 999 //als een element van de groep beweegt, bewegen elementen met zelfde of lagere order mee
                    },
                    position: position//clickpositie
                }, qNode, false);
                //zet de automovegroup op basis van de node-id
                qsNode.data('automovegroup', 'qsgroup-' + qsNode.id());
                //we maken 1 element in de QS, type zit in firstElementType
                logcreate(qsNode, {}, volger);
                let qse = addQuantitySpaceElement(qsNode, 'top', !volger, firstElementType);
                if (model.werkmodus === 'leid') {
                    model.samenwerkbericht([
                        {
                            actie: 'create',
                            node: qsNode.json()
                            //nooit in condset
                        },
                        {
                            actie: 'addQSE',
                            positie: 'top',
                            elementType: firstElementType,
                            qs: qsNode.id(),
                            id: qse.id(),
                            select_in_origineel: true //deze moet bij de originele afzender geselecteerd worden
                            //nooit in condset
                        }
                    ], volger);
                }
                saveToModel(true); //en opslaan (als lokaal of leid)
                return true;
            }

            /**
             * Voeg een point of interval toe aan de (bestaande) quantityspace.
             *
             * Logisch gezien is een quantityspace als volgt opgebouwd in cy
             * qsnode -> edge -> qs-elemnentnode -> edge -> qs-elementnode -> edqe etc...

             * @param {cy.el} qsNode - het qs-element
             * @param {string} topOrBottom - is 'top' of 'bottom'. optioneel default top
             * @param {boolean} selectItem selecteer het nieuwe item
             * @param {string} [elementType] - is 'quantity_space_point' of 'quantity_space_interval'. Wordt alleen gebruikt als eerste element
             * @param {string} [id] - de id die de nieuwe node moet krijgen (bij replicatie in samenwerken)
             * @returns {cy.el} nieuwe node
             *
             * Let op: dit is een interne functie. We slaan geen undostate op en slaan niet op
             */
            function addQuantitySpaceElement(qsNode, topOrBottom, selectItem, elementType, id) {

                //zoek de juiste parent
                //in een QS is elk volgend element een kind van de vorige
                let parentNode = qsNode;
                let firstChild = null;
                if (topOrBottom == "top") {
                    firstChild = nextQsItem(qsNode); //null als die er niet is
                } else {
                    //verder zoeken
                    let sub;
                    do {
                        sub = nextQsItem(parentNode);
                        if (sub) {
                            parentNode = sub; //het kind eronder
                        }
                    } while (sub);
                }
                //parentnode is nu de parent van de nieuwe node, we proppen ons tussen die parent en zijn 1e child
                //bij de bovenste is het QS-element de parent!

                //we bepalen het te zetten type en de positie aan de hand van wat we weten
                let newpos = {};
                if (firstChild) {
                    //het andere type dan ons eerste kind
                    elementType = firstChild.data("type") ==
                    "quantity_space_interval" ? "quantity_space_point" : "quantity_space_interval";
                    //wij komen op deze plek
                    newpos = clone(firstChild.position());
                } else if (parentNode != qsNode) {
                    //het andere type dan het element boven ons
                    elementType = parentNode.data("type") ==
                    "quantity_space_interval" ? "quantity_space_point" : "quantity_space_interval";
                    //daar komen we 30p onder
                    newpos = {
                        x: parentNode.position('x'),
                        y: parentNode.position('y') + 30
                    };
                } else {
                    //nog geen elementen, dus het type is wat er is opgegeven
                    elementType = elementType || "quantity_space_point"; //failsave
                    //stukje onder de qs
                    newpos = {
                        x: parentNode.position('x'),
                        y: parentNode.position('y') + 60
                    };
                }

                //we hebben: parentNode, eerste childnode, en het type. Maken maar
                //maak de nieuwe node
                let qsNodeId = qsNode.id();
                let elementNode = addCyNode({
                    data: {
                        type: elementType,
                        qspace: qsNodeId,
                        childIds: [],
                        id: id, //als undef dan wordt dit gezet door addCyNode
                        parentId: false,
                        automovegroup: qsNode.data('automovegroup') //alle elementen van deze qs bewegen samen
                    },
                    position: newpos
                }, null, selectItem); //connecten doen we hieronder zelf
                //logisch er tussenzetten:
                if (firstChild) //firstchild is het bovenste element of niets. Als het er is, zit ie aan parent vast
                {
                    deleteEdge(parentNode, firstChild);
                    //en weg als child van parentNode
                    parentNode.data('childIds').splice(parentNode.data('childIds').indexOf(firstChild.id()), 1);
                }
                //nieuwe edge maken en qua data aan elkaar knopen
                addEdgeFromTo(parentNode, elementNode, "normal", "model");
                parentNode.data('childIds').push(elementNode.id());
                elementNode.data('parentId', parentNode.id());
                if (firstChild) {
                    addEdgeFromTo(elementNode, firstChild, "normal", "model");
                    elementNode.data('childIds').push(firstChild.id());
                    firstChild.data('parentId', elementNode.id());
                }

                reorderQs(qsNode);
                return elementNode;
            }

            /**
             * Wrapper om addQuantitySpaceElement tbv views in canvas.html
             * @param topOrBottom
             * @param menuEvent click on menuitem
             */
            $scope.newQuantitySpaceElement = function (topOrBottom, menuEvent) {
                //uitbesteden aan plannen

                clearSingleSelection(); //de oude selectie weg
                hideItemMenu(menuEvent);//qtipmenu blijft kleven, dus weg

                planNewQSE(nodeForMenuItem(menuEvent.target), topOrBottom);
            };

            //quantityspacelement is deletable maar wij steken daar een stokje voor
            //aangeroepen door het show event van de delete btn
            $scope.checkDelete_quantity_space_point = $scope.checkDelete_quantity_space_interval = function (node) {
                //dit mag alleen bij de bovenste en de onderste, en er moet er een over blijven
                let prev = prevQsItem(node);
                let next = nextQsItem(node);
                //er moet 1 null zijn maar niet allebei
                return ((prev && (!next)) || (next && (!prev)));
            };

            //ook een eigen deletehandler voor bovenste of onderste qs-element
            /**
             * Eigen deletehandler voor bovenste / onderste qs-element, ook op verzoek van leider in volger.
             * @param node
             * @param {boolean} [geenSave] niet loggen en saven (volger op verzoek van leider)
             * @param [volger] clientid van volger die actie startte
             */
            function deleteQuantitySpaceElement(node, geenSave, volger) {
                let id = node.id();
                let qsNode = qsNodeForItem(node);
                //we hebben al gecheckt maar dit is toch de handige weg
                let parent = prevQsItem(node);
                let child = nextQsItem(node);

                if (parent && (!child)) {
                    //de onderste kan gerust weg
                    if (!geenSave) {
                        logdelete(node, {}, volger);
                    }
                    deleteNode(node); //plant opslaan, verwijdert link met parent
                } else if (child && (!parent)) {
                    //lastiger - want ons child moet op onze plaats komen

                    //ontkoppel ons
                    deleteEdge(qsNode, node);
                    deleteEdge(node, child);
                    //en plak
                    child.position({
                        x: node.position('x'),
                        y: node.position('y')
                    });
                    addEdgeFromTo(qsNode, child, "normal", "model");
                    //ook logisch:
                    qsNode.data('childIds').push(child.id());
                    child.data('parentId', qsNode.id());
                    //en weg
                    if (!geenSave) {
                        logdelete(node, {}, volger);
                    }
                    deleteNode(node);
                }
                reorderQs(qsNode); //even weer keurig neerzetten
                if (!geenSave) {
                    //en opslaan
                    saveToModel(true);
                }
            }

            //scopefunctie stuurt door. Ook nodig in lokaalDelete voor speciale typen
            //vandaar de geenSave boolean hier
            $scope.onDelete_quantity_space_point = $scope.onDelete_quantity_space_interval = function (node, geenSave, volger) {
                saveUndoState(); //scopeversie: het is undoable
                deleteQuantitySpaceElement(node, geenSave, volger);
            };

            /**
             * Zorg dat de nodes in de qs weer keurig op een rijtje staan na toevoegen of verwijderen
             * @param qsNode
             */
            function reorderQs(qsNode) {
                //automove werkt niet meer bij positioneren door ons, alleen bij drag
                //dus doen we het zelf
                let qsEl = nextQsItem(qsNode);
                log.debug(`reorder`, qsEl.data('label'))
                let x = qsEl.position('x');
                let y = qsEl.position('y');
                //zet het value ook goed
                qsEl.outElements('[type = "quantity_value"]').position('y', y);
                //deze staat goed, nu de rest
                while (qsEl = nextQsItem(qsEl)) {
                    y += 30; //iets lager
                    qsEl.position({x: x, y: y});
                    qsEl.outElements('[type = "quantity_value"]').position('y', y);
                }
            }

            $scope.toggleQValue = function (menuEvent) {
                clearSingleSelection();
                hideQtips();
                let node = nodeForMenuItem(menuEvent.target);
                planToggleQValue(node); //inplannen
            }

            $scope.setQsPointZero = function (menuEvent) {
                log.debug(menuEvent);
                //plannen voor lokaal of leider
                clearSingleSelection();
                hideQtips(); //ook weg
                planQSPointZero(nodeForMenuItem(menuEvent.target));
            };

            $scope.toggleExogeen = function (menuEvent, exoType) {
                cancelAddAction();
                hideItemMenu(menuEvent);
                clearSingleSelection();
                hideQtips();
                //expliciet zoeken naar de quantity, want we gebruiken het ook bij de exogeen zelf
                planToggleExogeen(nodeForMenuItem(menuEvent.target), exoType);
            };

            $scope.toggleAllewaarden = function (menuEvent) {
                clearSingleSelection();
                hideQtips();
                planToggleAllewaarden(nodeForMenuItem(menuEvent.target));
            };

            /**
             * Voeg een afgeleide toe aan een quantity, inclusief zijn 3 waarden.
             * Gebeurt intern, niet meer extern. Geen saveUndoState dus
             * @param qNode
             * @param position
             * @returns {Array} van nieuwe nodes
             */
            function addDerivative(qNode, position) {
                //is ie er al?
                let nodes = [];
                if (qNode.outElements('[type = "derivative"]').length) {
                    //reset de selectedNode;
                    clearSingleSelection();
                    //verder stilzwijgend
                    return nodes;
                }
                //aanmaken
                //let id = getNewId('n');
                //let group = 'dgroup_' + id;
                let dNode = addCyNode({
                    data: {
                        type: 'derivative',
                        automoveorder: 999 //als een element van de groep beweegt, bewegen elementen met zelfde of lagere order mee
                    },
                    position: position//clickpositie
                }, qNode, false); //geen selectie, want waarschijnlijk automatisch

                nodes.push(dNode);
                //automovegroup op basis van de id
                let group = 'dgroup-' + dNode.id();
                dNode.data("automovegroup", group);

                //we maken de 3 elementen hier ook
                let x = position.x;
                let y = position.y + 50;

                let elNode = addCyNode({
                    group: 'nodes',
                    data: {
                        type: 'derivative_plus',
                        //id: getNewId('dp'),
                        derivative: dNode.id(),
                        automovegroup: group
                    },
                    position: {x: x, y: y}
                }, dNode, false);
                nodes.push(elNode);

                y += 30;
                elNode = addCyNode({
                    data: {
                        type: 'derivative_zero',
                        //id: getNewId('dz'),
                        derivative: dNode.id(),
                        automovegroup: group
                    },
                    position: {x: x, y: y}
                }, elNode, false);
                nodes.push(elNode);

                y += 30;
                elNode = addCyNode({
                    data: {
                        type: 'derivative_min',
                        //id: getNewId('dm'),
                        derivative: dNode.id(),
                        automovegroup: group
                    },
                    position: {x: x, y: y}
                }, elNode, false);
                nodes.push(elNode);
                cancelAddAction();
                return nodes;

            }

            $scope.toggleDValue = function (menuEvent) {
                clearSingleSelection();
                hideQtips();
                let node = nodeForMenuItem(menuEvent.target);
                planToggleDValue(node); //inplannen
            }

            /*********************** DELETE ******************************************/

            $scope.doDeleteBtn = function (event) {
                //uitbesteden aan planner
                planDelete(nodeForMenuItem(event.target));
            };

            /**
             * Delete een opgegeven node en alle 'outElements', intern dus zonder op te slaan
             * @param node
             * @param skipRecursive alleen true als we in een andere deleteloop zitten, dan gaat die over children
             */
            function deleteNode(node, skipRecursive) {
                _recursiveDeleteNode(node, skipRecursive); //voert het echt uit
                updateBallondisplay(); //even opruimen
            }

            //de recursieve uitvoerder van deleteNode
            function _recursiveDeleteNode(node, skipRecursive) {
                //bepaal eerst welke nodes targets van deze zijn, wat wij 'children' noemen
                //dat zijn subelementen maar ook nodes die een relatie representeren
                //relatienodes zijn altijd targets van hun argumenten

                let nodeId = node.id();
                let targets = node.outElements('node');

                //verwijder de qtips
                let qtips = node.scratch('qtips');
                if (qtips) {
                    for (let qtipid of qtips) {
                        let qtip = $(qtipid);
                        let qapi = qtip.qtip('api');
                        let scope = qapi.options.menuscope;  //.get werkt niet met ingewikkelde dingen?
                        if (scope) {
                            scope.$destroy();
                        }
                        qtip.remove();
                    }
                }
                node.unselect(); //weg ermee
                //parent bijwerken, als die er nog is
                let parentId = node.data('parentId');
                if (parentId) {
                    let parentNode = cy.nodes('#' + parentId);
                    if (parentNode.nonempty()) {
                        //verwijder deze index bij de kinderen
                        parentNode.data('childIds').splice(parentNode.data('childIds').indexOf(node.id()), 1);
                    }
                }

                cy.remove(node);
                //edgehandles?
                cy.remove(cy.nodes('.edgeHandle[handleParent="' + nodeId + '"]'));

                //bij een gewone delete gaan de kinderen (outElements dus) mee
                if (!skipRecursive) {
                    $.each(targets, function (idx, subnode) {
                        _recursiveDeleteNode(subnode);
                    });
                }
            }

            ///////////////////// INFORMATIE OVER NODES ///////////////////

            /**
             * Heel algemeen: geef een cy-element terug uit het canvas. Nou eenmaal nodig voor de simulatiecode
             * @param id
             * @return {cy.el}
             */
            function getElement(id) {
                return cy.getElementById(id);
            }

            pub.getElement = getElement;

            /**
             * Zoek vanaf argnode omhoog (parentid) naar een node waarvan het type in nodetypes staat
             * @param argnode node of id
             * @param {Array} nodetypes
             * @returns cy.el met 1 node of false als niet gevonden
             */
            function getNodeUp(argnode, nodetypes) {
                if (typeof (argnode) != "object") {
                    argnode = cy.getElementById(argnode);
                }
                if (nodetypes.indexOf(argnode.data('type')) != -1) {
                    return argnode;
                }
                //anders door naar boven
                let parentId = argnode.data('parentId');
                return parentId ? getNodeUp(parentId, nodetypes) : false;
            }

            /**
             * geef een quantitynode terug die bij het argument hoort (of false)
             * @param {string|cy.el} subElement (id van) het subelement van een quantity (of zelf een quantity)
             * @returns {cy.el|false} de quantity boven het argument
             */
            function getQuantity(subElement) {
                return getNodeUp(subElement, ['quantity']);
            }

            pub.getQuantity = getQuantity;

            /**
             * Geeft de quantityspace-node voor een qnode of null als die er niets is
             * @param qNode node of id
             * @returns {cy.el|null}
             */
            function getQSNode(qNode) {
                if (typeof (qNode) != "object") {
                    qNode = cy.getElementById(qNode);
                }
                if (!qNode) {
                    return null;
                }
                let qs = qNode.outElements('[type = "quantity_space"]');
                return qs.empty() ? null : qs;
            }

            pub.getQSNode = getQSNode;

            /**
             * Lever de qsnode op voor een quantity_space_point of _value
             * @param qsItem
             */

            function qsNodeForItem(qsItem) {
                //we hebben nu een goede up
                return getNodeUp(qsItem, ['quantity_space']);
            }

            /**
             * Zoek naar beneden tot de hele derivativeqs er is
             * Terug is een object met elementen type: node
             * @param quantiyOrDerivative kan ook id zijn
             */
            function derivativeQs(qNode) {
                if (typeof (qNode) != "object") {
                    qNode = cy.getElementById(qNode);
                }
                let item = qNode.outElements('[type = "derivative"]');
                let res = {};
                let next
                while (next = item.outElements('[type = "derivative_plus"],[type = "derivative_zero"],[type = "derivative_min"]'), next.length) {
                    item = next[0];
                    res[next.data('type')] = next;
                }
                return res;
            }

            pub.derivativeQs = derivativeQs;

            /**
             * levert alle elementen onder de qs node
             * @param qsNode
             */
            function allQsItems(qsNode) {
                return cy.$("[qspace = '" + qsNode.id() + "']");
            }

            /**
             * Geeft het volgende quantityspaceelement onder een qsnode of een quantityspaceelement
             * @param node
             */
            function nextQsItem(node) {
                let next = node.outElements('[type = "quantity_space_interval"],[type = "quantity_space_point"]');
                return next.length ? next[0] : null;
            }

            pub.nextQsItem = nextQsItem;

            /**
             * Geeft uet vorige quantityspaceelement van een qsnode - nooit de qsnode zelf
             * @param node
             * @returns {null}
             */
            function prevQsItem(node) {
                let next = node.inElements('[type = "quantity_space_interval"],[type = "quantity_space_point"]');
                return next.length ? next[0] : null;
            }

            pub.prevQsItem = prevQsItem;

            /**
             * Geef alle qs-items voor de qNode terug
             * @param qNode node of id
             * @returns cy.collection leeg als geen qs
             */
            function getFullQs(qNode) {
                let qsNode = getQSNode(qNode);
                if (!qsNode) {
                    return cy.collection();
                }
                return allQsItems(qsNode);
            }

            /**
             * Levert een node op voor de zero in de quantityspace van de quantity
             * of null als niet
             * @param qNode id of node
             */
            function getZeroValueNode(qNode) {
                let zero = getFullQs(qNode).filter('[?isZero]');
                return (zero.empty() ? null : zero);
            }

            //public
            pub.getZeroValueNode = getZeroValueNode;

            /**
             * Vind een zeronode in de buurt, liefst van de opgegeven quantity, maar desnoods in een andere QS als de qnode er zelf niet een heeft
             * Nodig voor simulator als er een ineq terugkomt tussen een quantity(-value) en 'zero' en de q heeft zelf geen zero
             * @param qNode
             */
            function relatedZeroNode(qNode) {
                let zero;
                zero = getZeroValueNode(qNode); //gewoon bij zichzelf
                if (zero) {
                    return zero;
                }
                //alle quantities waarmee de Q of een q-value verbonden is middels een ineq
                let q_met_vals = qNode.add(getFullQs(qNode));
                let ineqs = q_met_vals.outElements(q_ineqfilter);
                //directe zero:
                zero = ineqs.outElements(['?isZero']);
                if (zero && zero.nonempty()) {
                    return zero.first(); //de eerste de beste
                }
                //q met zero of sub van q met zero
                ineqs.outElements().forEach(function (n) {
                    let q = getQuantity(n);
                    if (q && (zero = getZeroValueNode(q))) //set let zero
                    {
                        return false; //break
                    }
                });
                if (zero && zero.nonempty()) {
                    return zero.first(); //er is er eentje
                }

                //laatste
                //doe ons een element dat zero is
                zero = cy.nodes('[type="quantity_space_point"][?isZero]');
                return zero && zero.nonempty() && zero.first(); //de eerste, anders false
            }

            pub.relatedZeroNode = relatedZeroNode;

            /**
             * Geef een inequality tussen twee elementen, of false als die er niet is
             * @param node1
             * @param node2
             * @param ookconditiesets=false geef ook een ineq terug uit een conditieset
             */
            function getIneqBetween(node1, node2, ookconditiesets) {
                //de ineqs hebben een from/to
                //we kunnen het ineqfilter niet uitbreiden, want die bevat offen (komma's)

                let n1 = node1.id(), n2 = node2.id();
                let res = cy.nodes(ineqfilter).filter('[from="' + n1 + '"][to="' + n2 + '"],[from="' + n2 + '"][to="' + n1 + '"]').not('.simulation');
                if (!ookconditiesets) {
                    //dan alleen zonder condtag
                    res = res.filter('[!condtag]');
                }
                return res.empty() ? false : res.first();
            }

            pub.getIneqBetween = getIneqBetween;

            /*********************** NODE MENUS *************************************/
            /**
             * maak een qtip menu op basis van het type node.
             * @param node
             */
            function createNodeMenu(node) {
                //maak de qtips-cache aan als die er nog niet is
                //vooral nodig bij inlezen model uit file
                if (!node.scratch('qtips')) {
                    node.scratch('qtips', []);
                }
                let nodeId = node.id();
                let nodedef = node.definition();
                let nodeType = nodedef.nodetype;
                if (nodedef.deletable) {
                    createDeletebtn(node, true); //ook altijd meteen tonen
                }

                createNodeTypemenu(node, nodeId, nodeType, true); //altijd meteen tonen, want menu wordt alleen gemaakt onselect
                //alleen als dit type node een naam kan hebben maken we een labelinput aan.
                if (nodedef.hasname) {
                    createNodeLabelinput(node, nodeId, nodedef, true); //ook meteen tonen
                }

                node.scratch('nodemenu', true); //die is er
            }

            /**
             * Special function: maak een menu voor een simnode. Moet in de canvas staan als een menu template "sim_" + opgegeven type
             * Gebruikt door addSimnodes
             * Gebruikt door addSimnodes
             * @param node
             */
            function createMenuForSimnode(node) {
                //Er is nooit een menu voor een simnode (alleen een prullenbak)
                //en het is heel zwaar om hem te zoeken, dus returnen we:
                return;
                // createNodeTypemenu(node, node.id(), "sim_" + node.data('type'));
            }

            /**
             * het juiste menu maken voor een node.
             * we maken het menu opnieuw bij het opvragen ervan door de qtip, uit een template
             * dat blijkt beter te werken dan het te laten hangen, want er gaat dan iets mis
             * met de $compile van de scope, waardoor ng-click maar 1x werkt
             * Wel is het heel erg kostbaar om een menu dat er niet is te vinden.
             * Daarom zetten we de eerste keer dat we iets niet vinden meteen iets in de cache
             * en roepen we hierboven in het geval van sim_ deze functie al helemaal niet meer aan
             */
            function createNodeTypemenu(node, nodeId, nodeType, shownow) {
                //zoek het bijbehorende menu.
                // het nodeType moet (optineel via de nodemenus hash) wijzen naar een template dat inline in de html staat (id=nodemenu_<naam in hash>)
                // dat kan ook met een parent-type

                //speciale nodemenus:
                let nodemenus =
                    {
                        entity: "entity_agent",
                        agent: "entity_agent",
                        derivative_plus: "derivative_plus_min",
                        derivative_min: "derivative_plus_min",
                        //ineq: "inequality"
                    };

                //welk menu?
                let template = null;
                //zoek naar boven vanaf dit type
                for (let t of [nodeType, ...elementService.alleUps(nodeType)]) {
                    try {
                        let key = "nodemenu_" + (nodemenus[t] || t);
                        template = $templateCache.get(key);
                        if (template) {
                            log.debug(`Template voor ${nodeType} op key ${key}`);
                            //gevonden
                            break;
                        }
                    } catch (e) {
                        log.error(e);
                        return;
                    }
                }
                if (!template) {
                    //geen menu, moet toch bestaan
                    template = "<div></div>";
                }

                //original = original.first().clone();
                //childscope voor het menu, zodat we this.node hebben
                //in scope-functies
                let menuscope = $scope.$new(false);
                menuscope.node = node;
                let qtipdata = {
                    content: function () {
                        //template wordt elke keer opnieuw gecompileerd
                        //nu prerender aanstaat moeten we hier geen apply in zetten

                        let menu = $($compile(template)(menuscope));

                        //alle items
                        let items = menu.find(`.nodemenu_item`);
                        //paar filters die dingen uit items gooien.
                        /*
                    Wat er overblijft
                    -------------------------------------
                        we checken of er een leeg menu overblijft. Dan doen we <div></div>
                        */

                        //minimum modelleerniveau, via attribute
                        log.debug(`items voor filter op niveau`, items.length);
                        items = items.filter((i, el) => {
                            el = $(el);
                            let minniveau = el.attr('data-minniveau');
                            if (minniveau && parseInt(minniveau, 10) > model.effectief_modelleerniveau) {
                                return false; //deze niet
                            }
                            return true;
                        });
                        log.debug(`items na filter op niveau`, items.length);
                        /*
                            conditionele states
                            Situatie met een condstate ingeschakeld
                            ----------------------------------------
                            We hiden alle menuitems die
                            - Geen enkele info over condstates hebben
                            - die niet bij een node in de huidige condset of zonder condset horen
                            - die niet het juiste attribute hebben

                            In conditie moet er data-condstate-cond staan, of data-condstate-match als de node ook conditie is
                            In given moet er data-condstate-cons staat, of data-condstate-match als de node ook given is

                            Situatie zonder condstate ingeschakeld
                            -------------------------------------
                            We hiden alle menuitems die
                            - bij een node in een condstate horen

                        */
                        let nodetag = node.data('condtag');
                        if ($scope.condtag) {
                            // de node in deze conditieset?
                            if (nodetag && (nodetag !== $scope.condtag.tag)) {
                                log.debug(`Node uit andere conditieset: geen menu`);
                                return '<div></div>';
                            }


                            //elke condstate en de huidige condstate
                            let selector = `[data-condstate-any], [data-condstate-${$scope.condtag.state}]`;
                            //mag same ook?
                            if (node.data('condstate') === $scope.condtag.state) {
                                selector += `,[data-condstate-match]`; //een of-filter
                            }
                            items = items.filter(selector);

                        } else {
                            //geen conditieset actief
                            if (nodetag) {
                                //geen menu
                                log.debug(`conditionele node, geen conditie aan: geen menu`);
                                return "<div></div>";
                            }
                        }

                        log.debug(`items na filter op condtag`, items.length);

                        //data-ballon is bedoeld voor "tekstballonnen" in samenwerking / meekijken
                        if (items.is("[data-ballon]")) {
                            //mogen tekstballonnen?
                            if (!(api?.userdata?.projectdata?.tekstballonnen === 'altijd' || (api?.userdata?.projectdata?.tekstballonnen === 'samenwerken' && model?.samenwerking !== 'single'))) {
                                //als dit NIET mag, dan:
                                items = items.not("[data-ballon]"); //weg
                            }
                        }

                        //alle menu-items die niet in items zitten, moeten weg
                        //anders menuitems die niet mogen weglaten
                        menu.find(`.nodemenu_item`).not(items).remove();
                        //is er nog wel een hoofdmenu over?
                        if (!menu.find('.nodemenu .nodemenu_item').length) {
                            log.debug(`Hele hoofdmenu verborgen: stop`);
                            return '<div></div>';
                        }

                        return menu;
                    },
                    //id: 'typemenu-' + nodeId,
                    menuscope: menuscope,
                    node: node,
                    prerender: true, //nodig omdat de positie anders mis gaat als er direct hierna nog meer nodes worden gemaakt
                    position: {
                        effect: false,
                        my: 'top left',
                        at: 'bottom right',
                        adjust: {
                            x: 5,
                            y: 5,
                            cyViewport: true
                            // method: 'none' //niet verspringen als het niet past
                        }
                    },
                    style: {
                        classes: 'dynalearnNodeMenu',
                        def: false,
                        tip: false
                        //width: false
                    },
                    show: {
                        ready: shownow,
                        solo: false,
                        effect: false
                        //delay: 90
                    },
                    hide: {
                        fixed: true
                        //delay: 0
                    },
                    events: {
                        show: function (event, api) {
                            //nooit een menu als de node niet in de juiste condstate zit
                            //dat klopt niet. Want je wilt een conditie bij een statisch element
                            //kunnen maken en in theorie zelfs een given bij een conditie
                            /*                            if (!nodeInCondtag(node)) {
                                                            log.debug(`Geen nodemenu - verkeerde condstate`);
                                                            event.preventDefault();
                                                            return false;
                                                        }*/

                            //als we een relatie toevoegen en de startnode is een andere dan de eindnode
                            if (inAddRelation() && !currentAddAction.node.same(node)) {
                                event.preventDefault();
                                return false;
                            }

                        }
                    }
                };
                //menu in een qtip plaatsen.
                node.qtip(qtipdata);
                //bewaar deze qtip voor destroy,op id
                node.scratch('qtips').push('#qtip-' + node.qtip('api').get('id'));
            }

            /**
             * qtip met textinput
             * @param node
             * @param nodeId
             * @param nodedef
             * @param shownow
             */
            function createNodeLabelinput(node, nodeId, nodedef, shownow) {
                //we splitten een paar keer op normmodel (select) of vrij (input):

                let isNorm = !!(model.norm && model.norm.namen && Object.keys(model.norm.namen).length);
                //labelPos is right of center
                let pos;
                let extraclass = "";
                if (nodedef.labelPosition === 'right') {
                    pos = {
                        effect: false,
                        my: 'center left',
                        at: 'center right',
                        adjust: {
                            cyViewport: true,
                            method: 'none' //niet verspringen als het niet past
                        }
                    };
                } else {
                    //midden
                    extraclass = " center";
                    pos = {
                        effect: false,
                        my: 'bottom center',
                        at: 'top center',
                        adjust: {
                            cyViewport: true,
                            method: 'none' //niet verspringen als het niet past
                        }
                    };
                }
                //hoe de qtip zelf wordt hangt af van het normmodel;
                let qtipdata;
                let content;
                if (isNorm) {
                    //maak een select en voeg de _huidige_ vertaling van NAAM_NOG_OPEN als generieke toe
                    //er is dus een probleem als de user van taal wisselt, maar heel beperkt. En anders wordt het erg ingewikkeld, met terugvertalen enzo
                    content = `<div class="nodeLabel" id="nodelabel_${nodeId}"><select id="labelselect_${nodeId}" class="nodeLabelselect ${extraclass}">` +
                        '<option value="' + __("NAAM_NOG_OPEN") + '">' + __("NAAM_NOG_OPEN") + '</option>';
                    let namen = Object.keys(model.norm.namen);
                    //even specials (is dit de handige plek?, tja moet maar)

                    namen.sort((n1, n2) => specialenaam(n1).localeCompare(specialenaam(n2)));
                    namen.forEach(function (naam) {
                        let aantal = "";
                        //aantal erachter als we weten dat de naam vaker voorkomt
                        if (model.norm.namen[naam] > 1) {
                            aantal = " (" + model.norm.namen[naam] + "x)";
                        }
                        content += '<option value="' + naam + '">' + specialenaam(naam) + aantal + '</option>';
                    });
                    content += "</select>";
                } else {
                    content = `<div class="nodeLabel" id="nodelabel_${nodeId}"><input type="text" id="labelinput_${nodeId}" value="" class="nodeLabelinput ${extraclass}"></div>`;
                }
                //editbare input
                qtipdata = {
                    //id: 'labelinputqtip-' + nodeId, //wordt qtip-q
                    node: node,
                    content: content,
                    prerender: true, //nodig omdat de positie anders mis gaat als er direct hierna nog meer nodes worden gemaakt
                    position: pos,
                    style: {
                        classes: 'nodelabelinput',
                        def: false,
                        tip: false
                    },
                    show: {
                        ready: shownow,
                        // delay: 1,
                        solo: false,
                        effect: false
                    },
                    hide: {
                        fixed: true
                    },
                    events: {
                        render: function (event) {
                            //initialisatie
                            //focusevent op de input
                            if (!isNorm) {
                                let inputfield = $('input[id=labelinput_' + nodeId + ']');
                                inputfield.on('focus', function () {
                                    inputfield.select();
                                });
                            }
                        },
                        show: function (event) {
                            //als we een relatie toevoegen en de startnode is een andere dan de eindnode
                            //of als de node niet in de juiste conditieset-context zit
                            if ((!nodeInCondtag(node)) || (!nodedef.hasname) || node.data('skipName') || (inAddRelation() && !currentAddAction.node.same(node))) {
                                event.preventDefault();
                                return false;
                            }
                            if (isNorm) {
                                //selecteer de juiste
                                $('select[id="labelselect_' + nodeId + '"] option[value="' + node.data("nodename") + '"]').prop('selected', true);
                            } else {
                                let inputfield = $('input[id=labelinput_' + nodeId + ']');
                                inputfield.val(node.data("nodename"));
                            }
                            //schaal op basis van de zoom van de cy
                            let zoom = Math.min(3.5, Math.max(0.95, cy.zoom()));
                            log.debug(`transform: scale(${zoom});`);
                            $(event.target).css({transform: `scale(${zoom})`});
                            if (nodedef.labelPosition === 'right') {
                                $(event.target).css({transformOrigin: 'left center'});
                            }
                            node.data('label', ''); //hide label content
                        },
                        hide: function (event) {
                            //haal de naam op
                            let newname;
                            if (isNorm) {
                                newname = $('select[id="labelselect_' + nodeId + '"] option:selected').val();
                            } else {
                                newname = $('input[id=labelinput_' + nodeId + ']').val();
                            }
                            if (newname !== node.data("nodename")) {
                                planWijzigNaam(node, newname);
                            } else {
                                $timeout(_ => updateNodelabel(node), 50); //wel even bijwerken
                            }
                        }
                    }
                };

                node.qtip(qtipdata);
                //bewaar deze qtip voor destroy. Niet als jquery, maar op id
                //want hij is er nog niet - pas na eerste keer nodig
                node.scratch('qtips').push('#qtip-' + node.qtip('api').get('id'));
            }

            /**
             * Toon het juiste label van de node
             * @param node
             */
            function updateNodelabel(node) {
                //alleen een naam als die er volgens de definitie is en skipName niet ingeschakeld is
                let def = node.definition();
                node.data('label', specialenaam((def.hasname && !node.data('skipName')) ? node.data('nodename') : ""));
            }

            /**
             * deletebtn voor node maken
             * @param node
             * @param shownow, als true dan wordt de knop meteen getoond
             * Speciaal: de deletebutton doet voor het tonen een check of het mag.
             * Maak een functie $scope.checkDelete_<nodetype> die een boolean teruggeeft
             * en eventueel $scope.onDelete_<nodetype> voor het verwijderen zelf (ipv deleteNode)
             */
            function createDeletebtn(node, shownow) {
                let nodeId = node.id();
                let selector = '#' + nodeId;
                let qtipdata = {
                    //id: 'deletebtn-' + nodeId,
                    node: node,
                    prerender: true,
                    content: {
                        text: function () {
                            //ook deze maken we steeds opnieuw
                            let template = $templateCache.get("nodeDeleteBtn");
                            return $($compile(template)($scope)); //we maken een clone en compileren hem
                        }
                    },
                    position: {
                        effect: false,
                        my: 'right center',
                        at: 'left center',
                        target: cy.$(selector),
                        adjust: {
                            x: 5,
                            cyViewport: true,
                            method: 'none' //niet verspringen als het niet past
                        }
                    },
                    tip: false,
                    style: {
                        def: false,
                        tip: false
                    },
                    show: {
                        ready: shownow,
                        effect: false,
                        solo: false
                    },
                    hide: {
                        fixed: true
                    },
                    events: {
                        show: function (event) {
                            //niet tonen als we bezig zijn een relatie toe te voegen
                            //of als de callback ons weigert
                            //of in de verkeerde condstate
                            let stop = false;

                            if (!nodeInCondtag(node)) {
                                log.debug('Node geen delete want niet in condtag');
                                stop = true;
                            } else if (inAddRelation()) {
                                stop = true;
                            } else {
                                //is er een checkDelete voor dit type
                                let fn = 'checkDelete_' + node.data('type');
                                if ((typeof ($scope[fn]) === "function") && (!$scope[fn](node))) {
                                    stop = true;
                                }
                            }
                            if (stop) {
                                event.preventDefault();
                                return false;
                            }
                            log.debug('node mag delete');
                            log.debug(node.classes(), node.position(), node.data());
                        }
                    }
                };
                node.qtip(qtipdata);
                //bewaar deze qtip voor destroy op id
                //moet nu want we kunnen alleen bij de laatste qtip op een element
                node.scratch('qtips').push('#qtip-' + node.qtip('api').get('id'));
            }

            /**
             * Hide het menu waarin een bepaald menuitem (of een event daarvan) zit
             * @param menuItemOrEvent
             */
            function hideItemMenu(menuItemOrEvent) {
                $(menuItemOrEvent.target || menuItemOrEvent).closest('.qtip').hide();
            }

            pub.hideItemMenu = hideItemMenu; //ook publiek

            /**
             * Hide alle qtips
             * @param nodes
             */
            function hideQtips() {
                $('.qtip').each(function (i, qtip) {
                    $(qtip).qtip('api').hide();
                })
            }

            /**
             * Geef de node bij een item in de hierboven gemaakte nodemenus
             * @param menuItem
             *  Vaak niet meer nodig, want in de menu's hebben we een eigen scope, en dus this.node in de scope-functies, en zelfs gewoon node.data(...) in ng-if etc.
             * @returns {*}
             */
            function nodeForMenuItem(menuItem) {
                //LET OP:  vaak niet meer nodig
                let qtip = $(menuItem).closest('.qtip');
                if (!qtip.length) {
                    return null;
                }
                let api = qtip.qtip('api');
                return api.get("node");
            }

            //ook publiek beschikbaar
            pub.nodeForMenuItem = nodeForMenuItem;

            /**
             * openen van een submenu in een nodemenu, over het algemeen door op een listitem te klikken.
             * options bevat een key menutype dit is een string die verwijst naar een id van een menu;
             * @param event
             * @param options
             */
            function openSubmenu(event, options) {
                //dit annuleert alles
                cancelAddAction();
                if (options) {
                    if (!options.hasOwnProperty('menutype')) {
                        //doe niets
                        log.warn("Geen menutype");
                        return false;
                    }
                }
                //we vinden het submenu binnen het huidige
                let menu = $(event.target).closest('.qtip').find('[submenu = "' + options.menutype + '"]');
                if (!menu.length) {
                    //menu dat geopend moet worden bestaat niet.
                    log.warn("Geen submenu met menutype", options.menutype);
                    return false;
                }
                if (!menu.attr('id')) {
                    menu.attr('id', getId('submenu'));
                }
                //alleen als we een ander menu willen openen hiden we even alle andere submenu's
                if ($scope.openedsubmenu != menu[0]) {
                    $('.nodemenu_sub').each(function (i, sm) {
                        if (sm != menu[0]) {
                            $(sm).hide(); //geen hide queen voor ons submenu
                        }
                    })
                }

                menu.show();
                $scope.openedsubmenu = menu[0];

            }

            $scope.openSubmenu = openSubmenu;


            /*********************** EDGES ****************************************/

            /**
             * teken een lijn tussen de geselecteerde node en de zojuist geplaatste node.
             * @param from - parent of source / target node
             * @param to - child of nieuwe relatienode
             * @param reltype - expliciet relatietype, levert een extra class op
             * @param stateclass - stateclass (model of simulation): levert een extra class op
             * Classes die worden toegevoegd volgens edgeClasses
             */
            function addEdgeFromTo(from, to, reltype, stateclass) {
                //check of er eventueel edgeHandles zijn?
                let tohandle = getEdgeHandle(to, 'to_' + reltype);
                let fromhandle = getEdgeHandle(from, 'from_' + reltype);

                //fromhandle en tohandle zijn nu gewoon de node zelf of een speciale edgeHandle node als die in de elementservice zijn gedefinieerd voor dit reltype
                let edge = {
                    group: "edges",
                    data: {
                        source: fromhandle.id(),
                        target: tohandle.id(),
                        //orig source en target ook, zie deleteEdge
                        orig_source: from.id(),
                        orig_target: to.id(),
                        reltype: reltype,
                        id: getId('e')
                    },
                    classes: edgeClasses(from.data('type'), to.data('type'), reltype, stateclass)
                };
                //conditie of consequentie?
                let condstate = from.data('condstate') || to.data('condstate'); //1 van 2en bepaalt het
                if (condstate) {
                    edge.data.condstate = condstate;
                }

                let cyedge = cy.add(edge);
                repositionEdgeHandles(); //meteen bijwerken
                return cyedge;
            }

            pub.addEdgeFromTo = addEdgeFromTo; //ook gebruikt door sim-code

            /**
             * /**
             * Geef gegeven een nodeType voor source en target de juiste styleclasses (cytostyle) terug
             * zo maken we de classes onafhankelijk van lay-outnamen en kunnen wij bij
             * inlezen ook de classes opnieuw zetten gegeven de nieuwe inzichten
             * Classes die worden toegevoegd: rel_from_<sourcetype>, rel_to_<targettype> rel_type_<relatietype>
             *     relatietype is standaard normal
             *     daarnaast nog voor elke parent in de isahierarchie rel_from_isa_<parent> en rel_to_isa_<parent>
             * @param sourceType
             * @param targetType
             * @param reltype
             * @param stateclass "model","simulation"
             */
            function edgeClasses(sourceType, targetType, reltype, stateclass) {
                let classes = ["edge_from_" + sourceType, "edge_to_" + targetType, stateclass]; //ook toevoegen model, niet van simulate
                //alle parenttypen doen ook mee
                $.each(elementService.alleUps(sourceType), function (i, parent) {
                    classes.push("edge_from_isa_" + parent);
                });
                $.each(elementService.alleUps(targetType), function (i, parent) {
                    classes.push("edge_to_isa_" + parent);
                });
                //plus het meegegeven typen
                classes.push("edge_type_" + (reltype ? reltype : "normal"));
                return classes.join(" ");
            }

            /**
             * Regel de juiste handle voor een bepaalde node: dat is of de node zelf, of een specifieke edgeHandle-node
             * @param parentNode
             * @param handleType
             * @returns {cy.ele}
             */
            function getEdgeHandle(parentNode, handleType) {
                let nodepos;
                // log.debug(`getEdgeHandle`,parentNode, parentNode.definition());
                let edgeHandles = parentNode.definition().edgeHandles;
                if (edgeHandles && edgeHandles[handleType] && edgeHandles[handleType].length) {
                    nodepos = edgeHandles[handleType][0]; //eerst maar eens de eerste
                } else {
                    return parentNode; //gewoon aan de parent
                }

                //we moeten hem maken
                let parentPos = parentNode.position();
                let classes = "edgeHandle"; //NIET class model, dus niet opgeslagen

                //hide_in_simulate: als de parentnode hide in simulate, dan wij ook (wordt bij laden opnieuw gecheckt)
                if (parentNode.hasClass('hide_in_simulate')) {
                    classes += " hide_in_simulate";
                }
                let edgeNode = cy.add({
                    group: 'nodes',
                    classes: classes,
                    selectable: false,
                    data: {
                        type: 'edgeHandle',
                        handleParent: parentNode.id(),
                        handleType: handleType,
                        id: getId('eh'),
                        freeMove: false //als true, dan is deze vrij te verplaatsen. Vooral om de juiste offset te vinden
                    },
                    position: {
                        x: parentPos.x + nodepos.x,
                        y: parentPos.y + nodepos.y
                    },
                    scratch: {
                        offsets: edgeHandles[handleType],
                        currentOffset: 0
                    }
                });
                //even freemove om de offset te berekenen?
                if (edgeNode.data('freeMove')) {
                    edgeNode.on('drag', function () {
                        let npos = edgeNode.position();
                        let ppos = cy.getElementById(edgeNode.data('handleParent')).position();
                    });
                }

                return edgeNode;

            }

            /**
             * Bij verwijderen edge: kijk of er edgehandles zijn in de argumenten
             * en plan hun verwijdering
             */
            function checkEdgeHandleOnDeleteEdge() {
                //this is de edge die weggaat
                this.connectedNodes('[type="edgeHandle"]').forEach(function (edgeHandle) {
                    //we plannen het verwijderen in na het verwijderen van de egde
                    //om eventuele recursie in de cy-code te voorkomen
                    setTimeout(function () {
                        cy.remove(edgeHandle);
                    }, 0);
                });

            }

            /**
             * Loop over alle edgehandles en bepaal opnieuw hun optimale offsets (als ze er meerdere hebben)
             * Wordt aangeroepen door endAutomove en bij nieuwe edgeHandles
             *
             */
            function repositionEdgeHandles() {
                cy.nodes('.edgeHandle').forEach(function (edgeNode) {
                    //we halen de definitie van de edgeHandles altijd uit de scratch en anders uit de def van de parent
                    //zodat we het kunnen bijwerken in code en opgeslagen modellen worden bijgewerkt

                    let parentNode = cy.getElementById(edgeNode.data('handleParent'));

                    let offsets = edgeNode.scratch('offsets');
                    if (!offsets) {
                        let handledef = parentNode.definition().edgeHandles;
                        if (!(handledef && handledef[edgeNode.data('handleType')])) {
                            return; //niet te doen
                        }
                        offsets = handledef[edgeNode.data('handleType')];
                        edgeNode.scratch("offsets", offsets);
                    }

                    if (offsets.length < 2) {
                        return; //niets te doen
                    }
                    let linked = edgeNode.neighborhood('node'); //bij meerdere gaat het om de eerste, maar in principe is het er 1
                    if (linked.empty()) {
                        log.warn(`Geen linked gevonden`, edgeNode);
                        return; //niets te doen, continue. Ook niet weggooien, want dan mislukt een eventuelle nieuwe edge die gaande is
                    }
                    let lpos = linked.position();
                    let ppos = parentNode.position();
                    let best = null;
                    for(let o of offsets) {
                        let hpos = {
                            x: ppos.x + o.x,
                            y: ppos.y + o.y
                        };
                        let lsquare = Math.pow(lpos.x - hpos.x, 2) + Math.pow(lpos.y - hpos.y, 2); //pythagoras, we laten de wortel zitten want het gaat om de sortering
                        if ((! best) || (lsquare < best.lsquare)) {
                            best = {
                                hpos: hpos, //gelijk de positie bewaren
                                lsquare: lsquare
                            }
                        }
                    }
                    if (best) {
                        edgeNode.position(best.hpos);
                        edgeNode.scratch('currentOffset', best.index);
                    }
                    else
                    {
                        log.warn('Geen offset gevonden voor handle', edgeNode);
                    }
                });
            }

            /**
             * verwijderen van edge op nodeId
             * we zoeken de edge op waarvan de nodeId van de source en target van de edge.
             * zo weten we zeker dat we niet meerdere edges verwijderen.
             * @param {object} sourceNode
             * @param {object} targetNode -- het id van de node die target is van de edge.
             */
            function deleteEdge(sourceNode, targetNode) {

                //we zoeken op orig_source en orig_target
                //zodat het ook goed gaat als er edgehandles gebruikt zijn
                cy.remove(cy.$(`[orig_source='${sourceNode.id()}'][orig_target='${targetNode.id()}']`));
            }

            /**************************** CONDITIONELE TAGS *************************************

             /**
             * Interne functies
             */

            /**
             * Nieuwe clients e.d.: stuur als leider de huidige toestand rond
             */
            function syncSamenwerkUX() {
                if (model && model.werkmodus === 'leid') {
                    let acties = [{
                        actie: "syncConditiesets",
                        condtag: $scope.condtag,
                        conditionele_tags: $scope.conditionele_tags,
                        conditional_open: $scope.conditional_open
                    }];
                    model.samenwerkbericht(acties);
                    //foutniveau resetten we maar om het allemaal goed te krijgen. Niet perfect.
                    //TODO: een algehele UX-sync.
                    model.resetFoutniveau(); //even weer dicht
                }
            }

            /**
             * Handler van het syncConditiesets-bericht, alleen voor volgers
             * @param data
             */
            function onSyncConditiesets(data) {
                if (model && model.werkmodus === 'volg') {
                    //gewoon overnemen
                    $scope.condtag = data.condtag;
                    $scope.conditionele_tags = data.conditionele_tags;
                    $scope.conditional_open = data.conditional_open;
                    updateConditieclasses();
                }
            }

            /**
             * Check of node actief hoort te zijn in de huidige condtag
             * @param node
             */
            function nodeInCondtag(node) {
                let nodetag = node.data('condtag');
                let nodestate = node.data('condstate');
                /*	log.debug('NodeInCondtag', $scope.condtag && $scope.condtag.tag, nodetag, $scope.condtag && $scope.condtag.state, nodestate);
					log.debug(node.json().classes);*/
                if (((!$scope.condtag) && (nodetag)) || ($scope.condtag && (!nodetag))) {
                    return false;
                }

                if (!$scope.condtag) {
                    return true;
                }

                return (nodetag === $scope.condtag.tag && nodestate === $scope.condtag.state);
            }

            /**
             * update de classes rond de conditieset op de nodes en edges. Autoratief.
             * We matchen op tag en op tag + state
             * inconditieset = juiste tag (ongeacht state)
             * uitconditieset = andere tag
             * inconditiestate = juiste tag + state
             * uitconditiestate = niet juiste tag + state
             *
             * Samen met selectors op ?condtag en !condtag (wel/geen conditioneel item) komen we een eind
             * @param [nodes] Optionele subset van nodes waarvoor we dit moeten doen (vaak 1 node)
             */
            function updateConditieclasses(nodes) {
                cy.batch(() => {
                    let col = nodes || cy.nodes();
                    col.removeClass("inconditieset uitconditieset inconditiestate uitconditiestate onderconditie");

                    log.debug(`condtag`, $scope.condtag);

                    let inset, instate;

                    let actievetags = Object.keys($scope.conditionele_tags).filter(condtag => $scope.conditionele_tags[condtag]);
                    if (actievetags.length) {
                        inset = col.filter(actievetags.map(condtag => `[condtag = "${condtag}"]`).join(','));
                    } else {
                        inset = col.filter(`[!condtag]`);
                    }
                    if ($scope.condtag) {
                        // inset = col.filter(`[condtag = "${$scope.condtag.tag}"]`);
                        instate = inset.filter(`[condtag = "${$scope.condtag.tag}"][condstate = "${$scope.condtag.state}"]`);
                    } else {
                        instate = col.filter(`[!condtag]`);
                    }
                    inset.addClass('inconditieset');
                    instate.addClass('inconditiestate');

                    //en de negaties
                    col.not(inset).addClass('uitconditieset');
                    col.not(instate).addClass('uitconditiestate');

                    //speciaal: values die onder conditievalues vallen krijgen een class
                    col.filter(`.quantity_value[!condtag],.derivative_value[!condtag]`).forEach(v => {
                        if (col.filter(`.inconditieset[?condtag][parentId = "${v.data('parentId')}"]`).nonempty()) {
                            v.addClass('onderconditie');
                            log.debug(`Value onder conditie`);
                        }
                    });

                    //en alle edges
                    cy.edges().removeClass("inconditieset uitconditieset inconditiestate uitconditiestate");
                    /*
					Een edge is in conditieset als:
					- alle connected nodes de juiste conditieset hebben of geen
					Een edge is in conditiestate als:
					- er een connected node is met de juiste conditieset en de juiste conditiestate
					 */

                    cy.edges().forEach(edge => {
                        let nodes = edge.connectedNodes().map(node => node.data('type') === 'edgeHandle' ? cy.getElementById(node.data('handleParent')) : node);

                        let inset = true;
                        let instate = false;

                        for (let node of nodes) {
                            if (node.data('condtag') && !node.hasClass("inconditieset")) {
                                inset = false;
                            }
                            if (node.data(`condtag`) && node.hasClass("inconditieset") && node.hasClass('inconditiestate')) {
                                instate = true;
                                log.debug(`instate is true vanwege node`, node.data(), node.json().classes);
                            }
                        }

                        edge.addClass(inset ? "inconditieset" : "uitconditieset");
                        edge.addClass(instate ? "inconditiestate" : "uitconditiestate");
                    });
                });
            }

            //UX voor de conditionele tags: regel samenwerking

            /**
             * Zet de conditionele tools via leider/volger
             * @param {boolean} show
             * @param {boolean} [vanLeider]
             */
            function showConditionalTools(show, vanLeider) {
                if (model.werkmodus === "volg" && !vanLeider) {
                    //stuur door
                    model.samenwerkbericht([{
                        actie: "showConditionalTools",
                        show: show
                    }]);
                    return;
                    //ook gewoon niet zetten tot de leider het okee vindt
                }
                //zeer simpele interpretatie
                $scope.conditional_open = show;
                if (model.werkmodus === "leid" && (!vanLeider)) {
                    //door naar de volgers
                    model.samenwerkbericht([{
                        actie: "showConditionalTools",
                        show: show
                    }]);
                }
            }

            $scope.showConditionalTools = showConditionalTools;

            /**
             * Scope: lever alle condtags
             */
            $scope.allecondtags = function () {
                let condtags = Object.keys($scope.conditionele_tags);
                condtags.sort();
                return condtags;
            };

            //zijn er condtags?
            $scope.heeftCondtags = pub.heeftCondtags = function () {
                return $scope.conditionele_tags && Object.keys($scope.conditionele_tags).length;
            };

            /**
             * Scope: nieuwe conditionele tag. Deze kan dus gebruikt worden bij elementen
             */
            $scope.newcondtag = function () {
                //voeg een nieuwe conditionele tag toe

                //wij doen de voorbereiding en daarna dispatchen we via samenwerking
                cancelAddAction();
                _vraagCondTag('', true).then(res => {
                    changeCondtag(null, res.tag);
                }).catch(() => {
                });
            };

            /**
             * Wijzig een bestaande condtag, of maak een nieuwe. Via dispatch
             * @param oldname als null: maak een nieuwe
             * @param newname nieuwe naam als null: verwijder alleen
             * @param vanLeider
             * @param {string} [volger] als we dit als leider van een volger doorgekregen hebben, is dit de id van de volger
             */

            function changeCondtag(oldname, newname, vanLeider, volger) {
                log.debug(`changeCondtag(${oldname},${newname}, ${vanLeider})`);
                //new is niet undoable, want geen enkel gevolg voor het opgeslagen model
                //we zouden het kunnen undo (want reload model = het verdwijnt) maar dan krijg je
                //dat redo niet werkt, en dat undo van toevoegen van de eerste node de cond ook doet verdwijnen
                let isVolg = (model.werkmodus === "volg");
                if (isVolg && !vanLeider) {
                    //stuur door
                    model.samenwerkbericht([{
                        actie: "changeCondtag",
                        oldname: oldname,
                        newname: newname
                    }]);
                    return;
                    //ook gewoon niet zetten tot de leider het okee vindt
                }

                //we cancelen eventuele adds, ook bij volgers
                cancelAddAction();
                //voor edit én delete:
                saveUndoState(); //want de tags krijgen nieuwe data. Undo is lokaal in volger en leider
                if (oldname) {

                    //en afsplitsen
                    if (newname) {
                        //wijzigen
                        if (!isVolg) {
                            model.logaction('cond_rename', oldname, volger, 'model', {nieuw: newname});
                        }
                        $scope.conditionele_tags[newname] = $scope.conditionele_tags[oldname];

                        //was de oude geselecteerd?
                        if ($scope.condtag && $scope.condtag.tag === oldname) {
                            $scope.condtag.tag = newname; //zelfde state
                        }
                        //en nu de nodes
                        cy.nodes(`[condtag = "${oldname}"]`).data('condtag', newname); //rename
                        //  model.logaction(`cond_rename`, orig, null, undefined, {nieuw: condtag});
                    } else {
                        //verwijderen
                        if (!isVolg) {
                            model.logaction('cond_delete', oldname, volger, 'model');
                        }
                        if ($scope.condtag && $scope.condtag.tag === oldname) {
                            $scope.condtag = null;
                            $scope.conditional_open = false;
                        }
                        //de nodes moeten weg
                        //we gebruiken onze deleteNode, geen ruimte voor onDelete_... dus, maar dat is ook niet nodig omdat alle nodes gaan binnen deze tag
                        //dit is dus lokaal binnen één samenwerkbericht
                        cy.nodes(`[condtag = "${oldname}"]`).forEach(node => {
                            // logdelete(node);
                            //willen we eigenlijk wel een logdelete op elke node in dit geval? We loggen toch het verwijderen van een conditieset?
                            deleteNode(node); //lokaal in elke samenwerker
                        });
                        // model.logaction(`cond_delete`, condtag);
                    }

                    //oude nu weg
                    delete $scope.conditionele_tags[oldname];

                    //opslaan relevante wijziging
                    saveToModel(true); //wordt wel geskipt bij volgers
                } else {
                    //nieuw
                    if (!isVolg) {
                        model.logaction('cond_new', newname, volger, 'model');
                    }
                    $scope.conditionele_tags[newname] = true;
                    $scope.condtag = {
                        tag: newname,
                        state: 'cond'
                    };
                    //nog geen relevante wijzigingen, want een condtag bestaat pas echt
                    //als het elementen heeft
                }
                updateConditieclasses();
                log.debug($scope.condtag, $scope.conditionele_tags);

                if (model.werkmodus === "leid" && (!vanLeider)) {
                    //stuur door naar volgers
                    model.samenwerkbericht([{
                        actie: "changeCondtag",
                        oldname: oldname,
                        newname: newname
                    }]);
                }
                //en opslaan
                if (model.werkmodus !== 'volg') {
                    saveToModel(true); //relevant
                }

            }


            /**
             * Selecteer een andere condtag en/of state als de huidige
             * @param condtag
             * @param state
             * @param vanLeider
             * @param {string} [volger] id van de volger die de actie begon, voor loggen
             */
            function setCondtag(condtag, state, vanLeider, volger) {
                state = state || null;
                if (model.werkmodus === "volg" && !vanLeider) {
                    //stuur door
                    model.samenwerkbericht([{
                        actie: "setCondtag",
                        condtag: condtag,
                        state: state
                    }]);
                    return;
                    //ook gewoon niet zetten tot de leider het okee vindt
                }

                //uitvoeren
                cancelAddAction();
                saveUndoState();
                if (condtag) {
                    $scope.condtag = {
                        tag: condtag,
                        state: state
                    };
                    $scope.conditionele_tags[condtag] = true; //ook weergeven
                } else {
                    $scope.condtag = null;
                }
                updateConditieclasses();
                //en sowieso dicht
                $scope.conditional_open = false;

                if (model.werkmodus === "leid" && (!vanLeider)) {
                    //door naar de volgers
                    model.samenwerkbericht([{
                        actie: "setCondtag",
                        condtag: condtag,
                        state: state
                    }]);
                }
                //en opslaan
                if (model.werkmodus !== 'volg') {
                    if ($scope.condtag) {
                        model.logaction(`cond_set`, condtag, volger, 'model', {state: state});
                    } else {
                        model.logaction(`cond_reset`, '-', volger, 'model');
                    }
                    saveToModel(false); //niet relevant
                }
            }

            /**
             * Scope: stel een andere conditionele tag in.
             * @param condtag
             */
            $scope.setcondtag = function (condtag) {
                //we sturen het door, als er iets gewijzigd is
                if ($scope.condtag && $scope.condtag.tag === condtag) {
                    return; //never mind
                }
                setCondtag(condtag, 'cond'); //altijd beginnen met conditie
            };

            /**
             * Toon of verberg de elementen van een condtag
             * @param condtag
             * @param show
             * @param vanLeider
             * @param {string} [volger] id van eventuele volger die de acite startte
             */
            function setViewtag(condtag, show, vanLeider, volger) {
                if (model.werkmodus === "volg" && !vanLeider) {
                    //stuur door
                    model.samenwerkbericht([{
                        actie: "setViewtag",
                        condtag: condtag,
                        show: show
                    }]);
                    return;
                    //ook gewoon niet zetten tot de leider het okee vindt
                }
                //als dit de condtag is, dan moet hij aanblijven
                //dat controleren we als we de baas zijn
                if (model.werkmodus !== "volg" && $scope.condtag && $scope.condtag.tag === condtag) {
                    return;
                }
                saveUndoState(); //undoable
                $scope.conditionele_tags[condtag] = !$scope.conditionele_tags[condtag];
                updateConditieclasses();
                //en geef het door aan volgers
                if (model.werkmodus === "leid" && (!vanLeider)) {
                    //door naar de volgers
                    model.samenwerkbericht([{
                        actie: "setViewtag",
                        condtag: condtag,
                        show: show
                    }]);
                }
                if (model.werkmodus !== 'volg') {
                    model.logaction(`cond_view`, condtag, volger, 'model', {show: show});
                    saveToModel(false); //niet relevant
                }
            }

            /**
             * Scope: toggle het viewen van een conditionele tag
             * @param condtag
             */
            $scope.toggleviewtag = function (condtag) {
                //als dit de condtag is, dan beginnen we hier niet aan
                if ($scope.condtag && $scope.condtag.tag === condtag) {
                    return;
                }
                //anders roepen we de dispatcher aan
                setViewtag(condtag, !$scope.conditionele_tags[condtag]);
            };

            /**
             * Scope: open de dialoog voor wijzigen van de condtag, inclusief verwijderen
             * @param condtag
             */
            $scope.editcondtag = function (condtag) {
                cancelAddAction();
                _vraagCondTag(condtag, false).then(res => {
                    //wijzigen of verwijderen:
                    changeCondtag(condtag,
                        res.verwijder ? null : res.tag);
                }).catch(() => {
                    //cancel
                });
            };

            /**
             * Reset de state van de huidige condtag naar geen enkele. (de X-button)
             * We sturen het door
             */
            $scope.unsetcondtag = function () {
                if ($scope.condtag) {
                    setCondtag(null);
                }
            };

            $scope.setcondtagstate = function (state) {
                //we sturen het door naar de dispatch als dit nuttig is
                if ($scope.condtag && ($scope.condtag.state !== state)) {
                    setCondtag($scope.condtag.tag, state);
                }
            };

            /**
             * Helper: prompt voor een naam(wijziging)
             * @param orig originele tag (bij wijziging)
             * @param {boolean} [nieuw]
             * @private
             */
            function _vraagCondTag(orig, nieuw) {
                return $uibModal.open({
                    templateUrl: 'app/states/canvas/condtagprompt.html',
                    scope: $scope, //sub van ons,
                    backdrop: 'static',
                    controller: ['$scope',
                        function ($scope) {
                            orig = orig.toLowerCase();
                            Object.assign($scope, {
                                naam: orig,
                                feedback: false,
                                titel: nieuw ? 'CONDTAG.VOEGTOE.TITEL' : 'CONDTAG.WIJZIG.TITEL',
                                prompt: nieuw ? 'CONDTAG.VOEGTOE.PROMPT' : 'CONDTAG.WIJZIG.PROMPT',
                                nieuw: nieuw,
                                checkSluit: function () {
                                    if (!$scope.naam) {
                                        return; //had ui moeten doen
                                    }
                                    $scope.naam = $scope.naam.toLowerCase();
                                    if ($scope.naam !== orig && $scope.conditionele_tags.hasOwnProperty($scope.naam)) {
                                        $scope.feedback = "CONDTAG.FEEDBACK.BESTAATAL";
                                    } else {
                                        $scope.$close({
                                            verwijder: false,
                                            orig: orig,
                                            tag: $scope.naam
                                        });
                                    }
                                },
                                verwijder: function () {
                                    if (nieuw) {
                                        //hoort niet
                                        $scope.$dismiss(); //gewoon cancel
                                    }
                                    //we confirmen
                                    return sihwconfirm($translate.instant('ALGEMEEN.ZEKER'), $translate.instant('CONDTAG.VERWIJDERPROMPT'), $translate.instant('ALGEMEEN.JA'), $translate.instant('ALGEMEEN.NEE')).then(ok => {
                                        if (ok) {
                                            $scope.$close({
                                                verwijder: true,
                                                orig: orig
                                            });
                                        }
                                    });
                                }
                            });
                        }]
                }).result;
            }

////////////////////////////////////////////////////////////////////////////////
            /**
             * Scope: geef true als de normbalk afgebeeld moetworden
             * @returns {boolean}
             */
            $scope.normbalk = function () {
                return !!(api.userdata && api.userdata.projectdata && api.userdata.projectdata.norm_balk);
            }

            /**
             * Scope: geef true als de foutfeedback afgebeeld moet worden (vraagteken)
             * @returns {boolean}
             */
            $scope.foutfeedback = function () {
                return !!(api.userdata && api.userdata.projectdata && api.userdata.projectdata.norm_foutfeedback);
            }

            /**
             * Scope: geef true als de fouten gehighlight moeten worden. Dit is nu onafhankelijk van de foutfeedback (vraagteken)
             * @returns {boolean}
             */
            $scope.fouthighlight = function () {
                return !!(api.userdata && api.userdata.projectdata && api.userdata.projectdata.norm_foutfeedback_highlight);
            }

            /**
             * Scope: geef true als de bouwsuggesties afgebeeld moeten worden
             * @returns {boolean}
             */
            $scope.bouwsuggesties = function () {
                return (!$scope.simulateOn) && (api.userdata && api.userdata.projectdata && api.userdata.projectdata.bouwsuggesties);
            }


            /**
             * helper voor het vraagteken bij foutmodellen, is er een fout in interpretatie X?
             * @param interpretatie interpretatienummer
             */
            $scope.isNormfout = function (interpretatie) {
                if (!(model.norm && model.norminterpretaties && model.norminterpretaties[interpretatie])) {
                    return false; //geen normfout
                }
                let foutmodel = model.norminterpretaties[interpretatie].foutmodel;
                return Object.keys(foutmodel).some(id =>
                    foutmodel[id].fouten.length);

            };

            /**
             * Geef de nodes de juiste classes voor normfout en bepaal de af te beelden fouten
             */
            function updateFoutweergave() {
                cy.batch(() => {
                    cy.$('.normfout_select').removeClass("normfout_select"); //geselecteerd
                    $scope.foutbeschrijving = []; //leeg
                    let fouteElementen = []; //alle elementen met fouten
                    $scope.selectedfout = null;
                    if (model.norm && model.normmatch) {
                        for (let id of Object.keys(model.normmatch.foutmodel)) {
                            if (model.normmatch.foutmodel[id].fouten.length) {
                                let el = model.normmatch.foutmodel[id].el;
                                fouteElementen.push(el); //alle elementen bewaren
                                let elinfo = Object.assign({}, el);
                                if (!elinfo.nodename) {
                                    if (elementService.isRelation(el.type)) {
                                        let from = cy.$id(el.from);
                                        let to = cy.$id(el.to);
                                        elinfo.nodename = (from.nonempty() ? (from.data('nodename') || '?') : '?') + ' ➔ ' + (to.nonempty() ? (to.data('nodename') || '?') : '?')
                                    } else {
                                        elinfo.nodename = "(geen naam)";
                                    }
                                }
                                //speciaal?
                                elinfo.nodename = specialenaam(el.isZero ? '___qszero' : elinfo.nodename);

                                if (el.parentId) {
                                    elinfo.parent = cy.$(`#${el.parentId}`).data();
                                }
                                // log.debug(elinfo);
                                //we gaan nu kijken of we een vertaling kunnen vinden die past bij de fout
                                //we zoeken een paar verschillende combinaties van fouttype en elementtype
                                if (model.foutniveau > 0) { //foutniveau 0 = geen foutbeschrijving
                                    log.debug(`*** Bepaal tekst voor foutniveau ${model.foutniveau}`);
                                    for (let fout of model.normmatch.foutmodel[id].fouten) {
                                        let vertalinggevonden = false;
                                        let foutkeys = []; //hierin maken we de foutkeys die we zoeken
                                        //specifiek
                                        foutkeys.push(`${el.type || 'unk'}_${fout}`);
                                        foutkeys.push(`${el.type || 'unk'}_${fout}`);
                                        log.debug(`Fout ${foutkeys[0]}`, model.normmatch.foutmodel[id], elinfo);
                                        let ups = elementService.alleUps(el.type);
                                        //hoger op de tree
                                        for (let up of ups) {
                                            foutkeys.push(`${up}_${fout}`)
                                        }
                                        //en uiteindelijk generiek voor deze fout
                                        foutkeys.push(`any_${fout}`);
                                        //nu juist algemene fout
                                        foutkeys.push(`${el.type || 'unk'}_any`);
                                        //hoger op de tree
                                        for (let up of ups) {
                                            foutkeys.push(`${up}_any`)
                                        }
                                        //en de algemene fout dan maar
                                        foutkeys.push(`any_any`);
                                        //log.debug(foutkeys);
                                        for (let foutkey of foutkeys) {
                                            let transkey = `NORMFOUTEN.${foutkey}_${model.foutniveau}`; //eerst specifiek foutniveau
                                            let vertaling = $translate.instant(transkey, elinfo);
                                            if (transkey === vertaling) {
                                                //dan zonder foutniveau?
                                                transkey = `NORMFOUTEN.${foutkey}`;
                                                vertaling = $translate.instant(transkey, elinfo);
                                            }
                                            if (transkey !== vertaling) {
                                                //gelukt
                                                vertalinggevonden = true;
                                                if (foutkey === `any_any`) {
                                                    log.debug(`Generieke fout voor`, model.normmatch.foutmodel[id])
                                                }
                                                let fb = $scope.foutbeschrijving.find(fb => fb.tekst === vertaling);
                                                if (!fb) {
                                                    fb = {
                                                        tekst: vertaling,
                                                        fout: `${foutkeys[0]}_${model.foutniveau}`,
                                                        gebruikt: transkey,
                                                        elementen: []
                                                    };
                                                    $scope.foutbeschrijving.push(fb);
                                                }
                                                fb.elementen.push(el);
                                                break;
                                            }
                                        }
                                        if (!vertalinggevonden) {
                                            log.warn(`Geen foutbeschrijving voor ${fout} bij`, el);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    //en nu higlighten van alle normfouten
                    highlight_normfouten(fouteElementen);
                });
                //foutniveau? dit loggen we, via directe call (async)
                if (model.foutniveau) {
                    model.directlogAction('help', {
                        errorlevel: model.foutniveau,
                        errors: $scope.foutbeschrijving
                    });
                }
            }

            /**
             * Geef alle normfouten in de foutbeschrijving een class voor een kleurtje
             */
            function highlight_normfouten(elementen) {
                cy.$('.normfout').removeClass("normfout"); //alle fouten weg
                //alleen als de optie in het project aanstaat
                if ($scope.fouthighlight()) {
                    for (let el of elementen) {
                        //deze krijgt een kleurtje
                        highlight_normfoutelement(el, 'normfout');
                    }
                }
            }

            /**
             * Helper: geef een element, of de edges erbij een highlistclass voor het weergeven van normfouten (dus class normfout of normfout_select)
             * @param el
             * @param highlightClass
             */
            function highlight_normfoutelement(el, highlightClass) {
                if (el) {
                    //bij relaties juist ook de edges
                    if (elementService.isSubtypeOf(el.type, 'relation')) {
                        cy.$(`[orig_source='${el.id}'],[orig_target='${el.id}']`).addClass(highlightClass);
                    }
                    //en het element zelf
                    cy.$id(el.id).addClass(highlightClass);
                }
            }

            /**
             * Ux klik op een fout in een foutregel
             * @param foutregel De foutregel
             * @param index Index van de fout
             */
            $scope.highlight_fout = function (foutregel, index) {
                let foutindex = $scope.foutbeschrijving.indexOf(foutregel); //zelfde element
                if (model.werkmodus === 'volg') {
                    //uitbesteden
                    model.samenwerkbericht([{
                        actie: 'highlight_fout',
                        foutindex: foutindex,
                        elementindex: index
                    }]);
                } else {
                    highlight_fout(foutindex, index);
                }
            }

            /**
             * Interne afhandeling. Highlight een fout zoals hierboven gegenereerd
             * @param foutindex Index in $scope.foutbeschrijving
             * @param index Index in elementen van de foutbeschrijving
             * @param {string} [volger]
             */
            function highlight_fout(foutindex, index, volger) {
                cy.$('.normfout_select').removeClass('normfout_select');
                let foutregel = $scope.foutbeschrijving[foutindex];
                log.debug(`highlight_fout`, foutindex, index, foutregel);
                let el;
                $scope.selectedfout = {foutregel: foutregel, index: index};
                if (foutregel) {
                    el = foutregel.elementen[index];
                }
                if (el) {
                    highlight_normfoutelement(el, 'normfout_select');
                }
                if (model.werkmodus !== 'volg') {
                    //en volgers bijwwerken
                    model.samenwerkbericht([{
                        actie: 'highlight_fout',
                        foutindex: foutindex,
                        elementindex: index
                    }]);
                    model.directlogAction('foutselect', {
                        foutindex: foutindex,
                        elementindex: index,
                        fout: foutregel ? foutregel.fout : '-',
                        element: el ? el.id : '-'
                    }, 'model', 'model', false, volger)
                }
            }

            /**
             * Helper: reset de foutbeschrijving, zodat de feedback verdwijnt, en reset het foutniveau
             */
            function resetFoutbeschrijving() {
                model.resetFoutniveau(); //regelt de rest via events en samenwerking
            }

//debug-functie: log de mapping van een node of edge
//alleen aanroepen als log.logtOpLevel('debug') (of 'info') en alleen tijdelijk
//we loggen hier op info, zodat het wel kán
            function logMapping(el) {
                if (!(model.norm && model.normmatch)) {
                    return; //niets doen
                }
                log.info(`**** MAPPING ****`);
                let mapping = model.normmatch.mappings.find(m => m.werk && m.werk.id === el.id());
                if (!mapping) {
                    log.info(`Geen mapping`);
                } else {
                    log.info(`Werkelement`, mapping.werk);
                    log.info(`Match norm`, mapping.norm);
                    log.info(`Score-regels`, mapping.regels.join(", "));
                    log.info(`Score: ${mapping.score}`);
                    if (mapping.werk.from && mapping.norm.from) {
                        //klopt het?
                        let m2 = model.normmatch.mappings.find(m => m.werk && m.werk.id === mapping.werk.from);
                        log.info("MAPPING FROM", m2);
                        if (m2 && m2.norm.id === mapping.norm.from) {
                            log.info(`FROM KLOPT`);
                        } else {
                            log.info(`FROM FOUT`);
                        }
                    }
                    if (mapping.werk.to && mapping.norm.to) {
                        //klopt het?
                        let m2 = model.normmatch.mappings.find(m => m.werk && m.werk.id === mapping.werk.to);
                        log.info("MAPPING TO", m2);
                        if (m2 && m2.norm.id === mapping.norm.to) {
                            log.info(`TO KLOPT`);
                        } else {
                            log.info(`TO FOUT`);
                        }
                    }
                    log.info(mapping);
                }
                log.info(`**********************************************`);
            }


            /**
             * Scope-functies
             */

            /**************************** DATA / JSON *************************************/

            /**
             * Genereer onze modeldata als javascript-object (geen json)
             */
            function modelData() {
                //we bewaren niet zoveel. Een paar algemene dingen, de elements (nodes, niet edges) en wat we nodig hebben voor restore
                //we doen dus niet cy.json
                //we hebben veel moeite gedaan om de nodes puur te houden, zonder (versieafhankelijke) lay-outinfo
                //definities enzo zitten in de scratch, dus komen hier niet


                let res = {
                    cy: {
                        zoom: Math.round(cy.zoom() * 100) / 100 //2 cijfers achter de komma
                    },
                    nodes: {}, //op id
                    model: {  //logischer model, bevat de data
                        elements: {}, //elementen op id (de data)
                        types: {} //per type een array met ids
                    },
                    //toegevoegd: de conditiesets die er zijn, zodat ze ook undoable worden
                    //in eerdere versies waren die er nog niet, en werden ze uit
                    //de data van de nodes gehaald, dat doen we ook niet (zie reloadmodel)
                    condtags: $scope.conditionele_tags,
                    condtag: $scope.condtag
                };

                //we bewaren het meerendeel van nodes, maar de classes niet
                //die hergenereren we als nodig
                //ook edges en edgehandles worden opnieuw gegenereerd
                //filter op onderdelen vh model, niet eventuele simulatie
                //vergeet niet: dynalearn-relaties zijn óók nodes. De egdes kunnen we hergenereren
                $.each(cy.nodes(".model"), function (i, node) {
                    if (node.hasClass("simulation")) {
                        return; //continue - niet opslaan
                    }
                    let data = node.data();
                    //modeldata:
                    res.model.elements[data.id] = data;
                    if (!res.model.types[data.type]) {
                        res.model.types[data.type] = [];
                    }
                    res.model.types[data.type].push(data.id); //op type, maar alleen de ids
                    //grafische zaken
                    let n = node.json();
                    delete n.classes;
                    delete n.data;
                    res.nodes[data.id] = n;
                });
                return res;
            }

            pub.modelData = modelData; //ook publiek

            /**
             * Return de json van onze modelData
             */
            function graphJSON() {
                return JSON.stringify(modelData());
            }

            /**
             * Return een jpg img van het weergegeven model, optioneel met maxwidth en maxheight
             *
             * @param {Number} [maxwidth = 200] maximale breedte bij het schalen
             * @param {Number} [maxheight = 200] maximale hoogte bij het schalen
             * @returns {String} base64-string
             */
            function graphJpg(maxwidth, maxheight) {
                let img = cy.jpg({
                    output: 'base64',
                    full: true,
                    maxWidth: maxwidth || 1200,
                    maxHeight: maxheight || 1200
                });
                return img;
            }

            pub.graphJpg = graphJpg;

            /**
             * Helper rond model.logaction: log het maken van een node
             * @param newnode ele de gemaakte node
             * @param args [object] relevante argumenten
             * @param {string} [volger]
             */
            function logcreate(newnode, args, volger) {
                args = args || {};
                //voeg relevante argumenten toe
                let data = newnode.data();
                if (elementService.isSubtypeOf(data.type, 'relation')) {
                    //de from en to meenemen
                    args._fromroute = route(cy.getElementById(data.from));
                    args._toroute = route(cy.getElementById(data.to));
                }
                args.condtag = data.condtag;
                args.condstate = data.condstate;

                return model.logaction('create', route(newnode), volger, data.type, args);
            }

            /**
             * Helper rond model.logaction: log het verwijderen van een node (alleen de hoofdnode wordt gelogd)
             * @param node ele
             *              * @param args [object] relevante argumenten
             * @param {string} [volger]
             */
            function logdelete(node, args, volger) {
                args = args || {};
                let data = node.data();
                if (elementService.isSubtypeOf(data.type, 'relation')) {
                    //de from en to meenemen
                    args._fromroute = route(cy.getElementById(data.from));
                    args._toroute = route(cy.getElementById(data.to));
                }
                args.condtag = data.condtag;
                args.condstate = data.condstate;
                return model.logaction('delete', route(node), volger, data.type, args);
            }

            /**
             * Helper rond model.logaction: log een modificatie. In args moet staan wat dan
             * @param node
             * @param args [object] relevante argumenten
             * @param {string} [volger]
             */
            function logmodify(node, args, volger) {
                //TODO: aanroepen met volger
                args = args || {};
                let data = node.data();
                if (elementService.isSubtypeOf(data.type, 'relation')) {
                    //de from en to meenemen
                    args._fromroute = route(cy.getElementById(data.from));
                    args._toroute = route(cy.getElementById(data.to));
                }
                args.condtag = data.condtag;
                args.condstate = data.condstate;
                return model.logaction('modify', route(node), volger, data.type, args);
            }

            /**
             * Maak een string met daarin de route naar de node in begrijpelijke taal (in termen van parents)
             * @param node ele de node
             * @returns string
             */
            function route(node) {
                let routestr = "";
                //speciaal: bij quantity-space-elementen springen we direct naar de q, want de parents zijn de bovenliggende elementen
                let nodetype = node.data('type');
                if (elementService.isSubtypeOf(nodetype, 'quantity_space_element') || elementService.isSubtypeOf(nodetype, 'derivative_element')) {
                    routestr = route(getNodeUp(node, ['quantity'])) + "|"; //vanaf daar dus
                } else if (node.data('parentId')) {
                    routestr = route(cy.getElementById(node.data('parentId'))) + "|";
                }
                routestr += beschrijf(node);
                return routestr;
            }

            /**
             * Maak een leesbare beschrijving van een node, voor in log en rapportage
             * @param node ele de node
             * @returns string
             */
            function beschrijf(node) {
                let data = node.data();
                let nodename = data.nodename || "_";
                if (data.type == 'quantity_space_point' && data.isZero) {
                    nodename = 'zero';
                }
                let desc = (data.type || '_') + ":" + node.id() + ":" + nodename;
                return desc;
            }

            /*********************** SIMULATIEWEERGAVE ****************************
             * die simviewcontroller gaat over de simulatie, maar wij over de weergave in ons canvas (sort of)
             */

            /**
             * Return de simulatieelementen
             * @param {string} [filter] extra selectorfilter op de content
             * @returns {*}
             */
            function simulationContent(filter) {
                let alles = cy.$(".simulation");
                return filter ? alles.filter(filter) : alles;
            }

            pub.simulationContent = simulationContent;

            /**
             * Controleer of een node op de gewenste pos zou overlappen met een bestaande, zichtbare node
             * @param pos
             * @param marge
             * @return {boolean}
             */
            function overlap(pos, marge) {
                let overlap = false;
                cy.nodes(':visible').forEach(function (node) {
                    let npos = node.position();
                    if ((pos.x - marge) < npos.x && (pos.x + marge) > npos.x && (pos.y - marge) < npos.y && (pos.y + marge) > npos.y) {
                        overlap = true;
                        return false; //break
                    }
                });
                return overlap;
            }

            pub.overlap = overlap;

            /**
             *    Verwijder alle of gefilterde simulatiecontent
             *     @param {string} [filter] extra selectorfilter op de content
             */
            function removeSimulation(filter) {
                //qtips worden weggehaald via een on('remove')
                cy.remove(simulationContent(filter));
            }

            pub.removeSimulation = removeSimulation;

            /**
             * Zet / verwijder een class op alle (nu) beschikbare elementen om aan te geven dat
             * we een simulatie-state weergeven
             * @param inSimulate
             */
            function setInSimulate(inSimulate) {

                if (inSimulate) {
                    cy.elements().addClass('simulating');
                } else {
                    cy.elements().removeClass('simulating');
                }
            }

            pub.setInSimulate = setInSimulate;

            /**
             * Return alle quantitynodes
             * @returns {*}
             */
            function quantities() {
                return cy.$('[type = "quantity"]');
            }

            pub.quantities = quantities;

            /**
             * Return alle ineqs in het model
             * @return {*}
             */
            function modelIneqs() {
                //we moeten het negatief definieren, want hebben (vooralsnog) geen standaardclass voor modelnodes
                return cy.$(ineqfilter).not('.simulation');
            }

            pub.modelIneqs = modelIneqs;

            /**
             * Voeg een colletie nodes toe aan de simulatieweergave
             * @param {object|array|cy.eles} nodes
             * @param {function} [deleteCb] als gegeven dan wordt er een deletebutton toegevoegd aan de simnode. Roept deleteCB aan bij gebruik (met node als argument)
             * Vooralsnog is dat gewoon toevoegen met een toegevoegde class, kijken of er voor het type een menu is (sim_<type>) en eventueel een deletebutton
             */
            function addSimnodes(nodes, deleteCb) {
                let created = cy.add(nodes);
                created.forEach(function (node) {
                    node.addClass('simulation');
                    node.scratch('qtips', []); //voor menus bij simnodes (van het type sim_ plus type)
                    createMenuForSimnode(node);

                    //deletebutton?
                    if (deleteCb) {
                        let qtipdata = {
                            //id: 'deletebtn-' + nodeId,
                            node: node,
                            prerender: true,
                            content: {
                                text: function () {
                                    //steeds genereren
                                    let template = $templateCache.get("simNodeDeleteBtn");
                                    return $($compile(template)($scope)); //we maken een clone en compileren hem
                                }
                            },
                            position: {
                                effect: false,
                                my: 'right center',
                                at: 'left center',
                                adjust: {
                                    x: 5,
                                    cyViewport: true
                                }
                            },
                            tip: false,
                            style: {
                                def: false,
                                tip: false
                            },
                            show: {
                                ready: false,
                                effect: false,
                                solo: false
                            },
                            hide: {
                                fixed: true
                            },
                            events: {}
                        };
                        node.qtip(qtipdata);
                        node.scratch('deleteCb', deleteCb); //bewaar de delete callback
                        //bewaar deze qtip voor destroy op id
                        //moet nu want we kunnen alleen bij de laatste qtip op een element
                        node.scratch('qtips').push('#qtip-' + node.qtip('api').get('id'));
                        node.scratch('prullenbak', node.qtip('api')); //de prullenbak-api
                    }
                    //event voor verwijderen qtips
                    node.on('remove', function () {
                        //verwijder de qtips
                        let qtips = node.scratch('qtips');
                        if (qtips && qtips.length) {
                            $(qtips.join(', ')).remove(); //maken jquery van de id's
                        }
                    });
                });
                return created; //chained
            }

            pub.addSimnodes = addSimnodes;

            /**
             * Handle prullenbak bij simnode: run de deleteCallback opgegeven bij het aanmaken
             * @param event
             */
            $scope.doSimDeleteBtn = function (event) {
                let qtip = $(event.target).closest('.qtip');
                if (!qtip.length) {
                    return null;
                }
                let api = qtip.qtip('api');
                let node = api.get('node');
                let deleteCb = node.scratch('deleteCb');
                if (node && deleteCb && typeof (deleteCb) === 'function') {
                    deleteCb(node);
                }
            };

            /********************************************** Simfeedback **********************************/
            /**
             * Run de feedbackengine
             * Wordt gedaan als simuleren begint, eventueel alleen onder voorwaarden maar dat moet de aanroeper regelen
             */
            function runSimfeedback() {
                if (model.werkmodus === 'volg') {
                    return; //dan hoeft het niet, we krijgen het wel van de leider
                    //communicatie loopt dan via simcontrol
                }

                //laat de engine het werk doen
                $scope.simfeedback.run();
                if ($scope.simfeedback.beschikbaar) {
                    //loggen
                    model.directlogAction('simfb', $scope.simfeedback.feedback, 'model', 'model');
                }
            }

            /**
             * clear ux voor simfeedback
             */
            function clearSimfeedback() {
                cy.$('.highlighted').removeClass('highlighted');
                $scope.simfeedback.reset();
                $scope.showSimfeedback = false;
                $scope.selectedsimfeedback = null;
            }

            /**
             * Schakel ux voor sim-feedback aan of uit (scope)
             */
            $scope.toggleSimfeedback = function () {
                if (model.werkmodus === 'volg') {
                    simctrl.simfeedbackActie('toggle', !$scope.showSimfeedback);
                } else {
                    showSimfeedback(!$scope.showSimfeedback);
                }
            }

            /**
             * Interne afhandeling tonen feedback
             * @param show
             * @param {string} [volger]
             */
            function showSimfeedback(show, volger) {
                if (model.werkmodus !== 'volg') {
                    cy.$('.highlighted').removeClass('highlighted');
                    $scope.selectedsimfeedback = null;
                    $scope.showSimfeedback = !$scope.showSimfeedback;
                    simctrl.syncSimfeedback();
                    model.directlogAction('simfb_show', {show: show}, 'model', 'model', false, volger)
                }
            }

            pub.showSimfeedback = showSimfeedback;

            /**
             * Ux klik op een simfeedback
             * @param feedbackdesc object met type (key uit de feedback)) en index

             */
            $scope.highlight_feedback = function (feedbackdesc) {
                if (model.werkmodus === 'volg') {
                    //uitbesteden aan de leider
                    simctrl.simfeedbackActie('highlight', feedbackdesc);
                } else {
                    highlight_feedback(feedbackdesc);
                }
            }

            /**
             * Interne afhandeling Highlight een route in simfeedback zoals hierboven gegenereerd
             * @param feedbackdesc object met type en index. verwijst dus naar een entry in de simfeedbackengine.feedback. Geen directe objectidentity ivm samenwerking
             * @param {string} [volger]
             */
            function highlight_feedback(feedbackdesc, volger) {

                cy.$('.highlighted').removeClass('highlighted');
                $scope.selectedsimfeedback = feedbackdesc;
                if (feedbackdesc) {
                    let loop = $scope.simfeedback.feedback[feedbackdesc.type][feedbackdesc.index];
                    for (let el_id of loop) {
                        let el = cy.$id(el_id);
                        //bij relaties juist ook de edges
                        if (elementService.isSubtypeOf(el.data('type'), 'relation')) {
                            cy.$(`[orig_source='${el_id}'],[orig_target='${el_id}']`).addClass('highlighted');
                        }
                        //en het element zelf
                        el.addClass('highlighted');
                    }
                }
                if (model.werkmodus !== 'volg') {
                    //en volgers bijwwerken
                    simctrl.syncSimfeedback();
                    model.directlogAction('simfb_select', feedbackdesc, 'model', 'model', false, volger)
                }
            }

            pub.highlight_feedback = highlight_feedback;

            /**
             * communiceer de huidige simfeedback-status. Dit wordt opgevraagd door de simctrl, als het nodig is of als wij dat vragen (via simfeedbackChanged)
             * We checken niet of de feature wel ingeschakeld staat, dat gaat wel goed als de juiste data doorkomt
             */
            pub.simfeedback = function () {
                return {
                    showSimfeedback: $scope.showSimfeedback,
                    feedback: $scope.simfeedback.feedback,
                    selectedsimfeedback: $scope.selectedsimfeedback
                };
            }

            /**
             * Vanuit simulatieberichten komt er een nieuwe simfeedback door van onze leider, dus updaten we
             * Opnieuw checken we niet of deze feature wel aanstaat, dan wordt er toch null gegeven
             * @param status
             */
            pub.updateSimfeedback = function (status) {
                log.debug(`updateSimfeedback`, status);
                clearSimfeedback();
                if (status) {
                    //we doen het zo veilig mogelijk
                    if (status.showSimfeedback) {
                        $scope.showSimfeedback = true;
                    }
                    if (status.feedback) {
                        $scope.simfeedback.setFeedback(status.feedback);
                    }
                    if (status.selectedsimfeedback) {
                        //deze is omgezet in een index in de simfeedback, ivm objectidentity
                        highlight_feedback(status.selectedsimfeedback); //direct doen en doorvoeren
                    }
                }
            }

            /***********************************************************************************************/


            /********************* HELPERS **************************************/

            function __() {
                return $translate.instant.apply($translate, arguments);
            }

            function specialenaam(naam) {
                return (naam && naam.slice(0, 3) === "___") ? __(`SPECIALENAMEN.${naam}`) : naam;
            }

            /**
             * clone een object
             * @param obj
             */
            function clone(obj) {
                return angular.merge({}, obj);
            }

            /**
             * Regel een goede id
             * @param prefix
             * @return {*|string}
             */
            function getId(prefix) {
                return cytoscape.getId(prefix, cy);
            }

            pub.getId = getId; //ook publiek op onze canvas

            /********************* PublicI Interface********************/

//paar dingen die alleen in de public interface zitten
            /**
             * Public: Is dit een leeg model?
             * @return {boolean}
             */
            pub.emptyModel = function () {
                return cy ? cy.elements().empty() : true;
            };
            /**
             * Geef een referentie naar het cytocanvas-object terug. Niet gebruiken om zelf nodes toe te voegen! Maar wel voor ctrl.cy().collection() etc.
             * @return {*}
             */
            pub.cy = function () {
                return cy;
            };

//initialiseer
            init(); //doet ook registeren van de interface. We zijn nu klaar om verzoeken te ontvangen
        }
    ])
