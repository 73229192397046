/* 
* videosrc.service.js
* 2-12-2022 - Jelmer Jellema - Spin in het Web B.V.
*
*
* multilangfile.getSource(pad, naam, extensie, alleen_naam = false)-> promise(url|string|false)
*
* We kijken of de <pad>/<naam>_taal.<extensie> met taal de preferred language
* anders en, anders nl, en anders zonder taal. Resultaat wordt gecached.
* We returnen (in promise0 false als we hem niet vinden, of anders de hele url of alleen de gegenereerde filenaam
*/

dynalearn.service('multilangfile', ['$http', '$translate', '$q', 'sihwlog',
    function ($http, $translate, $q, sihwlog) {
        let cache = {};
        let log = sihwlog.logLevel('debug');
        return {
            /**
             *
             * @param pad
             * @param naam
             * @param extensie
             * @param [alleen_naam]
             * @returns {Promise<*>}
             */
            async getSource(pad, naam, extensie, alleen_naam) {
                //we doen dit async,maar sturen een $q terug
                log.debug(`getSource ${pad} ${naam} ${extensie}`);
                let gevonden = false; //return én cache als niet gevonden
                const cachekey = `${pad}_${naam}_${extensie}`;
                if (cachekey in cache) {
                    gevonden = cache[cachekey];
                    log.debug(`Cached: ${gevonden}`);
                }
                else {
                    //anders testen
                    let preftaal = $translate.preferredLanguage();
                    let talen = [preftaal];
                    for (let taal of ['en', 'nl']) {
                        if (preftaal !== taal) {
                            talen.push(taal);
                        }
                    }

                    let namen = talen.map(taal => `${naam}_${taal}.${extensie}`); //zonder pad
                    namen.push(`${naam}.${extensie}`);


                    for (let naam of namen) {
                        try {
                            log.debug(`Proberen ${naam}`);
                            await $http.head(`${pad}/${naam}`);
                            log.debug(`Gelukt!`);
                            gevonden = naam;
                            break;
                        } catch (_e) {
                            //door naar volgende
                        }
                    }
                }
                cache[cachekey] = gevonden; //kan false zijn, ofeen naam
                return $q.resolve(alleen_naam ? gevonden : `${pad}/${gevonden}`);
            }
        }
    }]);
