/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 5-4-2017
 * Time: 16:01
 *
 * Directive voor een panzoom-control voor een cytoscapejs-canvas
 *
 * <dlpanzoom cy="scopevar" [in-cy-container = 1] [min-zoom="0.1"] [max-zoom="10"]> koppelt de panzoom-control aan de cytoscape in de betreffende scopevar
 *
 */

dynalearn.directive('dlpanzoom',['$window','$interval','sihwlog',function($window, $interval,sihwlog) {
    var log = sihwlog.logLevel('debug');

    return {
        restrict: 'E', //element
        templateUrl: 'app/directives/dlpanzoom/dlpanzoom.html',
        scope: {
            cy: '=',
            inCyContainer: '=',
            minZoom: '=',
            maxZoom: '='
        },

        //alles scopen binnen 1 link-functie
        link: function (scope, element, attrs) {
            //elements:
            var $el = null, $slider = null, $handle = null;

            var cy = null; //de cytoscape instance
            var options = {
                inCyContainer: scope.inCyContainer ? (scope.inCyContainer === '1') : true, //plaats de slider in de container van de cy
                minZoom: scope.minZoom ? parseFloat(scope.minZoom) : 0.1, // min zoom level
                maxZoom: scope.maxZoom ? parseFloat(scope.maxZoom) : 10, // min zoom level, // max zoom level
                sliderPadding: 2, //ruimte tussen slider en randen
                zoomFactor: 0.05, //percentage verhoging / verlaging bij de zoomknoppen
                zoomDelay: 45 // ms tussen 2 acties van de zoomknoppen
            };
            var sliding = false; //zijn we aan het schuiven?
            var zooming = false; //zijn we aan het zoomen?
            var zoomInterval = null; //de interval promise van de zoomknoppen, om te cancelen
            var zoomCenterX, zoomCenterY; //positie van het centrum van de zoom, zodat alles mooi op zijn plek blijft
            /**
             * Initialiseer de events voor de cy, gegeven een cy
             */
            function initCyEvents() {
                if (!cy) {
                    return;
                }

                cy.on('zoom', function () {
                    if (!sliding) {
                        positionSliderFromZoom();
                    }
                });
            }

            //positioneer de slider
            //overgenomen en aangepast van cytoscape-panzoom
            function positionSliderFromZoom() {
                if (!cy) {
                    return;
                }
                var z = cy.zoom();
                var zmin = options.minZoom;
                var zmax = options.maxZoom;


                // assume (zoom = zmax ^ p) where p ranges on (x, 1) with x negative
                var x = Math.log(zmin) / Math.log(zmax);
                var p = Math.log(z) / Math.log(zmax);
                var percent = (p - x) / (1 - x);

                var min = options.sliderPadding;
                var max = $slider.width() - $handle.width() - 2 * options.sliderPadding;
                var left = percent * ( max - min );

                // constrain to slider bounds
                if (left < min) {
                    left = min
                }
                if (left > max) {
                    left = max
                }

                // move the handle
                $handle.css('left', left);
            }

            /**
             * Overgenomen van cytoscape-panzoom, omgebouwd naar horizontaal
             * @param evt
             * @param handleOffset
             */
            function setSliderFromMouse(evt, handleOffset) {
                if (handleOffset === undefined) {
                    handleOffset = 0;
                }

                var min = 0 + options.sliderPadding;
                var max = $slider.width() - $handle.width() - 2 * options.sliderPadding;
                var left = evt.pageX - $slider.offset().left - handleOffset;

                // constrain to slider bounds
                if (left < min) {
                    left = min
                }
                if (left > max) {
                    left = max
                }

                var percent = (left - min) / ( max - min );

                //de handle ook verplaatsen, want dat doen we tijdens sliden niet bij cy.onZoom
                // move the handle
                $handle.css('left', left);

                var zmin = options.minZoom;
                var zmax = options.maxZoom;

                // assume (zoom = zmax ^ p) where p ranges on (x, 1) with x negative
                var x = Math.log(zmin) / Math.log(zmax);
                var p = (1 - x) * percent + x;

                // change the zoom level
                var z = Math.pow(zmax, p);

                // bound the zoom value in case of floating pt rounding error
                if (z < zmin) {
                    z = zmin;
                } else if (z > zmax) {
                    z = zmax;
                }

                zoomTo(z);
            }

            /**
             * Er is ergens op de slider geklikt
             * @param e
             */
            function sliderKlik(e) {
                if ((!cy) || e.target == $handle[0]) {
                    //geen init, of we klikken op de handle
                    return false;
                }
                setSliderFromMouse(e);
            }

            function handleKlik(e) {
                stopZoom();
                startZooming();
                $handle.addClass('active');
                var offset = e.offsetX; //waar beginnen we?
                var mouseevent = null; //wordt elke x ms gecheckt ipv op elk event

                var slide = function (slideEvent) {
                    //setSliderFromMouse(slideEvent, offset);
                    mouseevent = slideEvent; //in het volgende interval dus weer bijwerken
                }

                var verwerkSlide = function () {
                    //aangeroepen per interval om de load niet te hoog te maken
                    if (mouseevent) {
                        //een nieuw event om te verwerken
                        setSliderFromMouse(mouseevent, offset);
                    }
                }

                var w = $($window);
                w.on("mousemove touchmove", slide);

                var verwerkSlideInterval = $interval(verwerkSlide, 25); //elke zoveel ms kijken of er geslide is

                w.one("mouseup touchend", function () {
                    zooming = false;
                    sliding = false;
                    w.off("mousemove touchmove", slide);
                    $interval.cancel(verwerkSlideInterval);
                    $handle.removeClass('active');
                });

                return false;
            }

            /**
             * Handle de resetknop
             */
            function resetZoom() {
                if (!cy)
                    return;
                if (cy.elements().size() === 0) {
                    cy.reset();
                } else {
                    cy.fit(null,44); //beetje padding
                    cy.center();
                }
                return false;
            }

            function zoomIn(e) {
                e.preventDefault();
                e.stopPropagation();
                return zoomInUit(1); //groter
            }

            function zoomUit(e) {
                e.preventDefault();
                e.stopPropagation();
                return zoomInUit(-1);
            }

            function zoomInUit(richting) {
                if (!cy) {
                    return;
                }
                stopZoom();
                var doZoom = function () {
                    var zoom = cy.zoom();
                    var lvl = cy.zoom() * (1 + richting * options.zoomFactor );

                    if (lvl < options.minZoom) {
                        lvl = options.minZoom;
                    }

                    if (lvl > options.maxZoom) {
                        lvl = options.maxZoom;
                    }

                    if ((lvl == options.maxZoom && zoom == options.maxZoom) ||
                        (lvl == options.minZoom && zoom == options.minZoom)
                    ) {
                        return;
                    }

                    zoomTo(lvl);
                };

                startZooming();
                $($window).one('mouseup touchend blur', stopZoom);
                doZoom();
                zoomInterval = $interval(doZoom, options.zoomDelay);

                return false;
            }

            /**
             * Niet langer repeaten op de zoombuttons
             */
            function stopZoom() {
                if (zooming) {
                    $interval.cancel(zoomInterval);
                    $($window).off('mouseup touchend blur', stopZoom);
                }
                zooming = false;
            }

            function calculateZoomCenterPoint() {
                if (!cy) {
                    return;
                }
                var container = $(cy.container());
                zoomCenterX = container.width() / 2;
                zoomCenterY = container.height() / 2;
            }

            function startZooming() {
                if (zooming) {
                    stopZoom();
                }
                zooming = true;
                calculateZoomCenterPoint();
            }


            function zoomTo(level) {
                if (!zooming) { // for non-continuous zooming (e.g. click slider at pt)
                    calculateZoomCenterPoint();
                }

                cy.zoom({
                    level: level,
                    renderedPosition: {x: zoomCenterX, y: zoomCenterY}
                });
                log.debug(cy.zoom());
            }


            function init() {
                //elementen:
                $el = element; //module-globaal bewaren
                $slider = $el.find(".dl-panzoom-slider");
                $handle = $el.find('.dl-panzoom-slider-handle');

                //set verplichte styles op element
                element.css({position: 'absolute'});
                element.addClass('dl-panzoom');
                scope.$watch(scope.cy, function () {
                    if (scope.cy === cy) {
                        return; //zelfde
                    }
                    cy = scope.cy;

                    if (options.inCyContainer) {
                        //verplaats hem
                        $(cy.container()).prepend($el);
                    }

                    initCyEvents();
                    positionSliderFromZoom();
                });

                //event handlers voor de knoppen
                $el.find('.dl-panzoom-reset').on('mousedown touchstart', resetZoom);
                $el.find('.dl-panzoom-zoom-out').on('mousedown touchstart', zoomUit);
                $el.find('.dl-panzoom-zoom-in').on('mousedown touchstart', zoomIn);

                //voor een plek op de slider
                $slider.on('mousedown touchstart', sliderKlik);
                //en een plek op de handle
                $handle.on('mousedown touchstart', handleKlik);
            }

            init();
        }
    };
}]);
