/**
 * Created by Lennaerd on 2-10-2015.
 */

/**
 * 2 verschillende urls: / voor het begin van de applicatie en /actie voor acties met argumenten. Die argumenten sturen we gewoon door naar build.
 *
 * 2020: 2 verschillende dialogen. Een stapvoorstapdialoog, en een algemene. Allemaal verstopt in één dialoog. En dan ook nog opvangen van openargs en wachtwoordlink
 */
dynalearn.controller('login', ['$scope', '$state', '$stateParams', '$timeout', 'sihwlog', 'api', '$uibModal', function ($scope, $state, $stateParams, $timeout, sihwlog, api, $uibModal) {
    let log = sihwlog.logLevel('debug');

    log.debug('Login', $stateParams);

    //formuliermodel. Maar we vullen pas in de verschillende stappen
    $scope.login = {
        //domein: localStorage.logindomein || "",
        // project: localStorage.loginproject || ""
    };

    if ($stateParams.projectcode)
    {
        //Code-login = altijd uitloggen
        log.debug(`directe link naar projectcode`);
        api.logout();
        $scope.stap = "code";
        $scope.login.code = $stateParams.projectcode;
    }
    else {

        //stap: credentials, domein, project of advanced
        $scope.stap = "credentials";

        api.isLoggedIn().then(function (res) {
            if (res) {
                $state.go('build', {openAction: $stateParams.openAction, openArg: $stateParams.openArg}); //door naar bouwen
                return;
            }
        });
    }

    $scope.codeLogin = function() {
        //direct naar codelogin
        $scope.formfeedback = "";
        $scope.stap = "code";
    }
    /**
     * Ga naar geavanceerd inloggen
     */
    $scope.advancedLogin = function () {
        //zet eventuele bekende info, maar alleen aan het begin
        $scope.formfeedback = "";
        if ($scope.stap === 'credentials') {
            $scope.login.domein = $scope.login.domein || localStorage.logindomein || "";
            $scope.login.project = $scope.login.project || localStorage.loginproject || "";
        }
        $scope.stap = "advanced";
    };

    /**
     * Juist terug naar de normale login vanuit advanced
     */
    $scope.normaleLogin = function () {
        $scope.formfeedback = "";
        $scope.stap = "credentials"; //alles lekker laten hangen
    }

    /**
     * Terug naar stap 1
     */
    $scope.naarBegin = function () {
        $scope.formfeedback = "";
        $scope.stap = "credentials";
        $scope.login.domein = $scope.login.project = "";
    }


    /**
     * Handel het inloggen af
     */
    function doLogin() {
        $scope.formfeedback = "LOGIN.BEZIG";
        //ok, wat we sturen hangt af van de stap
        let data = {
            username: $scope.login.username,
            password: $scope.login.password
        };
        if (['domein','project','advanced','fixed_advanced'].includes($scope.stap)) {
            data.domein = $scope.login.domein;
        }
        if (['project','advanced','fixed_advanced'].includes($scope.stap)) {
            data.project = $scope.login.project;
        }

        api.login(data).then(
            function (res) {
                /* we komen hier altijd.
                Res heeft nu:
                loggedin: true / false - is men volledig ingelogd
                kiesdomein?: array. Als dit er is, dan moet er een domein gekozen worden
                kiesproject?: array. Als dit er is, dan moet er een project gekozen worden
                domein?: het domein op de inloggegevens slaan (door keuze of doordat er maar 1 is)
                project?: het project waarop nu is ingelogd
                userdata?: bij ingelogd ook de userdata

                Bij loggedin = true zijn domein, project en userdata altijd gegeven

                 */

                $scope.formfeedback = "";
                if (res) {
                    //ingelogd?
                    if (res.loggedin) {
                        //sla domein en project op
                        localStorage.logindomein = res.domein;
                        localStorage.loginproject = res.project;
                        //door naar canvas
                        $state.go('build', {openAction: $stateParams.openAction, openArg: $stateParams.openArg});
                        log.info(`${res.domein}/${res.project}`);
                    } else if (res.kiesdomein) {
                        //we moeten een domein kiezen
                        $scope.login.domein = ""; //geen
                        $scope.login.kiesdomein = res.kiesdomein;
                        $scope.stap = "domein"; //kies meer
                    } else if (res.domein && res.kiesproject) {
                        $scope.login.domein = res.domein;
                        $scope.login.project = "";
                        $scope.login.kiesproject = res.kiesproject;
                        $scope.stap = "project";
                    } else {
                        $scope.stap = "credentials";
                        $scope.formfeedback = "LOGIN.MISLUKT";
                    }
                } else {
                    $scope.formfeedback = "LOGIN.MISLUKT";
                }
            }
        );
    }

    $scope.doLogin = doLogin;

    /**
     * Open een dialoogje voor wachtwoordvergeten
     */
    $scope.wachtwoordVergeten = function () {
        $uibModal.open({
            templateUrl: 'app/states/login/wachtwoordvergeten.html',
            backdrop: 'static',
            controller: ['$scope', '$uibModalInstance', '$translate', 'sihwconfirm', function ($scope, $uibModalInstance, $translate, sihwconfirm) {
                $scope.vraagLink = function () {
                    log.debug($scope.gebruikersnaam);
                    return api.stuurWachtwoordlink($scope.gebruikersnaam).then(() => {
                        return sihwconfirm($translate.instant('WACHTWOORDVERGETEN.VRAAGLINK.VERZONDEN'), $translate.instant('WACHTWOORDVERGETEN.VRAAGLINK.MAILUITLEG'), $translate.instant('ALGEMEEN.OK'), false)
                            .then(() => $uibModalInstance.close());
                    }).catch(e => {
                        $uibModalInstance.dismiss(e);
                    });
                }
            }]
        }).result.catch(e => {
            log.error(e);
        });
    }

    /**
     * Aanmelden met code en e-mailadres. Het backoffice stuurt eventueel een mail
     */
    $scope.aanmeldenCode = function() {
        $scope.formfeedback = "LOGIN.CODEBEZIG";
        if ($scope.stap === 'code') {
            //alleen met code, we kijken wat er terugkomt

            api.aanmeldenCode($scope.login.code, $scope.login.username).then(res => {
                //res = false is mislukt
                $scope.formfeedback = "";
                if (res) {
                    //wat is de status?
                    switch (res.status) {
                        case 'AL_ACCOUNT':
                            $scope.formfeedback = "LOGIN.AL_ACCOUNT";
                            $scope.stap = "fixed_advanced"; //advanced maar met domein en project verborgen
                            $scope.login.domein = res.domein;
                            $scope.login.project = res.project;
                            break;
                        case 'TOEGANG_MET_ACCOUNT':
                            $scope.formfeedback = "LOGIN.TOEGANG_MET_ACCOUNT";
                            $scope.stap = "fixed_advanced";
                            $scope.login.domein = res.domein;
                            $scope.login.project = res.project;
                            break;
                        case 'BEVESTIGINGSCODE_GESTUURD':
                            $scope.formfeedback = "";
                            $scope.login.bevestigingscode = $scope.login.password = $scope.login.password2 = "";
                            $scope.stap = "bevestigaccount";
                            break;
                    }
                } else {
                    $scope.formfeedback = "LOGIN.MISLUKT";
                }
            });
        }
        else if ($scope.stap === 'bevestigaccount')
        {
            api.aanmeldenCode($scope.login.code, $scope.login.username, $scope.login.bevestigingscode, $scope.login.password, $scope.login.naam).then(res => {
                $scope.formfeedback = "";
                if (res && res.status === 'ingelogd')
                {
                    log.debug(`We zijn ingelogd`,res);
                    localStorage.logindomein = res.domein;
                    localStorage.loginproject = res.project;
                    //door naar canvas
                    $state.go('build');
                    log.info(`${res.domein}/${res.project}`);
                    api.toast('LOGIN.AANGEMELD_MET_CODE');
                }
                else {
                    $scope.formfeedback = "LOGIN.MISLUKT";
                }
            });
        }
    }

}]);
