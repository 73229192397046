/**
 * Spin in het Web Apeldoorn
 * Created by Jelmer on 2-12-2016.
 *
 * Verschillende filter en helpers
 */

angular.module('dl.helpers',[])
	.directive('convertToNumber', function() {
		return {
			require: 'ngModel',
			link: function(scope, element, attrs, ngModel) {
				ngModel.$parsers.push(function(val) {
					return parseInt(val, 10);
				});
				ngModel.$formatters.push(function(val) {
					return '' + val;
				});
			}
		};
	});
