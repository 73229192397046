/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 23-8-2016
 * Time: 10:29
 *
 * publicI service: controllers, services, whatever kan een interface registreren in public
 * die interface is dan beschikbaar voor alle componenten die de service injecten
 * Een interface bestaat alleen uit functies (in de this van die interface). Geen properties. Speciale functies beginnen met _i_
 * een interface kan in en uit scope gaan. Daarop kan getest worden via events en functies. Als een interface er niet is, zullen (eerder geregistreerde) interfacefuncties een error throwen. Is de interface er vervolgens weer wel, dan doen de functies het ook weer (eventueel wel op een andere this natuurlijk)
 *
 *
 * Interface registeren
 * ---------------------
 * publicI.register(interfacenaam,interface)
 * publicI.registerFromScope(interfacenaam,$scope,interface)
 * Registreer een interface (een object met functies in een this-scope).
 * Met registerFromScope wordt de interface vanzelf unregister-ed als de scope destroyed wordt
 * Throwt een error als de interface er al is
 *
 * publicI.unregister(interfacenaam)
 * Onregistreer een interface
 *
 * Interface gebruiken
 * --------------------
 * publicI.is(interfacenaam): boolean. Check op beschikbaarheid van een interface
 * publicI.when(interfacenaam | [interfacenamen]): promise. Resolvet zodra de interface beschikbaar is / alle interfaces beschikbaar zijn (evt direct)
 * publicI.i(interfacenaam) -> interface: haal de interface op. Throwt als hij er niet is
 * interface._i_available(): boolean. Check of de interface nog steeds beschikbaar is. Een interface-object kan onbeschikbaar worden en later weer beschikbaar zijn
 * interface.<functie>(...): roep een interfacefunctie aan. Throwt als de interface niet beschikbaar is (voor eerder geregistreerde functies)
 *
 * Reageer op beschikbaarheid
 * ---------------------------
 * We gebruiken alleen rootscope-events, geen promises. Dit om de gc niet te lastig te maken
 * Checken of een interface er is kan door public.is(..) en als die false geeft $scope.on('public.reg.<interfacenaam>').
 *
 * publicI.reg.<interfacenaam> event als een interface actief wordt
 * publicI.unreg.<interfacenaam> event als een interface weg is
 */

angular.module('sihw.publicI', ['sihw.sihwlog'])
.factory('publicI', [
	'$rootScope', '$q', 'sihwlog', function ($rootScope, $q, sihwlog) {
		var $log = sihwlog.logLevel('warn');
		$log.log('Start publicI-factory');
		var interfaces = {};

		/**
		 * Intern: Maak een nieuw interfaceobject dat nog niet beschikbaar is, met een unresolved promise
		 * Als het interfaceobject al eerder is gemaakt, wordt dat teruggegeven
		 * gebruikt voor when-calls enzo
		 * @param {string} naam
		 * @returns {Object} Interfaceobject
		 */
		function _getInterface(naam)
		{
			if (! interfaces[naam])
			{
				//scope var voor verwijzing in _i_available
				var iMeta =  interfaces[naam] =
				{
					naam: naam,
					available: false,
					scope: null, //als gekoppeld aan een scope
					unregisterDestroy: null, //als gekoppeld aan een scope staat hier de ontkoppel-functie
					imported: null, //welke functies zijn imported, wordt zo gezet
					defer: $q.defer(), //de defer (maakt promises) die resolvt als de interface available komt
					iObject: { //het publieke object
						_i_available: function () {
							return !!(iMeta.available); //boolean existence
						}
					}
				};
			}
			return interfaces[naam];
		}

		/**
		 * Maak een interfaceobject en voeg hem toe.
		 * @param naam
		 * @param interfaceFunctions
		 * @param [scope]
		 */
		function createInterface(naam, interfaceFunctions, scope) {
			var iMeta = _getInterface(naam);
			if ( iMeta.available) {
				throw(new Error('Dubbele interfaceregistratie ' + naam));
			}

			//is er een scope?
			iMeta.scope = scope || null;
			if (scope) {
				//registreer het verwijderen van de scope
				iMeta.unregisterDestroy = scope.$on('$destroy', function () {
					//is de interface nog van deze scope?
					if (iMeta.scope == scope) {
						deleteInterface(naam);
					}
				});
			}

			//importeer de functies uit de interface
			//als er in de vorige ronde functies waren die er nu niet zijn
			//dan blijft hun throw-implementatie uit deleteInterface werken

			iMeta.imported = []; //altijd nieuwe lijst, van de eventuele oude blijven we af
			Object.keys(interfaceFunctions).forEach(function (fnName) {
				var fn = interfaceFunctions[fnName];
				if (typeof(fn) == 'function') {
					iMeta.imported.push(fnName);
					iMeta.iObject[fnName] = fn; //maak hem beschikbaar
				}
			});

			iMeta.available = true;
			//en roep het rond
			$rootScope.$broadcast('publicI.reg.' + naam);
			//de defer in het object wordt nu geresolved
			iMeta.defer.resolve(true);
		}

		/**
		 * Verwijder de interface, hij is dus niet meer beschikbaar
		 * @param naam
		 */
		function deleteInterface(naam) {
			var iMeta = _getInterface(naam);
			if ( iMeta.available) {
				//hebben we een handler op een scope-destroy? Dan unregisteren we die
				if (iMeta.unregisterDestroy) {
					iMeta.unregisterDestroy();
				}
				iMeta.scope = iMeta.unregisterDestroy = null;
				//zet alle functies op een foutmelding, zodat opgeslagen interfacevars niet meer geldig zijn
				iMeta.imported.forEach(function (fnName) {
					iMeta.iObject[fnName] = function () {
						throw(new Error("Aanroep van onbeschikbare functie " + fnName + " in interface " + naam));
					};
				});
				//en wij halen hem niet weg, maar zetten hem op unavailable
				iMeta.available = false;
				//oude promises zijn al resolved, nieuwe partijen die nu willen weten of de interface er is
				//hebben dus een nieuwe promise nodig
				iMeta.defer = $q.defer();
				//en roep het rond
				$rootScope.$broadcast('publicI.unreg.' + naam);
			}
		}

		return {
			/**
			 * Registreer de interface. Throw als hij al bestaat
			 * @param interfacenaam
			 * @param interfaceObject
			 */
			register: function (interfacenaam, interfaceObject) {
				createInterface(interfacenaam, interfaceObject);
			},

			/**
			 * Registreer de interface en zorg dat hij automatisch wordt verwijderd als de meegegeven scope weg is
			 * @param interfacenaam
			 * @param scope
			 * @param interfaceObject
			 */
			registerFromScope: function (interfacenaam, scope, interfaceObject) {
				createInterface(interfacenaam, interfaceObject, scope);
			},

			/**
			 * Onregistreer een interface. Ga stilzwijgend door als hij er niet is. (Dan ook geen event)
			 * @param interfacenaam
			 */
			unregister: function (interfacenaam) {
				deleteInterface(interfacenaam);
			},

			/**
			 * Controleer of een interface beschikbaar is
			 * @param interfacenaam
			 * @returns {boolean}
			 */
			is: function (interfacenaam) {
				//we gebruiken niet _getInterface, want die maakt data aan
				var iMeta = interfaces[interfacenaam];
				return !!(iMeta && iMeta.available);
			},

            /**
             * Geef een promise die resolvt als de gevraagde interfaces beschikbaar zijn. Ook als dat nu al zo is.
             * @param {String|String[]} interfaces 1 of meer interfaces die er moeten zijn
             * @return {anifn.promise|is.promise|*}
             */
            when: function(interfaces) {
                if (! angular.isArray(interfaces))
                {
                    interfaces = [interfaces];
                }

                var promises = [];
                interfaces.forEach(function(interfacenaam)
                {
                	promises.push(_getInterface(interfacenaam).defer.promise);
                });
                //en een mooie nieuwe combinatiepromise
                return $q.all(promises);
            },

			/**
			 * Geef de interface met de betreffende naam terug Throw als hij er niet is
			 * @param interfacenaam
			 */
			i: function (interfacenaam) {
				var iMeta = interfaces[interfacenaam];
				if (!(iMeta && iMeta.available)) {
					throw('Opvragen onbeschikbare interface')
				}
				return iMeta.iObject; //de interface
			}
		};
	}

]);