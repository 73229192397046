/**
 * Created by Lennaerd on 1-10-2015.
 * initializatie van de app.
 * elk scherm heeft een eigen view en controller gegroepeerd in een map.
 *
 * De app definieert ook wat globale scopefuncties in de rootscope
 *
 */
const dynalearn = angular.module('dynalearn',
    ['ui.router', 'ui.bootstrap', 'pascalprecht.translate',  'ngSanitize', 'SpinJS2', 'sihw.sihwlog', 'sihw.wait','sihw.publicI', 'sihw.controls', 'sihw.switch', 'dl.helpers', 'sihw.confirm', 'sihw.memcache', 'myApp.erroralert', 'dynalearn.version', 'chart.js', 'dynalearn.moment-filter'
    ]);


dynalearn.run(['$rootScope', '$state', 'api', function ($rootScope, $state, api) {

    //we doen niet moeilijk met checken op statechange: er zijn maar 2 states
    //als het er meer worden hangen we de approuter er wel tussen
    //voor nu zal state build degene zijn die checkt of er wel is ingelogd
    //verbied toegang tot andere states dan login als niet ingelogd
    //we gaan altijd eerst naar login. Die bepaalt wel of we door kunnen

}]);

// Prevent the backspace key from navigating back. same for delete btn
//wij unbinden niet, want bijv uibmodal kan al gebonden hebben
$(document).ready(function () {
    $(document)/*.unbind('keydown')*/.bind('keydown', function (event) {
        var doPrevent = false;
        if (event.keyCode === 8 || event.keyCode === 46) {
            var d = event.srcElement || event.target;

            if ((d.tagName.toUpperCase() === 'INPUT' &&
                    (
                        d.type.toUpperCase() === 'TEXT' ||
                        d.type.toUpperCase() === 'PASSWORD' ||
                        d.type.toUpperCase() === 'FILE' ||
                        d.type.toUpperCase() === 'SEARCH' ||
                        d.type.toUpperCase() === 'EMAIL' ||
                        d.type.toUpperCase() === 'NUMBER' ||
                        d.type.toUpperCase() === 'DATE')
                ) ||
                d.tagName.toUpperCase() === 'TEXTAREA') {
                doPrevent = d.readOnly || d.disabled;
            }
            else {
                console.warn('BACKSPACE')
                doPrevent = true;
            }
        }

        if (doPrevent) {
            event.preventDefault();
        }
    });
});
