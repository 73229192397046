/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 25-1-2016
 * Time: 22:04
 *
 * Configuratie van de verschillende states
 *
 */

dynalearn.config(['$stateProvider', '$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider) {
        $stateProvider
            //we hebben een simpele loginstate zonder argumenten, en een andere state met openAction. Ze gaan allebei naar login
            //maar als we de / url argumenten geven, kunnen we niet meer naar /build komen...
            .state('login', {
                url: '/', //optioneel een openactie met arg, vanuit bo en material
                templateUrl: 'app/states/login/login.html',
                controller: 'login'
            })
            .state('actie', {
                url: '/actie/:openAction/:openArg', //optioneel een openactie met arg, vanuit bo en material
                templateUrl: 'app/states/login/login.html',
                controller: 'login'
            })
            .state('code', {
                url: '/code/:projectcode',
                templateUrl: 'app/states/login/login.html',
                controller: 'login'
            })
            .state('lesdirect', {
                url: '/lesdirect/:lesdirectkey',
                controller: 'lesdirect'
            })
            //wwlink doen we in een simpele state, zodat het ook op mobiel goed werkt
            .state('wwlink', {
                url: '/ww/:wwlink',
                templateUrl: 'app/states/wwlink/wwlink.html',
                controller: 'wwlink'
            })
            .state('build', {
                url: '/build/',
                params: {
                    openAction: null,
                    openArg: null
                },
                templateUrl: 'app/states/canvas/canvas.html',
                controller: 'cytocanvas'
            });
        $urlRouterProvider.otherwise('/');
    }]);
