/* 
* moment-filter.js
* 13-12-2022 - Jelmer Jellema - Spin in het Web B.V.
*
* {{datetimestring | moment: format}}
*/

angular.module('dynalearn.moment-filter', [])
    .filter('moment', [
        function () {
            return function (datetimestr, format) {
                try {
                    let m = moment(datetimestr);
                    if (m.isValid()) {
                        return m.format(format);
                    }
                    else {
                        return  ''
                    }
                } catch (e) {
                    return "";
                }
            };
        }
    ]);
