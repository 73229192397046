/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 17-8-2016
 * Time: 22:21
 *
 * Nested controller voor de code rond simulatie. Werkt veel samen met de parent en de modelservice.
 * Exporteert code via de publicI-interface 'simctrl'
 *
 * Dit zou een service moeten zijn met een directive voor het canvas
 *
 */

dynalearn.controller("cytocanvas.simview",
    ['$scope', '$timeout', 'sihwlog', 'publicI', 'cytoscape', 'elementService', 'api', 'model', function ($scope, $timeout, sihwlog, publicI, cytoscape, elementService, api, model) {
        var log = sihwlog.logLevel('debug');

        var simcy = null; //de cytoscape-instance met canvas
        var simulatie = null; //de simulatie die geladen is
        let selectievolgorde = 0; //teller voor sorteren op selectievolgorde
        let prevsim = {
            simulatie: null,
            finished: false,
            positions: false
        };
        var pub = {}; //dit wordt onze public interface
        var modelctrl = null; //de publicI van de modelviewcontroller

        var nextSimStep = "start"; //via updateNextStep steeds bijgehouden, in pub met getNextSimStep
        var valuehistory = false; //tonen we valuehistory
        var ineqhistory = false; //tonen we ineqhistory
        var showplan = null; //id van een planner voor showresult. We plannen het zodat we het niet 100x doen bij multiselect

        var simstatusPlanner = null;
        var simEventLock = false; //lock acties van samenwerker die hier ook weer events veroorzaken

        //settings rond history:
        var history_settings = {
            afstand_x: 150, //pixels vanaf de qnode
            kolom: 30, //pixels voor elke statekolom
            mainvalue: 20, //pixels voor de waarde zelf binnen de kolom.
            hogereOrdeKolom: 10, //pixels voor 2e en 3e afgeleide elk
            interval: 20, //pixels voor elke interval
            point: 20, //pixels voor elk point (is grijze ruimte waarin we een wit streepje tekenen)
            staterow: 20, //hoogte voor de staterow
            labelSpace: 15 //pixels tussen container en label
        };

        //scopevariabelen. Als in een service / directive, we werken met een parentscope
        var pscope = $scope.$parent;

        pscope.stateselect = {}; //verschillende flags worden gezet op het moment dat er zaken geselecteerd / gedeselecteerd worden
        pscope.history = {}; //welke history is ingeschakeld?

        //we kunnen als modelview er is. Kijk uit voor deadlocks
        publicI.when('modelctrl').then(init);

        //initialiseer en registreer de pub interface
        function init() {
            modelctrl = publicI.i('modelctrl'); //de public interface van de modelview-controller
            $scope.simcy = simcy = cytoscape.lib({
                layout: {
                    name: 'preset'
                },
                container: $('#simcanvas')[0],
                zoomingEnabled: true,
                userZoomingEnabled: true,
                enableSelectUsingLabel: true,
                panningEnabled: true,
                userPanningEnabled: true,
                boxSelectionEnabled: false,
                //min en maxzoom voor user - we zitten er zelf ook wel aan na tekenen states
                minZoom: 0.25,
                maxZoom: 4,
                selectionType: 'single',
                touchTapThreshold: 8,//default 8 (sane value -Larger values will almost certainly have undesirable consequences.)
                desktopTapThreshold: 10,//default 4 (sane value - Larger values will almost certainly have undesirable consequences.)
                autolock: false, /*nodes zijn niet te verplaatsen*/
                autoungrabify: false, /*nodes zijn niet vast te pakken.*/
                autounselectify: false,
                hideLabelsOnViewport: true,
                motionBlur: true, //optimalisatie
                textureOnViewport: true, //optimalisatie bij pan/zoom
                pixelRatio: 'auto',
                ready: function () {
                    /*onze eigen styling voor cytoscape inladen en setten. check
                     * cytoscape hanteert andere css rules
                     * kijk voor info op: http://js.cytoscape.org/#core/style en http://js.cytoscape.org/#style
                     */
                    //let op: cy is nog niet gezet als deze sync wordt uitgevoerd, we gebruiken this
                    var self = this;
                    $.ajax({
                        type: 'GET',
                        url: "content/style/simcytostyle.cytocss",
                        cache: false
                    }).done(function (response) {
                        self.style(response.toString()).update();
                    });
                }
            });
            //features vanuit onze algemene cytoscape-service: automove
            cytoscape.initFeatures(simcy, {multiselect: false});

            initCyEvents(); //afsplitsing

            //en de scope-events
            initScopeEvents();

            //simulatieknop;
            updateNextStep();

            //en registreer onze public interface. Op het einde van de init om deadlocks te voorkomen
            publicI.registerFromScope('simctrl', $scope, pub);

        }

        /**
         * Init event in het canvas
         */
        function initCyEvents() {
            /*select / unselect van simnodes heeft invloed op het getoonde resultaat in het model */
            simcy.on('select unselect', 'node', selectAction);
            if (log.logtOpLevel('debug')) {
                simcy.on('tap', () => {
                    log.debug(alleKruisingen().map(
                        k => {
                            return `${k[0].source().id()}-${k[0].target().id()} x ${k[1].source().id()}-${k[1].target().id()}`;
                        }
                    ));
                });
            }
            simcy.on('endAutomove', function (_e, data) {
                onChangePosition(data);
            });
        }

        /**
         * Reageer op select / unselect van een node. We zetten even een timer voor sorteren op selectievolgorde en tonen dan het resultaat
         */
        function selectAction(event) {
            var node = this; //voor de duidelijkheid

            //class aan alle verbonden edges
            //als een edge nou een outSelected én een inSelected heeft, kunnen we de style aanpassen (zie simcytostyle.css)
            //we doen dit alleen als de selectievolgorde past
            if (node.selected()) {
                log.debug(`select`,this.id());
                node.outElements('edge').addClass('outSelected');
                node.inElements('edge').addClass('inSelected');
            } else {
                log.debug(`unselect`,this.id());
                node.outElements('edge').removeClass('outSelected');
                node.inElements('edge').removeClass('inSelected');
                node.removeData('padcycle'); //het onthouden waar een padcycle begint houdt nu ook op
            }
            //we willen de volgorde van selectie bewaren
            //over tijd, en over samenwerkers
            //als eigenschap van de nodes voor het versturen naar
            //samenwerkers

            //maar niet als we de simstatus updaten, want dan zit de juiste volgorde al in de data

            if (!simEventLock) {
                node.data('selectievolgorde', selectievolgorde++); //ook bij unselect, want je moet toch filteren op :selected
            }

            //plan het tonen.
            //leider en lokaal moeten gewoon tonen (en leider moet ook versturen, dat doen we voor de snelheid eerst)
            //volger: als we onze simstatus aan het updaten zijn, moeten we tonen inplannen, anders alleen sturen en wachten op een update-bericht


            if (!showplan) {
                //dan plannen, zodat het per serie acties maar 1 keer gebeurd
                var lokaalInUpdatesimstatus = simEventLock; //want we gaan async, dus even de scope inhalen
                showplan = $timeout(function () {
                    log.debug(`showplan`);
                    showplan = 0;

                    //in alle gevallen (volg, leid, lokaal) het volgende:
                    checkStateSelect();
                    //edges tussen 2 opvolgende selecties krijgen een extra class
                    simcy.edges().removeClass('opRoute');
                    let statesOpvolgorde = simcy.nodes('node[type = "state"]:selected').sort(function (a, b) {
                        return a.data('selectievolgorde') - b.data('selectievolgorde');
                    });
                    for(let i = 0; i < statesOpvolgorde.length - 1; i++)
                    {
                        statesOpvolgorde[i].outElements('edge').intersection(statesOpvolgorde[i+1].inElements('edge')).addClass('opRoute');
                    }
                    //en edges tussen padcycle=voor (laatste state voor een cycle) en padcycle=in (eerste state in een cycle) ook
                    let voorcycle = simcy.nodes('[padcycle = "voor"]');
                    if (voorcycle.nonempty())
                    {
                        let incycle = simcy.nodes('[padcycle = "in"]');
                        if (incycle.nonempty())
                        {
                            log.debug(`We hebben een voorcycle en incycle: kleur de edge`);
                            simcy.edges(`[source = "${voorcycle.id()}"][target = "${incycle.id()}"]`).addClass('opRoute');
                        }
                    }

                    //en dit alleen in specifieke gevallen:
                    if (model.werkmodus === 'volg') {
                        //zijn we niet bezig met een update in opdracht van de leider?
                        //dan sturen we de selectactie door
                        if (!lokaalInUpdatesimstatus) {
                            stuurSelected();
                            //en meer hoeven we nu niet te doen, dat gebeurt bij het update-bericht
                            return;
                        }
                        //anders doorvallen naar de updates
                    }
                    //leider meteen de simulatiestatus sturen, niet plannen maar doen
                    if (model.werkmodus === 'leid') {
                        planStuursimulatiestatus(true);
                    }
                    //
                    //als we niet in update zitten moeten we loggen
                    //inUpdatesimstatus wordt gebruikt als er vanuit een volger /leider acties zijn
                    //die dit soort events veroorzaken
                    if (!lokaalInUpdatesimstatus) {
                        log.debug(`logselected!`);
                        logSelected();
                    }


                    //en uitvoeren
                    if (!lokaalInUpdatesimstatus) {
                        log.debug(`we roepen showResult aan`);
                        showResult();
                    } else {
                        log.debug(`we roepen showResult nu niet aan`);
                    }
                });
            }
        }

        /**
         * autoMove-code is klaar met drag.
         * @param data Het automove-object, met .all voor alle nodes met gewijzigde posities
         */
        function onChangePosition(data) {
            var posities = {};
            data.all.forEach(function (node) {
                posities[node.id()] = node.position();
            });
            if (model.werkmodus !== 'lokaal') {
                simulatiebericht('positie', {posities: posities});
            }
        }

        /**
         * Init events / functies in de scope
         */
        function initScopeEvents() {
            //bij wijziging in samenwerking moeten we wellicht de simstatus rondroepen
            $scope.$on('model.werkmodusChanged', onWijzigSamenwerking);
            $scope.$on('api.notify.clientOnline', onWijzigSamenwerking);
        }

        /**
         * er is iets gewijzgd in de samenwerking - andere werkmodus, client erbij etc.
         * als we leiden, dan sturen we de simulatiestatus rond
         */

        function onWijzigSamenwerking() {
            if (model.werkmodus === 'leid') {
                planStuursimulatiestatus(); //direct sturen
            }
        }

        /**
         * Reset de hele simulatie
         * @param {boolean} [skipSamenwerking] als true, dan geen verending van de simulatiestatus
         */
        function reset(skipSamenwerking) {
            //we bewaren het
            prevsim.finished = (nextSimStep === "finished");
            prevsim.simulatie = simulatie;
            prevsim.positions = {};
            //bewaar alle posities:
            simcy.nodes().forEach(node => {
                prevsim.positions[node.id() ?? '-'] = Object.assign({}, node.position());
            });
            clearResult(); //weg met het resultaat uit de modelview
            //wanneer we een simulatie stoppen verwijderen we alle elementen in simcy
            //omdat we iedere keer opnieuw de nodes toevoegen/ opnieuw simuleren
            simcy.nodes().remove();
            simulatie = null; //weg met de simulatie
            valuehistory = ineqhistory = false; //uit
            pscope.history = {}; //leeg
            hideAllValueHistory();
            updateNextStep();
            if (!skipSamenwerking) {
                planStuursimulatiestatus();
            }
        }

        pub.reset = reset;

        /**
         * Vraag het canvas om een resize-operatie uit te voeren
         */
        function resizeCanvas() {
            if (simcy) {
                simcy.resize();
            }
        }

        pub.resizeCanvas = resizeCanvas;

        /***************** SIMULATIESTATES *********************/

        /**
         * Begin met het afbeelden van de simulatiestates, gegeven de meegestuurde simulatie
         * @param {Object} simdata Object met simulatiedata volgens het format van het backend
         * @param {boolean} fullsim Als true, dan beelden we gelijk de volle simulatie af qua states
         */
        function startSimulatie(simdata, fullsim) {

            //is dit nog hetzelfde als de vorige, en full sim?
            let restorepos = false;
            if (fullsim && simdata && prevsim.finished) {
                let zelfde = prevsim.simulatie && simdata && angular.equals(prevsim.simulatie.start, simdata.start) && angular.equals(prevsim.simulatie.states, simdata.states);
                if (zelfde) {
                    restorepos = prevsim.positions; //posities per state, zie reset
                }
            }

            reset();
            simulatie = simdata; //bewaren
            //full simulation?
            if (fullsim) {
                simulateFull();
            } else {
                //het scenario
                var modelNode = {//dit is het "scenario" het startpunt van de simulatie states
                    group: 'nodes',
                    position: {x: 50, y: 50},
                    data: {
                        type: 'state',
                        state: null, //begin
                        id: 'sn0',
                        num: 0,
                        geplaatst: true //deze is al geplaatst
                    },
                    classes: 'scenario'
                };
                simcy.add(modelNode).select(); //gelijk selecteren
                $.each(simulatie.start, function (i, statenum) {
                    addState(simulatie.states['s' + statenum], 'open');
                });
                updateNextStep();
                drawPositions();
            }
            //posities aanpassen?
            if (restorepos) {
                for (let id of Object.keys(restorepos)) {
                    simcy.$id(id).position(Object.assign({}, restorepos[id]));
                }
                initZoomEnFit();
            }
            planStuursimulatiestatus(); //stuur de huidige status
            /* //en even ademenen en dan geforceerd redraw
             setTimeout(function () {
             simcy.elements("node:visible").select().unselect()
             }, 400);*/
        }

        pub.startSimulatie = startSimulatie; //aangeroepen door maincontroller

        /**
         * Maak een volle simulatie. Intern, dus we gaan ervan uit dat we gewoon kunnen
         */
        function simulateFull() {
            //teken het scenario
            var modelNode = {//dit is het "scenario" het startpunt van de simulatie states
                group: 'nodes',
                position: {x: 50, y: 50},
                data: {
                    type: 'state',
                    state: null, //begin
                    id: 'sn0',
                    num: 0,
                    geplaatst: true //deze is al geplaatst
                },
                classes: 'scenario'
            };
            simcy.add(modelNode).select(); //gelijk selecteren
            //states is object
            $.each(simulatie.states, function (prop, s) {
                addState(s, 'closed');
            });

            updateNextStep();
            doLayout(); //en layouten, stuurt ook simstatus
        }

        /**
         * Doe de volgende stap in de simulatie: open state worden terminated, etc
         */
        function nextStep() {
            clearResult(); //weg met zichtbare resultaten
            //en weg met de selectie
            simcy.nodes().unselect();

            //terminate, order, close, maar andersom, omdat we anders elke stap voor elke state doen

            //probleem:  close levert nieuwe states op, die open zijn en dus hieronder direct getermineerd worden
            //daarom flaggen we ze even:
            simcy.nodes().forEach(function (node) {
                node.data('bestaat', true)
            });
            //alle states die ordered zijn sluiten

            simcy.nodes('[status = "ordered"]').forEach(simulateCloseState);
            //alle states die terminated zijn ordenen
            simcy.nodes('[status = "terminated"]').forEach(function (s) {
                simulateTransitie(s, 'ordering')
            });
            //alle states die 'open' zijn termineren, als ze al bestonden voorhet closen van de ordered states
            simcy.nodes('[status = "open"][bestaat]').forEach(function (s) {
                simulateTransitie(s, 'terminatie')
            });

            updateNextStep();
            drawPositions();
            planStuursimulatiestatus(); //stuur de huidige status
        }

        pub.nextStep = nextStep;

        /**
         * Zet onze nextSimStep var goed, voor uitlezen vanuit parentscope (via pub)
         */
        function updateNextStep() {
            //nexSimtStep wordt start, terminate, order, close of finished
            //start is als er geen states zijn of alle states uit de simulatie zichtbaar zijn

            //bij open states volgt terminate etc
            if ((!simulatie) || simcy.nodes().empty()) {
                nextSimStep = "start";
            } else if (simcy.nodes('[status = "open"]').nonempty()) {
                nextSimStep = "terminate";
            } else if (simcy.nodes('[status = "terminated"]').nonempty()) {
                nextSimStep = "order";
            } else if (simcy.nodes('[status = "ordered"]').nonempty()) {
                nextSimStep = "close";
            } else {
                //niets meer te doen
                nextSimStep = "finished";
            }
        }

        //uitleesbaar:
        pub.getNextSimStep = function () {
            return nextSimStep;
        }

        /**
         * Herbouw de layout van de stategraph
         * Door request via publicI, of na full layout. Herpositioneert ook alle states
         */
        function doLayout() {
            //2017 11 24: we doen geen timeout meer eromheen, want dat breekt stuurSimulatiestatus

            //dagre layout was hoopvol maar bakte er weinig van met veel nodes
            simcy.resize();

            //volgers doen verder niets, dat moet de leider maar regelen
            if (model.werkmodus === 'volg') {
                initZoomEnFit(); //alleen dit
                return;
            }

            //alle states en transities opnieuw
            simcy.nodes('[type = "state"],[type = "transitie"]').removeData('geplaatst'); //opnieuw plaatsen
            var scenario = simcy.getElementById('sn0');
            if (scenario) {
                scenario.position({x: 50, y: 50});
                scenario.data('geplaatst', true); //deze is geplaatst
            }
            drawPositions(); //dus alles opnieuw
            initZoomEnFit();

            planStuursimulatiestatus(false, true); //stuur de huidige status, met fitten
        }

        pub.doLayout = doLayout;

        /**
         * Helper bij doLayout en updateSimstatus: zet een maximum zoom, en doe een intiele fit
         */
        function initZoomEnFit() {
            simcy.fit(null,4);
            var z = simcy.zoom();
            var minz = 0.1, maxz = 10;
            if (z < minz) {
                log.debug(`Zoom ${z} te klein. We zetten hem op ${minz}`)
                simcy.zoom(minz);
            } else if (z > maxz) {
                log.debug(`Zoom ${z} te groot. We zetten hem op ${maxz}`)
                simcy.zoom(maxz);
            }
            simcy.center(); //centreren
        }

        /**
         * Standaardcode voor plaatsen items. De fancy stuff zit in nicestates
         */
        function drawPositions() {
            let repositioned = false;
            //TIJDELIJK
            // log.debug(`Scenario op`, simcy.$id('sn0').position());

            do {
                repositioned = false;
                //alle niet geplaatste states en transities
                ///
                simcy.nodes('[type = "state"][^geplaatst],[type = "transitie"][^geplaatst]').forEach(function (stateOfTransitie) {
                    //we vinden zijn eerste parent, en vanaf daar alle ongeplaatste subnodes
                    var parentState = stateOfTransitie.inElements('[type = "state"][geplaatst]').first();
                    if (!parentState) {
                        //later
                        return true; //continue
                    }
                    var parentPos = parentState.position();
                    var subItems = parentState.outElements('[type = "state"][^geplaatst],[type = "transitie"][^geplaatst]');
                    //hoeveel ruimte?
                    var numsub = subItems.length; //minimaal 1 anders waren we hier niet
                    //er zijn states of transities, nooit allebei (toch?)
                    let tussenruimte = subItems.is('[type = "transitie"]') ? 5 : 10;
                    var ssize = subItems.first().outerWidth(); //hoogte is gelijk
                    var as1 = numsub * (ssize + tussenruimte) - tussenruimte;
                    var plaatspos = {};

                    var plaatsing_dx = 0, plaatsing_dy = 0;
                    var bb = {};
                    var benodigdebreedte, benodigdehoogte;
                    if ($scope.resizeDirection == "verti") {
                        benodigdehoogte = as1;
                        bb.left = parentPos.x + ssize + tussenruimte;
                        bb.top = parentPos.y - benodigdehoogte / 2;
                        bb.right = bb.left + ssize;
                        bb.bottom = parentPos.y + benodigdehoogte / 2;
                        //zoek ruimte, maar houd alleen rekening met al geplaatste nodes
                        while (!simcy.emptyArea(bb.left, bb.right, bb.top, bb.bottom, '[geplaatst]')) {
                            log.debug(`${stateOfTransitie.id()} past niet op`,bb.left + ssize /2, bb.top + ssize / 2);
                            bb.left += tussenruimte;
                            bb.right += tussenruimte;
                            /*  bb.top += tussenruimte;
                              bb.bottom += tussenruimte;*/
                        }

                        plaatspos = {
                            x: bb.left + ssize / 2,
                            y: bb.top + ssize / 2
                        };
                        plaatsing_dy = ssize + tussenruimte;
                    } else {
                        benodigdebreedte = as1;
                        bb.left = parentPos.x - benodigdebreedte / 2;
                        bb.top = parentPos.y + ssize + tussenruimte;
                        bb.right = parentPos.x + benodigdebreedte / 2;
                        bb.bottom = bb.top + ssize;
                        //zoek ruimte
                        while (!simcy.emptyArea(bb.left, bb.right, bb.top, bb.bottom,
                            '[geplaatst]')) {
                            bb.top += tussenruimte;
                            bb.bottom += tussenruimte;
                        }
                        plaatspos = {
                            x: bb.left + ssize / 2,
                            y: bb.top + ssize / 2
                        };
                        plaatsing_dx = ssize + tussenruimte;
                    }

                    subItems.forEach(function (substate) {
                        substate.position(Object.assign({}, plaatspos));
                        repositioned = true;
                        substate.data('geplaatst', true);
                        plaatspos.x += plaatsing_dx;
                        plaatspos.y += plaatsing_dy;
                    });
                    //dus breaken we nu de foreach, helemaal opnieuw zoeken
                    return false; //break
                });

            } while (repositioned); //blijf ongeplaatste nodes proberen te plaatsen, zolang er roering is
            initZoomEnFit();
        }

        /**
         * Probeer de states met weinig kruizingen lekker neer te zetten
         */
        async function niceStates() {
            //we zetten ons block buiten angular om, en wachten tot hij er is

            let blocker = $('#simcanvasblocker');

            await new Promise(resolve => {
                blocker.show(99, resolve)
            });
            const wachtnabeste = 1500; //zoveel ms nadat we een nieuwe beste hebben zoeken we naar een betere
            let bestkruisingen = 99999;
            let bestposities = {};

            async function runLayout() {
                return new Promise(resolve => {
                    simcy.layout(
                        /* {
                             name: 'random',
                             stop: resolve
                         }*/
                        {
                            name: 'cose',
                            animate: false,
                            randomize: true, //nodig voor eerste positie
                            fit: false, //doen we later
                            stop: resolve //klaar
                        }).run();
                });
            }

            let bestemeting = Date.now();
            let i = 0;
            while (Date.now() - bestemeting < wachtnabeste) {
                i++;
                await runLayout();
                let kruisingen = alleKruisingen().length;
                if (kruisingen < bestkruisingen) {
                    //deze is beter
                    simcy.nodes().forEach(node => {
                        bestposities[node.id()] = Object.assign({}, node.position());
                    });
                    bestkruisingen = kruisingen;
                    if (kruisingen === 0) {
                        break; //klaar
                    } else {
                        bestemeting = Date.now();
                    }
                }
            }
            //goed zetten
            for (let id of Object.keys(bestposities)) {
                simcy.$id(id).position(bestposities[id]);
            }
            initZoomEnFit();
            blocker.hide();
            planStuursimulatiestatus(false, true); //stuur de huidige status, met fit
        }

        pub.niceStates = niceStates;


        function alleKruisingen() {

            //welke edges kruizen?
            //dotproduct tussen AB en CD is (B.x-A.x)*(D.x-C.x) + (B.y-A.y)*(D.y-C.y)
            //als dit negatief is, dan kruizen ze
            let edges = simcy.edges('.nextstate').toArray();
            let AB;
            let kruisingen = [];
            while (AB = edges.shift()) {
                //basisinfo

                let A = AB.source();
                let Apos = AB.sourceEndpoint(); //dus niet in de node, maar op de rand
                let B = AB.target();
                let Bpos = AB.targetEndpoint();

                //we werken dus met exacte eindpunten
                let ABx_min = Math.min(Apos.x, Bpos.x); /*+ (A.outerWidth() / 2)*/ //ruimte van node erbij op, anders kruisen lijnen elkaar in de node
                let ABx_max = Math.max(Apos.x, Bpos.x); /*- (A.outerWidth() / 2)*/
                let ABy_min = Math.min(Apos.y, Bpos.y); /*+ (A.outerHeight() / 2)*/
                let ABy_max = Math.max(Apos.y, Bpos.y);/* - (A.outerHeight() / 2)*/

                let AB_f = AB.lijnfunctie(); //zie cytoscape.service. Geeft een slope (m) en intercept (b) voor y=mx + b; of {x: ...} voor een vertikale lijn

                for (let CD of edges) {
                    //vind het kruispunt op de lijn en bepaal of dat op deze lijn zit
                    //2e lijnfunctie om in de vergelijking te stoppen
                    let C = CD.source();
                    let Cpos = CD.sourceEndpoint();
                    let D = CD.target();
                    let Dpos = CD.targetEndpoint();

                    let CDx_min = Math.min(Cpos.x, Dpos.x);
                    let CDx_max = Math.max(Cpos.x, Dpos.x);
                    let CDy_min = Math.min(Cpos.y, Dpos.y);
                    let CDy_max = Math.max(Cpos.y, Dpos.y);
                    let CD_f = CD.lijnfunctie();
                    /*

                                        log.debug(`controleer ${A.id()}-${B.id()} x ${C.id()}-${D.id()}`);
                    */

                    //specials: AB is verticaal
                    if ('x' in AB_f) {
                        //CD ook?
                        if ('x' in CD_f) {
                            //    log.debug(`beiden verticaal`)
                            //allebei verticaal. Ze kruizen niet. Ze lopen parallel, of overlappen
                            continue;
                        } else {
                            //  log.debug(`Eerste verticaal`);
                            //eerste vertikaal, tweede niet
                            //kruispunt is op x = AB_f.x, ligt de bijbehorende y op de lijnen?
                            let y = CD_f.m * AB_f.x + CD_f.b; //de x staat vast, dit is de y
                            //ligt dat op de edge? de y waarde moet tussen ABy_min en ABy_max zitten
                            if (y > ABy_min && y < ABy_max && y > CDy_min && y < CDy_max) {
                                kruisingen.push([AB, CD]);
                            }
                        }
                    } else if ('x' in CD_f) {
                        //   log.debug(`tweede verticaal`);
                        //tweede vertikaal, eerste niet
                        //kruispunt op die x, ligt de bijbehorende y op die lijn?
                        let y = AB_f.m * CD_f.x + AB_f.b;
                        //ligt die x op de eerste edge?
                        if (y > ABy_min && y < ABy_max && y > CDy_min && y < CDy_max) {
                            kruisingen.push([AB, CD]);
                        }
                    } else if (AB_f.m !== CD_f.m) {
                        //niet parallel of precies overlappend
                        //ze kruizen ergens in het universum, waar de x en y gelijk zijn
                        /*
                        y = m1x + b1, y = m2x + b2.
                        Kruis: m1x + b1 = m2x + b2 <->
                        m1x = m2x + b2 - b1 <->
                        m1x - m2x = b2 - b1 <->
                        (m1 - m2)x = (b2 - b1) <->
                        x = (b2 - b1) / (m1 - m2)
                         */
                        let x = (CD_f.b - AB_f.b) / (AB_f.m - CD_f.m); //is nooit delen door 0, want dat is parallel
                        let y = AB_f.m * x + AB_f.b;
                        //prima, ligt dat op de lijn?
                        //   log.debug(`kruising ${A.id()}-${B.id()} met ${CD.source().id()}-${CD.target().id()} op x=${x} y=${y}`);
                        //       log.debug(`ABx_min ${ABx_min} ABx_max ${ABx_max} ABy_min ${ABy_min} ABy_max ${ABy_max}`);
                        //     log.debug(`CDx_min ${CDx_min} CDx_max ${CDx_max} CDy_min ${CDy_min} CDy_max ${CDy_max}`);
                        if (x > ABx_min && x < ABx_max && y > ABy_min && y < ABy_max && x > CDx_min && x < CDx_max && y > CDy_min && y < CDy_max) {

                            //kruist, tenzij in node

                            //  log.debug('KRUIST');
                            // log.debug(`kruispunt`, x, y);
                            kruisingen.push([AB, CD])
                        }
                    }
                }
            }
            return kruisingen;
        }

        /**
         * Voeg een simulatiestate toe aan de bestaande simulatie.
         * @param s
         * @param {string} [status] De status van de state - standaard 'open'
         * Gaat uit van stap voor stap
         * Positie moet zelf gedaan worden. Kan met drawPositions
         */
        function addState(s, status) {

            status = status || "open";

            var statenode = simcy.add({
                group: 'nodes',
                data: {
                    type: 'state',
                    sim: s, //de container met siminformatie
                    id: 'sn' + s.num,
                    num: s.num,
                    status: status,
                    automovegroup: 'sn' + s.num,
                    automoveorder: 100, //deze beweegt met transities, niet andersom
                    nomovewithparent: true //geen automove door parent, maar we doen wel een movegrouep
                },
                classes: 'state'
            });
            //aan scenario?
            if (s.start) {
                simcy.add({
                    group: "edges",
                    selectable: false,
                    data: {
                        source: 'sn0',
                        target: statenode.id(),
                        id: getId('l')
                    },
                    classes: 'nextstate'
                });
            }

            //check andere links
            checkStateLinks(statenode);
        }

        /**
         * Maak een state getermineerd / geordend door de transitienodes te tonen. Vereist daarna herplaatsten via drawPositions o.i.d.
         *
         * @param s statenode
         * @param transtype 'terminatie' of 'ordering', 'closure' (bij closure alleen de closures zonder opvolger, tekent geen nieuwe states!)
         */
        function simulateTransitie(s, transtype) {
            var state = s.data('sim');

            var transities = [], idtype;
            switch (transtype) {
                case 'terminatie':
                    transities = state.terminaties;
                    idtype = 't';
                    s.data('status', 'terminated'); //alvast
                    break;
                case 'ordering':
                    transities = state.orderings;
                    idtype = 'o';
                    s.data('status', 'ordered');
                    break;
                case 'closure':
                    transities = [];
                    $.each(state.closures, function (i, c) {
                        if (!c.to.length) //alleen closures zonder opvolger
                        {
                            transities.push(c);
                        }
                    });
                    idtype = 'c';
                    s.data('status', 'closed');
                    //tekent geen nieuwe states, dat moet apart gebeuren
                    break;

                default:
                    log.error('Onbekend transitietype', transtype);
                    return;
            }
            $scope.transities = transities;

            //verwijder alle oude transities bij deze state
            simcy.nodes('[type = "transitie"][statenum = "' + state.num + '"]').unselect().remove();

            $.each(transities, function (i, transitie) {
                var id = s.id() + idtype + i;
                simcy.add({
                    group: 'nodes',
                    data: {
                        // parent: s.id(), //compound
                        type: 'transitie',
                        transitietype: transtype,
                        statenum: state.num,
                        // statenode: s, //ongebruikt en geeft cycle in json voor samenwerken
                        sim: transitie, //de container met simulatieinfo, voor weergeven in showResult
                        statesim: state,
                        id: id,
                        identifier: transitie.identifier,
                        nomovewithparent: true,
                        automovegroup: s.id() //met de state moven
                    },
                    classes: 'transitie ' + transtype
                });
                simcy.add({
                    group: "edges",
                    selectable: false,
                    data: {
                        weight: 20,
                        source: s.id(),
                        target: id,
                        id: getId('l')
                    },
                    classes: 'transition'
                });
            });
        }

        /**
         *
         * Sluit een geordende state in de stap voor stap simulatie
         * @param s statenode
         */
        function simulateCloseState(s) {
            //transitienodes: alleen de closures zonder opvolger
            simulateTransitie(s, 'closure'); //zet onze state ook op closed

            var state = s.data('sim');

            //nieuwe states
            $.each(state.to, function (i, statenum) {
                //statenum is een state die er wel of niet al is
                if (simcy.getElementById('sn' + statenum).empty()) {
                    //nieuwe state
                    addState(simulatie.states['s' + statenum]); //doet ook de links, omdat we al closed zijn
                }
            });
            //en altijd de links checken
            checkStateLinks(s);
        }

        /**
         * Check of we pijlen van/naar de betreffende statenode moeten maken
         * @param statenode
         */
        function checkStateLinks(statenode) {
            //we tekenen pijlen NAAR deze node als de voorganger closed is
            //we tekenen pijlen VAN deze node als we zelf closed zijn
            var state = statenode.data('sim');
            $.each(state.from, function (i, num) {
                //is hij er al
                var fromnode = simcy.getElementById('sn' + num);
                //fromnode is er, hij is gesloten, en er is nog geen edge tussen
                if (fromnode.nonempty() && fromnode.data('status') == 'closed' &&
                    simcy.edges('[source = "' + fromnode.id() + '"][target = "' + statenode.id() + '"]').empty()) {
                    simcy.add({
                        group: 'edges',
                        selectable: false,
                        data: {
                            weight: 50,
                            source: fromnode.id(),
                            target: statenode.id(),
                            id: getId('l')
                        },
                        classes: 'nextstate'
                    });
                }

            });
            //TO alleen als we zelf closed zijn
            if (statenode.data('status') == 'closed') {
                $.each(state.to, function (i, num) {
                    var tonode = simcy.getElementById('sn' + num);
                    //tonode bestaat en er is nog geen edge tussen
                    if (tonode.nonempty()
                        && simcy.edges('[source = "' + statenode.id() + '"][target = "' + tonode.id() + '"]').empty()) {
                        simcy.add({
                            group: 'edges',
                            selectable: false,
                            data: {
                                weight: 50,
                                source: statenode.id(),
                                target: tonode.id(),
                                id: getId('l')
                            },
                            classes: 'nextstate'
                        });
                    }
                });
            }
        }

        /**
         * Interface: selecteer alle statenodes
         */
        function selectAllStates(volger) {
            lockAndLog(_ => {
                simcy.$(':selected').unselect();
                simcy.nodes('[type= "state"]').select();
            }, volger);
            showResult();
        }

        pub.selectAllStates = selectAllStates;

        /**
         * Interface: deselecteer alles in statecanvas
         */
        function selectNone(volger) {
            lockAndLog(_ => {
                simcy.$(':selected').unselect();
            }, volger);
            showResult();
        }

        pub.selectNone = selectNone;

        /**
         * Selecteer alle eindnodes in de stategraph
         */
        function selectEndings(volger) {
            lockAndLog(_ => {
                simcy.$(':selected').unselect();
                simcy.nodes('[type = "state"]').leaves().select();
            });
        }

        pub.selectEndings = selectEndings;

        /**
         * Interface: Selecteer een pad door de gegeven states
         */
        function selectPad(volger) {
            //in deze versie vinden we alle paden tussen states
            //en zoeken de paden met alle states erin
            //breadfirst: zodra we een pad hebben gevonden met alle gezochte states, zijn we klaar

            let states = simcy.nodes(':selected').filter('[type = "state"]').map(state => state.id());
            if (states.length === 0 || states.length === simcy.nodes('[type = "state"]').length) {
                states = ['sn0']; //dan gewoon het kortste pad van input
            }
            log.debug(`selectPad`, states);
            simcy.$(':selected').unselect(); //selectie weg
            simcy.nodes().removeData('padcycle'); //padcycle-status wordt opnieuw gezet
            let agenda = [['sn0']]; //de agenda van paden op id
            let pad;
            let gevonden = null;
            while (pad = agenda.shift()) {
                let laatsteState = simcy.$id(pad.at(-1));
                let outElements = laatsteState.outElements('[type = "state"]');
                if (outElements.empty()) {
                    //dit pad eindigt hier
                    if (states.every(state => pad.includes(state))) {
                        gevonden = pad; //dit is een prima pad
                    }
                } else {
                    outElements.forEach(opvolger => {
                        let oid = opvolger.id();
                        //check cycle of einde
                        if (pad.includes(oid)) {
                            //cycle. Is dit pad ok?
                            if (states.every(state => pad.includes(state))) {
                                gevonden = pad; //dit is dus een pad
                                //we zetten de padcycle statusdata:
                                log.debug(`Zet padcycle voor`, laatsteState.id());
                                laatsteState.data('padcycle','voor'); //dit is de laatste voor de cycle
                                //en de opvolger is dan padcycle-element
                                log.debug(`Zet padcycle in`, opvolger.id());
                                opvolger.data("padcycle",'in'); //dit is de eerste in de cycle
                                return false;
                            }
                            return; //volgende
                        }
                        agenda.push([...pad, oid]); //straks met de volgende stap erbij
                    });
                }
                if (gevonden) {
                    break;
                }
            }

            if (gevonden) {
                log.debug(`Gevonden`, gevonden);
                for (let state of gevonden) {
                    simcy.$id(state).select();
                   // log.debug(`PAD`, state, simcy.$id(state).data("selectievolgorde"));
                }
                log.debug(`Gevonden states geselecteerd`);
            }
        }

        pub.selectPad = selectPad;

        /********************* samenwerken ******************************
         *
         * Bij samenwerken wordt de simulatie synchroon gehouden vanaf de leider
         * de cytocanvas.controller regelt deze synchronisatie op het niveau van acties
         * wij (als 'leid') sturen bij elke wijziging een broadcast van de huidige simstatus
         * dat is niet heel data-efficiënt, maar wel heel volledig
         * broadcast kan ook verzocht worden, bijvoorbeeld als er een nieuwe client is, of een nieuwe
         * client wordt leider
         */


        /**
         Stuur een volledig overzicht van de simulatiestatus, tbv samenwerking
         @param {boolean} [fit] Vraag om de simview opnieuw te fitten
         */
        function stuurSimulatiestatus(fit) {
            if (model.werkmodus !== 'leid') {
                return; //nvt
            }
            //we bouwen een compleet laadbare versie van de simulatie
            var res = {
                selectievolgorde: selectievolgorde
            }; //altijd de teller voor selectievolgorde meesturen
            if (fit) {
                res.fit = true;
            }
            if (!simulatie) {
                res.simuleren = false;
                //thats all
            } else {
                res.simuleren = true;
                //we sturen zelfs de hele simulatie mee: die kan apart aan het be worden gevraagd
                res.simulatie = simulatie;
                res.elements = simcy.json().elements; //veilige manier tegen cyclische json?
                res.history = pscope.history;
                res.valuehistories = {};
                res.resizeDirection = $scope.resizeDirection; //welke kant op tonen
                //valuehistories verzamelen
                //we hoeven eigenlijk alleen de showstate en de positie door te sturen
                //het wordt toch opnieuw gegenereerd (?)
                modelctrl.quantities().forEach(function (qNode) {
                    res.valuehistories[qNode.id()] = {
                        show: qNode.scratch("showvaluehistory"),
                        lefttop: qNode.scratch('historylefttop')
                    };
                });
                res.simfeedback = modelctrl.simfeedback();
            }
            //verstuur het bericht
            simulatiebericht('simstatus', {status: res});
            return res; //en teruggeven
        }

        //ook publiek
        pub.stuurSimulatiestatus = stuurSimulatiestatus;

        /**
         * intern: inplannen simulatiestatus, zodat het maar één keer gebeurt
         * @param {boolean} [direct] Niet plannen, maar uitvoeren
         * @param {boolean} [fit] Vraag om de simview opnieuw te fitten
         */
        function planStuursimulatiestatus(direct, fit) {
            if (model.werkmodus !== 'leid') {
                return; //nvt
            }
            if (simstatusPlanner) {
                $timeout.cancel(simstatusPlanner);
                simstatusPlanner = null;
            }
            if (direct) {
                stuurSimulatiestatus(fit);
            } else {
                simstatusPlanner = $timeout(() => {
                    stuurSimulatiestatus(fit);
                }, 1); //zometeen doen
            }
        }

        /**
         * Volgers: krijg een door de leider gegenereerde simstatus binnen en verwerk dat
         * @param status
         */
        function updateSimstatus(status) {
            //de canvascontrol heeft zijn schermen al geüpdated
            if (model.werkmodus !== 'volg') {
                return; //nvt
            }
            log.debug(`updateSimstatus`, status);
            simEventLock = true; //lock, bijv bij selecteren van states
            if (!status.simuleren) {
                //als we aan het simuleren waren, dan nu niet meer
                if (simulatie) {
                    reset();
                }
                simEventLock = false; //klaar
                return; //verder niets te doen
            }
            //update alles
            try {
                var nieuwesimulatie = (!simulatie);
                simulatie = status.simulatie;
                selectievolgorde = status.selectievolgorde;
                simcy.json({elements: status.elements}); //inclusief specials zoals viir en in padcycle

                //value history
                pscope.history = status.history;

                //valuehistory
                angular.forEach(status.valuehistories,
                    function (his, qNodeId) {
                        var qNode = modelctrl.getElement(qNodeId);
                        if (!qNode) {
                            log.warn("updateSimstatus: qNode voor valuehistory niet gevonden", qNodeId);
                            return; //continue
                        }
                        qNode.scratch("showvaluehistory", his.show);
                        qNode.scratch("historylefttop", his.lefttop);
                    });

                $scope.internSetSimdirection(status.resizeDirection);

                if (nieuwesimulatie || status.fit) {
                    //even centreren en fitten
                    initZoomEnFit();
                }

                $timeout(function () {
                    showResult();
                }); //en bijwerken, maar dat doen we async
            } catch (_e) {
                log.error("Fout bij updateSimstatus");
                log.error(_e);
            }

            simEventLock = false; //klaar

        }

        pub.updateSimstatus = updateSimstatus;

        /**
         * Stuur - als volger - een simulatiebericht met de huidige selectie van states
         */
        function stuurSelected() {
            if (model.werkmodus !== 'volg') {
                return; //nvt
            }

            simulatiebericht('setSelected', {
                selectievolgorde: selectievolgorde,
                selected: simcy.$(':selected').map(function (el) {
                    return {
                        id: el.id(),
                        selectievolgorde: el.data('selectievolgorde')
                    };
                })
            });
        }

        /**
         * Call van cytocanvas bij setSelected simulatiebericht van volger
         * @param selectedNodes bevatten id en selectievolgorde
         * @param nieuwSelectievolgorde de binnenkomende selectievolgorde-teller
         * @param volger
         */
        function setSelected(selectedNodes, nieuwSelectievolgorde, volger) {
            //we doen het helemaal opnieuw
            selectievolgorde = nieuwSelectievolgorde; //aangepast
            var prevSelected = {};
            simcy.$(':selected').forEach(function (el) {
                prevSelected[el.id()] = el;
            });

            lockAndLog(_ => {
                simcy.$(':selected').unselect();

                selectedNodes.forEach(function (selectInfo) {
                    simcy.$id(selectInfo.id).select().data("selectievolgorde", selectInfo.selectievolgorde);
                });
            }, volger);
            $timeout(function () {
                showResult();
            }); //en bijwerken, maar dat doen we async
        }

        pub.setSelected = setSelected;

        /**
         * Helper. Log de huidige selectiestatus in de sim. Wordt aangeroepen na select (lokaal) of bij bericht van volger
         * @param [volger] de id van de volger
         */
        function logSelected(volger) {
            if (model.werkmodus === 'volg') {
                //niets loggen
                return;
            }
            log.debug(`logSelected(${volger})`);
            //we loggen alles wat selected is
            let selected = simcy.$(':selected');
            //alleen als het er 1 is doen we de details
            if (selected.length === 1) {
                model.directlogAction("sim_select", selected[0].data(), 'sim', 'sim', false, volger); //direct loggen, anders wordt het pas opgeslagen als de sim weer dicht is, en vaak overschreven.
            } else {
                //alleen de id'
                model.directlogAction("sim_multiselect", selected.map(el => el.id()), 'sim', 'sim', false, volger);
            }
        }

        /**
         * Lock lokale eventafhandeling (via inUpdatesimstatus) en log daarna de selectie, als we leider zijn
         * @param fn
         * @param volger
         */
        function lockAndLog(fn, volger) {
            let prevLock = simEventLock;
            simEventLock = true;
            fn();
            simEventLock = prevLock;
            logSelected(volger);
        }

        /**
         * Call van cytocanvas bij positie-simulatiebericht. Wij zetten het ook, en sturen het door als we leid zijn
         * @param posities
         */
        function setPositie(posities) {
            if (model.werkmodus === 'leid') {
                simulatiebericht('positie', {posities: posities});
            }

            angular.forEach(posities, function (pos, node) {
                node = simcy.$id(node);
                if (node) {
                    node.position(pos);
                }
            });
        }

        pub.setPositie = setPositie;


        //////////////////////// simfeedback vanuit de modelcontroller
        /**
         * Een volger stuurt een actie mbt simfeedback naar de leider
         * @param actie
         * @param arg
         */
        function simfeedbackActie(actie, arg) {
            if (model.werkmodus === 'volg') {
                simulatiebericht('simfeedbackactie', {feedbackactie: actie, feedbackarg: arg});
            }
        }

        pub.simfeedbackActie = simfeedbackActie;

        /**
         * Afhandelen binnenkomende simfeedbackactie simulatiebericht
         * @param actie
         * @param arg
         * @param {string} [volger] clientid van de volger die dit veroorzaakt
         */
        pub.onSimfeedbackactie = function (actie, arg, volger) {
            if (model.werkmodus !== 'leid') {
                return;
            }
            switch (actie) {
                case 'toggle':
                    //laat de leider de feedback tonen / hiden
                    modelctrl.showSimfeedback(arg, volger);
                    break;
                case 'highlight':
                    modelctrl.highlight_feedback(arg, volger);
                    break;
            }
        };

        /**
         * Een leider vraagt om de simfeedback bij volgers te syncen
         */
        function syncSimfeedback() {
            if (model.werkmodus === 'leid') {
                simulatiebericht('simfeedback', {status: modelctrl.simfeedback()});
            }
        }

        pub.syncSimfeedback = syncSimfeedback;

        /***************** Resultaten ****************************/
        /**
         * Verwijder de resultaten van een bepaalde simulatiestap in het model
         */
        function clearResult() {
            //opslaan pos van elementen met een savedposkey

            if (simulatie) {
                if (!simulatie.savedPos) {
                    simulatie.savedPos = {};
                }

                modelctrl.simulationContent('[savedposkey]').forEach(function (n) {
                    simulatie.savedPos[n.data("savedposkey")] = clone(n.position());

                });
            }
            modelctrl.removeSimulation();
            modelctrl.setInSimulate(false); //weer tonen
        }

        /**
         * Toon het resultaat van de huidige geselecteerde simulatieelementen (states, transities) in het model
         *  Waarden tonen we alleen bij selectie = 1, maar valuehistories juist bij meer
         *  @param {boolean} [forceRegenerate] als gegeven dan worden niet de oude nodes van de state opnieuw gebruikt
         */
        function showResult(forceRegenerate) {
            //het kan voorkomen dat dit dubbel gebeurt (bijv bij stateselect én daarna updatesimulatie)
            //dat nemen we voor lief. Checken of er wel een wijziging is werkt niet, want bij bijv
            //een andere display van de histories moet het opnieuw, ondanks dezelfde stateselectie

            log.debug(`showResult`, forceRegenerate);

            var geselecteerd = simcy.nodes(':selected');
            clearResult();

            //als niets geselecteerd, dan de begintoestand, wordt geregeld door simulateClearResult
            if (geselecteerd.length === 0) {
                return;
            }

            //hoe dan ook, nu de te verbergen modelzaken verbergen
            modelctrl.setInSimulate(true);

            //histories?
            if (simcy.nodes('node[type = "state"][id != "sn0"]:selected').length > 1) {
                modelctrl.quantities().forEach(function (q) {
                    if (q.scratch('showvaluehistory')) {
                        showHistory(q, forceRegenerate); //toon history opnieuw voor deze quantity
                    }
                });
                //en welke ineqhistories zijn er?
                if (simulatie.ineqHistory) {
                    Object.keys(simulatie.ineqHistory).forEach(function (ineqkey) {
                        if (simulatie.ineqHistory[ineqkey].visible) {
                            showIneqHistory(ineqkey, forceRegenerate);
                        }
                    });
                }
            }

            //waarden e.d. tonen we alleen bij 1 selectie:
            if (geselecteerd.length != 1) {
                return;
            }

            if (!geselecteerd.data("sim")) {
                //beginstate, toon de modelelementen en klaar
                modelctrl.setInSimulate(false);
                return;
            }

            //hebben we hem al?
            var simnodes = geselecteerd.scratch("savednodes");
            if ((!forceRegenerate) && simnodes) {
                modelctrl.addSimnodes(simnodes);
                //reset de positie van elementen die bij een modelnode horen, en plaats de andere op een eventuele nieuwe plek
                modelctrl.simulationContent().forEach(function (node) {
                    var kp = node.data('keep_position'); //bevat from: de node in het model en x en y als afwijking van de pos daarvan
                    if (kp) {
                        var modelpos = kp.from.position();

                        node.scratch('prevPos', false); //voorkom automove van de rest
                        node.position(
                            {
                                x: modelpos.x + kp.x,
                                y: modelpos.y + kp.y
                            }
                        );
                    } else {
                        checkSavedPos(node); //kijk of er een opgeslagen positie is
                    }

                });

                return;
            }

            //en tonen: stateinhoud en mogelijk terminatieinhoud

            if (geselecteerd.data("type") == "transitie") {
                //dan dus eerste de stateinhoud
                generateResultnodes(geselecteerd.data("statesim"), "state");
                //en dan de transitie zelf
                generateResultnodes(geselecteerd.data("sim"), geselecteerd.data('transitietype'));
            } else {
                //gewoon een state
                generateResultnodes(geselecteerd.data("sim"), "state");
            }

            //opslaan in de scratch van de node
            geselecteerd.scratch("savednodes", modelctrl.simulationContent('.simcache'));
        }


        /**
         * Check of de node een key heeft voor een savedpositie. Aan de hand van die key zetten we hem dan goed
         * Hierdoor kunnen verschillende nodes als dezelfde identiteit worden weergegeven. Bijv verschillende ineqs zijn 'de ineq tussen A en B'
         * @param node
         * @returns boolean true als we de positie hadden en hebben toegepast
         */
        function checkSavedPos(node) {
            var key = node.data('savedposkey');
            if (key && simulatie.savedPos[key]) {
                node.position(clone(simulatie.savedPos[key]));
                return true;
            } else {
                return false;
            }
        }

        /**
         * Genereer de nodes voor de opgegeven simdata
         * @param data simulationdata uit de state of termination
         * @param simtype op te slaan attribute bij de nodes, is 'state', 'terminatie', 'orderning'
         */
        function generateResultnodes(data, simtype) {
            log.debug(`Generate`, data);
            /////values
            $.each(data.values, function (q, val) {

                var qsItem = null;
                if (val == 'no_qs_default_interval') {
                    //by convention: dit is een waarde op een door ons niet weergegeven quantityspace
                    //die krijgt in de simulatie standaard een intervalwaarde
                    //skip:
                    return;
                }
                if (val == 'zero') {
                    qsItem = modelctrl.relatedZeroNode(q);
                    if (!qsItem) {
                        return; //skip
                    }
                } else {
                    //gewoon een nodeid
                    qsItem = modelctrl.getElement(val);
                    if (qsItem.empty()) {
                        return; //skip
                    }
                }
                //toevoegen als simnode
                var n = modelctrl.addSimnodes(
                    {
                        classes: "simulation quantity_value simcache",
                        data: {
                            type: 'quantity_value',
                            simtype: simtype,
                            id: qsItem.id() + '_simval',
                            automovegroup: qsItem.data('automovegroup'), //alle elementen van deze qs bewegen samen
                            //speciaal in simulatie info opslaan over de positie die bewaard moet worden
                            keep_position: {
                                from: qsItem,
                                x: -30,
                                y: 0
                            }
                        },
                        position: {
                            x: qsItem.position('x') - 30,
                            y: qsItem.position('y')
                        }
                    });
            });
            $.each(data.dvalues, function (q, val) {
                var qNode = modelctrl.getElement(q);
                if (qNode.empty()) {
                    log.error("Kon quantity voor dvalue niet vinden", q, val);
                    return; //skip
                }
                var dqs = modelctrl.derivativeQs(qNode);
                var dqe = null;
                switch (val) {
                    case 'plus':
                        dqe = dqs['derivative_plus'];
                        break;
                    case 'zero':
                        dqe = dqs['derivative_zero'];
                        break;
                    case 'min':
                        dqe = dqs['derivative_min'];
                        break;
                }
                if (!dqe) {
                    log.error("Kon afgeleide`waarde voor dvalue niet vinden", q, val);
                    return; //skip
                }
                //is er al een waarde - dan vinden we die nu exogeen (wordt niet verborgen)

                //exogene d-value kan best wijzigen, namelijk zero worden als stijgen niet meer kan
                //dus beelde we ze gewoon af
                if (true || dqe.outElements('[type = "derivative_value"]').empty()) {
                    //is er niet, dus afbeelden
                    var n = modelctrl.addSimnodes(
                        {
                            classes: "simulation derivative_value simcache",
                            data: {
                                type: 'derivative_value',
                                id: dqe.id() + '_simdval',
                                simtype: simtype,
                                automovegroup: dqe.data('automovegroup'), //alle elementen van deze qs bewegen samen
                                //speciaal in simulatie info opslaan over de positie die bewaard moet worden
                                keep_position: {
                                    from: dqe,
                                    x: -30,
                                    y: 0
                                }
                            },
                            position: {
                                x: dqe.position('x') - 30,
                                y: dqe.position('y')
                            }
                        });
                }
            });

            //quantity ineq
            $.each(data.q_ineq, function (i, ineq) {
                //parse de argumenten
                //Bert Bredeweg 7-2-2016: skip inequalities met calcs als argument
                //die laten we staan uit het model
                if (data.q_calcs[ineq.from] || data.q_calcs[ineq.to]) {
                    return; //volgende
                }
                var from = getIneqArg('q', ineq.from, ineq.to, data, simtype);
                var to = getIneqArg('q', ineq.to, ineq.from, data, simtype);
                //als 1 van de 2 false is, kan de relatie niet gemaakt worden
                //bijvoorbeeld bij een ongelijkheid van zero naar zero
                //die skippen we
                if (!(from && to)) {
                    log.error('q_ineq te weinig info', ineq.from, ineq.to);
                    return; //volgende
                }
                //als we hierboven druk bezig zijn geweest is .from of .to al een object
                addSimRelation(from, to, ineq.type, 'ineq', simtype);
            });

            //derivative ineq
            $.each(data.d_ineq, function (i, ineq) {
                //parse de argumenten
                //Bert Bredeweg 7-2-2016: skip inequalities met calcs als argument
                //die laten we staan uit het model
                if (data.d_calcs[ineq.from] || data.d_calcs[ineq.to]) {
                    return; //volgende
                }
                var from = getIneqArg('d', ineq.from, ineq.to, data, simtype);
                var to = getIneqArg('d', ineq.to, ineq.from, data, simtype);
                //als 1 van de 2 false is, kan de relatie niet gemaakt worden
                //bijvoorbeeld bij een ongelijkheid van zero naar zero
                //die skippen we
                if (!(from && to)) {
                    log.error('d_ineq te weinig info', ineq.from, ineq.to);
                    return; //volgende
                }
                //als we hierboven druk bezig zijn geweest is .from of .to al een object
                addSimRelation(from, to, ineq.type, 'd_ineq', simtype);
            });
        }

        /**
         *  Vind de juiste node voor een quantity inequality in de simulator
         * @param {string} qOrD, gaat het over quantities of derivatives?
         * @param arg
         * @param otherarg
         * @param state
         * @param simtype
         * @returns {cy.eles | boolean} een node of false
         */
        function getIneqArg(qOrD, arg, otherarg, state, simtype) {
            //als het een calc is kijken we of die calc er al is, of anders maken we hem ad hoc
            var res;
            //sanitize
            if (qOrD != 'q') {
                qOrD = 'd';
            }
            if (state[qOrD + '_calcs'][arg]) {
                res = getCalc(state, qOrD, arg, simtype);
            }
            //bij zero moeten we een zero vinden in de qs van het andere argument
            else if (arg == 'zero') {
                res = zeroNodeForArgument(qOrD, state, otherarg);
            }
            //anders is dit een node, quantity of point in ineq
            else {
                var node = typeof (arg) == "object" ? arg : modelctrl.getElement(arg);
                if (qOrD == 'q') {
                    res = node;
                } else {
                    //node is gegarandeerd een quantity, we zoeken de derivative eronder
                    res = node.outElements('[type = "derivative"]');
                }
            }
            return (res && res.nonempty()) ? res : false;
        }

        /**
         * Vind of maak een node die de gegeven calc representeert
         * @param state
         * @param {string} argtype q of d
         * @param calcspec
         * @param simtype
         * @returns {cy.el|boolean} de node of false als het niet lukt
         */
        function getCalc(state, argtype, calcspec, simtype) {
            //Volgens Bert komt dit niet voor. Daarom geven we false terug
            log.warn('getCalc aangeroepen voor een simulatiecalculus - die zou niet bestaan');
            return false;

        }

        /**
         * Helper bij tekenen inequalities. Geeft een handige zeronode terug gegeven een niet-zero arg van een ineq (d of q)
         * @param {string} qOrD: 'q' of 'd'
         * @param {object} state het dataobject met info over de simulatiestate
         * @param ineqArg
         * @returns {cy.eles|boolean} als het gevonden kan worden, false als niet
         */
        function zeroNodeForArgument(qOrD, state, ineqArg) {
            if (ineqArg == 'zero') {
                //we kunnen geen node vinden als dit arg zelf ook zero is
                log.warn("zeroNodeForArgument bij dubbele zero", qOrD, state, ineqArg);
                return false;
            }
            //is het een calc?
            var calcs = state[qOrD + '_calcs'];
            if (calcs[ineqArg]) {
                //zoek in de from of de to, bij een willen we een zero vinden
                return zeroNodeForArgument(qOrD, state, calcs[ineqArg].from) ||
                    zeroNodeForArgument(state, calcs[ineqArg].to);
            }
            //dan is het een node, we zoeken omhoog naar de quantity en dan in zijn qs naar zero
            var qnode = modelctrl.getQuantity(ineqArg);
            if (!qnode) {
                return false; //niet gevonden
            }
            var zero;
            if (qOrD == 'q') {
                zero = modelctrl.relatedZeroNode(qnode);
                //als niet, eens kijken of er een andere is
                /* if (!zero) {
                     zero = modelctrl.getS
                 }*/
            } else {
                zero = modelctrl.derivativeQs(qnode)['derivative_zero'];
            }
            return zero ? zero : false;
        }

        /**
         * Helper bij maken simulation state. We maken een relatie a la addRelation, maar dan voor sim
         * @param startNode
         * @param endNode
         * @param relationType (ineq, calc)
         * @param saveposkey key voor opslaan van de positie over states
         * @param simtype type simnode waarvoor we tekenen (state, terminatie, ordering)
         * @returns {cy.el} de nieuwe node
         */
        function addSimRelation(startNode, endNode, relationType, savedposkey, simtype) {
            let startNodepos,
                endNodepos;

            //bepaal eerst de savedposkey, dan weten we of er al een positie is opgeslagen voor deze relatie
            //De savedposkey zorgt dat relaties van hetzelfde soort (maar andere waarde) op dezelfde plek komen over states heen
            //dus een > en later een < tussen twee Q's is 'dezelfde'
            //we hebben de savedposkey, we zetten daar de savedposkey of de id van de argumenten achter
            let keyarg1 = startNode.data("savedposkey") || startNode.id();
            let keyarg2 = endNode.data("savedposkey") || endNode.id();

            savedposkey += '_';
            if (keyarg1 < keyarg2) //sorteren
            {
                savedposkey += keyarg1 + "_" + keyarg2;
            } else {
                savedposkey += keyarg2 + "_" + keyarg1;
            }

            //bepaal de plaatsingspositie
            let pos = null;
            if (simulatie.savedPos[savedposkey]) {
                pos = clone(simulatie.savedPos[savedposkey]);
            } else {
                //is er een inequality tussen dezelfde argumenten in het model?
                let modelineq = modelctrl.getIneqBetween(startNode, endNode);
                if (modelineq) {
                    pos = clone(modelineq.position());
                }
            }
            //anders maar gewoon een plek verzinnen:
            if (!pos) {
                pos = {};
                startNodepos = startNode.position();
                endNodepos = endNode.position();

                //altijd er precies tussenin en iets lager dan het midden

                pos.y = startNodepos.y + (endNodepos.y - startNodepos.y) / 2;
                if (Math.abs(endNodepos.x - startNodepos.x) < 10) {
                    //zelfde positie,
                    pos.x = endNodepos.x - 100;
                } else {
                    pos.x = startNodepos.x + (endNodepos.x - startNodepos.x) / 2; //ertussen
                    pos.y += 100; //iets naar onderen
                }

                //kijk of de nodes die er al zijn dit raken
                let poging;
                for (poging = 0; poging < 10; poging++) {
                    if (modelctrl.overlap(pos, 50)) {
                        pos.y += 20; //iets lager dan?
                    } else {
                        break; //gevonden
                    }
                }
            }

            var n = modelctrl.addSimnodes(
                {
                    classes: "simcache simulation " + relationType,
                    data: {
                        type: relationType,
                        id: `${savedposkey}_${relationType}`, //type toegevoegd om uniek te maken als er meerdere ineq zijn
                        savedposkey: savedposkey,
                        //en de from en to zoals bij gewone ineqs
                        from: startNode.id(),
                        to: endNode.id(),
                        simtype: simtype
                    },
                    position: pos
                });
            modelctrl.addEdgeFromTo(startNode, n, "source", "simulation simcache").data('simtype', simtype);
            modelctrl.addEdgeFromTo(endNode, n, "target", "simulation simcache").data('simtype', simtype);
            checkSavedPos(n); //weten we al waar hij moet?
            return n;
        }

        /****************** VALUE HISTORY ***********************/

        /**
         * Interfacefunctie, aangeroepen vanuit scope-functie in maincontroller (prullenbak). Hide de value history van een quantitynode in het model, gegeven de stateselectie
         * @param historyNode
         */
        function hideValueHistory(historyNode) {
            if (model.werkmodus === 'volg') {
                //dan moet de master het doen
                simulatiebericht('hideValueHistory', {
                    historyNode: historyNode.id() //moet bij de andere hetzelfde zijn
                });
                return;
            }
            var qid = historyNode.data('historyquantity');
            var qNode = modelctrl.getElement(qid);
            modelctrl.removeSimulation('[historyquantity="' + qid + '"]'); //weg ermee
            qNode.scratch("showvaluehistory", false);
            //was dit de laatste? Dan staat valuehistorytoggle weer uit
            valuehistory = (modelctrl.simulationContent('[historyquantity]').nonempty());
            pscope.history.value = valuehistory;

            //dit gebeurt vanuit de interface, dus plannen we ook een statusupdate
            planStuursimulatiestatus(); //bijwerken
        }

        //public versie voor aanroep vanuit volger via simulatiebericht
        pub.volgerHideValueHistory = function (historyNodeId) {
            var historyNode = modelctrl.getElement(historyNodeId);
            if (!historyNode) {
                log.warn('volgerHideValueHistory voor onbekende historynode', historyNodeId);
            }
            hideValueHistory(historyNode);
        };

        /**
         * Public: alle value histories aan of uit
         */
        function toggleAllValueHistory() {
            valuehistory = !valuehistory;
            pscope.history.value = valuehistory;
            if (valuehistory) {
                showAllValueHistory();
            } else {
                hideAllValueHistory();
            }
            planStuursimulatiestatus(); //bijwerken (misschien dubbel, maar dat werkt de planner wel uit)
        }

        pub.toggleAllValueHistory = toggleAllValueHistory;

        /**
         * Toon alle value histories
         */
        function showAllValueHistory() {
            modelctrl.quantities().forEach(function (qNode) {
                showHistory(qNode);
                qNode.scratch("showvaluehistory", true);
            });
        }

        /**
         * Verberg alle valuehistories
         */
        function hideAllValueHistory() {
            modelctrl.removeSimulation('[historyquantity]'); //weg met alle quantityhistories
            modelctrl.quantities().forEach(function (qNode) {
                qNode.scratch("showvaluehistory", false);
            });
        }

        /**
         * Toon de valuehistory voor een bepaalde quantity. Wordt gebruikt door leid, volg en lokaal
         * @param qNode
         * @param {boolean} [forceReposition] herplaats hem
         */
        function showHistory(qNode, forceReposition) {
            //settings:
            var s = history_settings; //even sneller
            var hogereOrdeRuimte; //de extra ruimte voor de hogere orde afgeleiden, wordt hieronder bepaald
            var data = {};
            var x, y; //helpers
            var i;
            var qid = qNode.id();
            var amg = 'history' + qid; //automovegroupid

            /* data en berekeningen */
            //alle geselecteerde states, gesorteerd op selectievolgorde:
            data.states = simcy.nodes('node[type = "state"][id != "sn0"]:selected').sort(function (a, b) {
                return a.data('selectievolgorde') - b.data('selectievolgorde');
            }).toArray();
            let cyclenode = simcy.nodes('[padcycle = "in"]');
            if (cyclenode.nonempty())
            {
                data.states.push(cyclenode);
            }
            log.debug(`showHistory voor ${qNode.data('label')}`, data.states.map(st => `${st.id()}(${st.data('selectievolgorde')})`).join('-'));

            data.numstates = data.states.length;
            if (data.numstates < 2) {
                return;
            }

            //bepaal de kolombreedte:
            var numhogereorde = 0; //1 hogere orde = 2e afg 2 hogere orde = 3e afgeleide
            for (i = 0; i < data.states.length; i++) {
                //3e afgeleide wordt zichtbaar als hij ergens een andere waarde heeft dan nil, nil
                if (data.states[i].data('sim').d3values[qid] && data.states[i].data('sim').d3values[qid] != 'nil') {
                    //3e afgeleide, we zijn klaar:
                    numhogereorde = 2;
                    break; //want hoger wordt het niet
                } else if (data.states[i].data('sim').d2values[qid]) {
                    numhogereorde = 1; //kan nog hoger worden
                }
            }
            hogereOrdeRuimte = numhogereorde * s.hogereOrdeKolom; //totaal

            //we pushen de qs-waarden op volgorde van boven naar beneden
            data.qs = [];
            data.numpoints = 0;
            data.numints = 0;
            var qsNode = modelctrl.getQSNode(qNode);
            if (qsNode) {
                //er is een qs: die gebruiken we dus
                var qsEl = qsNode; //parent
                while ((qsEl = modelctrl.nextQsItem(qsEl))) {
                    //tel gelijk punten en ints
                    if (qsEl.data('type') == 'quantity_space_point') {
                        data.numpoints++;
                    } else {
                        data.numints++;
                    }
                    data.qs.push(clone(qsEl.data())); //kopie
                }
            } else {
                data.numints = 1;
                data.qs = [{
                    type: 'quantity_space_interval',
                    id: 'no_qs_default_interval', //let op: dit is volgens conventie: dat geeft de backendsimulator terug
                    label: ''
                }];
            }

            data.numvalues = data.qs.length;

            data.infosize = {  //het infovlak
                w: data.numstates * (s.kolom + hogereOrdeRuimte),
                h: data.numints * s.interval + data.numpoints * s.point
            };
            data.boxsize = { //de hele box
                w: data.infosize.w,
                h: data.infosize.h + s.staterow
            };

            //om de positie op te slaan werken we niet met savedpos, vanwege de variabele grootte (pos = center)
            //als die er niet is, dan de default
            var oldpos = qNode.scratch('historylefttop');
            if (forceReposition || (!oldpos))
                oldpos = {
                    left: qNode.position('x') + s.afstand_x,
                    top: qNode.position('y')
                };
            //basis voor de boxinfo
            data.box = {
                left: oldpos.left,
                top: oldpos.top,
                right: oldpos.left + data.boxsize.w,
                bottom: oldpos.top + data.boxsize.h
            };
            //we noemen het container, maar het is géén compound, want die verdwijnt achter andere nodes.
            //we zetten hem toevallig gewoon op dezelfde plek als de rest
            var historynode = modelctrl.addSimnodes({
                    group: 'nodes',
                    classes: 'simulation simhistory_container',

                    position: {
                        x: data.box.left + data.boxsize.w / 2,
                        y: data.box.top + data.boxsize.h / 2
                    }, //default
                    data: {
                        type: 'simhistory_container',
                        id: 'histcontainer_' + qid, //fixed id, zodat leiders en volgers dezelfde houden
                        historyquantity: qid,
                        automovegroup: amg,
                        //want die zetten we hier, en wordt dan overgenomen
                        height: data.boxsize.h + 'px',
                        width: data.boxsize.w + 'px'
                    }
                },
                hideValueHistory);
            //bewaar steeds de topleft
            historynode.on('position', function () {
                qNode.scratch('historylefttop',
                    {
                        left: historynode.position('x') - data.boxsize.w / 2,
                        top: historynode.position('y') - data.boxsize.h / 2
                    });
            });

            //we bubbelen het tapevent van alle subnodes, zodat de prullenbak toont
            var tapContainer = function () {
                historynode.trigger('tap');
            };

            modelctrl.addEdgeFromTo(qNode, historynode, "normal", "simulation");
            //een grijs vlak als achtergrond

            modelctrl.addSimnodes({
                group: 'nodes',
                classes: 'simulation simhistory simhistory_background',
                position: {
                    x: data.box.left + data.boxsize.w / 2, //in het midden van de box
                    y: data.box.top + data.infosize.h / 2 //niet helemaal in het midden, vanwege de staterow
                },

                data: {
                    id: historynode.id() + '_bg',
                    historyquantity: qid,
                    automovegroup: amg,
                    //formaat via data - wordt opgepikt in de cytocss
                    height: data.infosize.h + 'px',
                    width: data.infosize.w + 'px'
                }
            }).on('tap', tapContainer);

            //de labels rechts erachter
            x = data.box.right + s.labelSpace;
            y = data.box.top; //dat is helemaal boven

            data.valueY = {};
            //fixed id's
            i = 1;
            data.qs.forEach(function (qsEl) {
                var yruimte = qsEl.type == "quantity_space_point" ? s.point : s.interval; //zoveel ruimte neemt deze rij
                data.valueY[qsEl.id] = y + yruimte / 2; //midden in de beschikbare ruimte. Bewaren voor de values zo
                if (qsEl.isZero) {
                    data.valueY.zero = data.valueY[qsEl.id]; //dat is dan de waardenaam in values
                }
                modelctrl.addSimnodes({
                    group: 'nodes',
                    classes: 'simulation simhistory simhistory_valuelabel', //geen simcache
                    position: {
                        x: x,
                        y: data.valueY[qsEl.id]
                    },
                    data: {
                        label: qsEl.isZero ? 'Ø' : qsEl.label,
                        id: historynode.id() + '_' + i,
                        isZero: qsEl.isZero,
                        historyquantity: qid,
                        automovegroup: amg
                    }
                }).on('tap', tapContainer);
                if (qsEl.type == "quantity_space_point") {
                    //maak een achtergronds node
                    modelctrl.addSimnodes({
                        group: 'nodes',
                        classes: 'simulation simhistory simhistory_pointblock',
                        position: {
                            x: data.box.left + data.boxsize.w / 2,
                            y: y + s.point / 2
                        },
                        data: {
                            id: historynode.id() + '_' + i + '_bg',
                            historyquantity: qid,
                            automovegroup: amg,
                            width: data.boxsize.w + 'px' //hoogte is in de style
                        }
                    }).on('tap', tapContainer);
                }
                y += yruimte;
                i++;
            });

            //statenummers en values
            data.states.forEach(function (sn, nr) {
                var sim = sn.data('sim'); //de statedata
                var x = data.box.left + nr * (s.kolom + hogereOrdeRuimte) + 0.5 * s.kolom; //midden in deel voor de echte valuemarker
                modelctrl.addSimnodes({
                    group: 'nodes',
                    classes: 'simulation simhistory simhistory_statenumber', //geen simcache
                    position: {
                        x: x,
                        y: data.box.bottom - s.staterow / 2 //en midden in de staterow
                    },
                    data: {
                        automovegroup: amg,
                        id: historynode.id() + '_statenr_' + nr,
                        historyquantity: qid,
                        num: sim.num,
                        //hoogte en breedte via de data in de stylesheet
                        //pakken volledige ruimte, css centreert het label
                        height: s.staterow + 'px',
                        width: s.kolom + 'px'
                        //parent: container
                    }
                }).on('tap', tapContainer);
                //en de values
                if (sim.values[qid]) {
                    modelctrl.addSimnodes({
                        group: 'nodes',
                        classes: 'simulation simhistory simhistory_valuemarker simhistory_valuemarker_'
                            + (sim.dvalues[qid] || 'unknown'),
                        position: {
                            x: x, y: data.valueY[sim.values[qid]]
                        },
                        data: {
                            type: 'simhistory_valuemarker',
                            id: historynode.id() + '_statemarker_' + nr,
                            automovegroup: amg,
                            historyquantity: qid,
                            dir: sim.dvalues[qid] || '?' //tmp
                        }
                    }).on('tap', tapContainer);
                    //2e afgeleide, skip unknown en nil
                    if (numhogereorde > 0 && sim.d2values[qid] && sim.d2values[qid] != "unknown" && sim.d2values[qid] != "nil") {
                        modelctrl.addSimnodes({
                            group: 'nodes',
                            classes: 'simulation simhistory simhistory_dvaluemarker simhistory_dvaluemarker_'
                                + (sim.d2values[qid]),
                            position: {
                                x: x + s.mainvalue / 2 + s.hogereOrdeKolom / 2, //meteen ertegenaan, de extra ruimte (s.kolom - s.mainvalue) komt dus rechts van alles
                                y: data.valueY[sim.values[qid]]
                            },
                            data: {
                                type: 'simhistory_d2valuemarker',
                                id: historynode.id() + '_statemarker_2e_' + nr,
                                automovegroup: amg,
                                historyquantity: qid,
                                dir: sim.d2values[qid] || '?' //tmp
                            }
                        }).on('tap', tapContainer);
                    }
                    //3e, skip unknown en nil
                    if (numhogereorde > 1 && sim.d3values[qid] && sim.d3values[qid] != "unknown" && sim.d3values[qid] != "nil") {
                        modelctrl.addSimnodes({
                            group: 'nodes',
                            classes: 'simulation simhistory simhistory_dvaluemarker simhistory_dvaluemarker_'
                                + (sim.d3values[qid]),
                            position: {
                                x: x + s.mainvalue / 2 + 1.5 * s.hogereOrdeKolom,
                                y: data.valueY[sim.values[qid]]
                            },
                            data: {
                                type: 'simhistory_d2valuemarker',
                                id: historynode.id() + '_statemarker_3e_' + nr,
                                automovegroup: amg,
                                historyquantity: qid,
                                dir: sim.d3values[qid] || '?' //tmp
                            }
                        }).on('tap', tapContainer);
                    }
                }
            });
        }

        /********************** INEQ HISTORY **************/

        /**
         * Public: alle ineq histories aan of uit
         */
        function toggleAllIneqHistory() {
            ineqhistory = !ineqhistory;
            pscope.history.ineq = ineqhistory;
            if (ineqhistory) {
                showAllIneqHistory();
            } else {
                hideAllIneqHistory();
            }
            planStuursimulatiestatus(); //bijwerken
        }

        pub.toggleAllIneqHistory = toggleAllIneqHistory;

        /**
         * Toon alle ineq histories
         */
        function showAllIneqHistory() {
            /**
             * Probleem: van welke ineqs gaan we histories afbeelden?
             * Uitgangspunt: alle ineqs in het scenario. Maar kan wijzigen in alle ineqs in de simulaties
             */

            //initialiseer de map op ineq-keys
            if (!simulatie.ineqHistory) {
                simulatie.ineqHistory = {} //key = ineq-args, value = object met data
            }
            modelctrl.modelIneqs().forEach(function (ineq) {
                var fromEl = modelctrl.getElement(ineq.data('from'));
                var toEl = modelctrl.getElement(ineq.data('to'));
                //geen calculus
                if (elementService.isSubtypeOf(fromEl.data('type'), 'calc') || elementService.isSubtypeOf(toEl.data('type'), 'calc')) {
                    return;
                }
                var ineqkey = ineq.data('from') + '-' + ineq.data('to');
                if (!simulatie.ineqHistory[ineqkey]) {
                    simulatie.ineqHistory[ineqkey] = {
                        visible: true, //zometeen zichtbaar
                        //en de defaults:
                        ineq: ineq.id(), //id bewaren voor plaatsen positie
                        derivative: elementService.isSubtypeOf(ineq.data('type'), 'd_ineq'), //is dit een afgeleide
                        from: ineq.data('from'),
                        to: ineq.data('to'),
                        perState: {} //type per state, wordt opgeslagen als we hem vinden
                    };
                }
                simulatie.ineqHistory[ineqkey].visible = true; //ook als hij al bestond
                //en toon hem
                showIneqHistory(ineqkey);
            });
        }

        /**
         * Verberg alle ineqhistories
         */
        function hideAllIneqHistory() {
            if (simulatie.ineqHistory) {
                Object.keys(simulatie.ineqHistory).forEach(function (ineqkey) {
                    hideIneqHistory(ineqkey, true); //intern, geen update of messaging
                });
            }
        }

        function showIneqHistory(ineqkey, forceReposition) {
            var s = history_settings; //sneller
            var saved = simulatie.ineqHistory[ineqkey];
            var data = {};
            var amg = 'ineqhistory_' + ineqkey; //automovegroupid

            /* data en berekeningen */
            //alle geselecteerde states, gesorteerd op selectievolgorde:
            data.states = simcy.nodes('node[type = "state"][id != "sn0"]:selected').sort(function (a, b) {
                return a.data('selectievolgorde') - b.data('selectievolgorde');
            });
            data.numstates = data.states.length;
            if (data.numstates < 2) {
                return;
            }
            //reken uit
            data.infosize = {  //het infovlak
                w: data.numstates * s.kolom,
                h: s.point //gewoon 1 value
            };
            data.boxsize = { //de hele box
                w: data.infosize.w,
                h: data.infosize.h + s.staterow
            };

            //positie staat in de ineqHistory
            var oldpos = saved.historylefttop; //al gezet in togglehistory
            if (forceReposition || (!oldpos)) {
                //uitrekenen
                let ineq = modelctrl.getElement(saved.ineq);
                saved.historylefttop = oldpos = {
                    left: ineq.position('x'),
                    top: ineq.position('y') + ineq.height() / 2 + 20
                };
            }
            //basis voor de boxinfo
            data.box = {
                left: oldpos.left,
                top: oldpos.top,
                right: oldpos.left + data.boxsize.w,
                bottom: oldpos.top + data.boxsize.h
            };
            //we noemen het container, maar het is géén compound, want die verdwijnt achter andere nodes.
            //we zetten hem toevallig gewoon op dezelfde plek als de rest
            var historynode = modelctrl.addSimnodes({
                    group: 'nodes',
                    classes: 'simulation simhistory_container',

                    position: {
                        x: data.box.left + data.boxsize.w / 2,
                        y: data.box.top + data.boxsize.h / 2
                    }, //default
                    data: {
                        type: 'simhistory_container',
                        id: 'histcontainer_' + ineqkey,
                        historyineq: ineqkey,
                        automovegroup: amg,
                        //via css truukje:
                        height: data.boxsize.h + 'px',
                        width: data.boxsize.w + 'px'
                    }
                },
                function () {
                    hideIneqHistory(ineqkey); //de functie onder de prullenbakknop
                });

            //we bubbelen het tapevent van alle subnodes, zodat de prullenbak toont
            var tapContainer = function () {
                historynode.trigger('tap');
            };
            //bewaar steeds de topleft
            historynode.on('position', function () {
                saved.historylefttop =
                    {
                        left: historynode.position('x') - data.boxsize.w / 2,
                        top: historynode.position('y') - data.boxsize.h / 2
                    };
            });
            //edges
            modelctrl.addEdgeFromTo(modelctrl.getElement(saved.from), historynode, "source", "simulation");
            modelctrl.addEdgeFromTo(modelctrl.getElement(saved.to), historynode, "target", "simulation");

            //een grijs vlak als achtergrond
            modelctrl.addSimnodes({
                group: 'nodes',
                classes: 'simulation simhistory simhistory_background',
                position: {
                    x: data.box.left + data.boxsize.w / 2, //in het midden van de box
                    y: data.box.top + data.infosize.h / 2 //niet helemaal in het midden, vanwege de staterow
                },

                data: {
                    id: historynode.id() + '_bg',
                    historyineq: ineqkey,
                    automovegroup: amg,
                    height: data.infosize.h + 'px',
                    width: data.infosize.w + 'px'
                }
            }).on('tap', tapContainer);

            //statenummers en values
            data.states.forEach(function (sn, nr) {
                let sim = sn.data('sim'); //de statedata
                let x = data.box.left + (nr + 0.5) * s.kolom; //midden in het blok.
                modelctrl.addSimnodes({
                    group: 'nodes',
                    classes: 'simulation simhistory simhistory_statenumber', //geen simcache

                    position: {
                        x: x,
                        y: data.box.bottom - s.staterow / 2 //en midden in de staterow
                    },
                    data: {
                        automovegroup: amg,
                        id: historynode.id() + '_statenr_' + nr,
                        historyineq: ineqkey,
                        num: sim.num,
                        //pakken volledige ruimte, css centreert het label
                        height: s.staterow + 'px',
                        width: s.kolom + 'px'
                        //parent: container
                    }
                }).on('tap', tapContainer);
                //en de ineq
                // var from = getIneqArg('q', ineq.from, ineq.to, data, simtype);
                // var to = getIneqArg('q', ineq.to, ineq.from, data, simtype);

                let ineqtype = saved.perState[sim.num];
                if (!ineqtype) {
                    //uitrekenen
                    let ineqs = saved.derivative ? sim.d_ineq : sim.q_ineq;
                    let qd = saved.derivative ? 'd' : 'q';
                    ineqtype = false;
                    //we moeten op zoek naar de from en de to
                    ineqs.forEach(function (ineq) {
                        var fromarg, toarg;
                        fromarg = getIneqArg(qd, ineq.from, ineq.to, sim, 'state');
                        if (fromarg && fromarg.id() == saved.from) //direct checken
                        {
                            toarg = getIneqArg(qd, ineq.to, ineq.from, sim, 'state');
                            if (toarg && toarg.id() == saved.to) {
                                //deze ineq is goed
                                ineqtype = ineq.type
                                return false; //break
                            }
                        }
                    });
                }
                ineqtype = ineqtype || "unknown";
                //altijd tekenen, ook bij unknown
                saved.perState[sim.num] = ineqtype; //niet nog een keer uitzoeken
                modelctrl.addSimnodes({
                    group: 'nodes',
                    classes: 'simulation simhistory simhistory_ineqmarker simhistory_ineqmarker_' + ineqtype,
                    position: {
                        x: x, y: data.box.top + s.interval / 2
                    },
                    data: {
                        type: 'simhistory_ineqemarker',
                        id: historynode.id() + '_stateineqmarker_' + nr,
                        automovegroup: amg,
                        historyineq: ineqkey
                    }
                }).on('tap', tapContainer);
            });
        }

        /**
         * Verwijder de ineqhistory met de gegeven ineqkey
         * @param ineqkey
         * @param {boolean} [intern] Als true, dan sturen we geen simulatieberichten
         */
        function hideIneqHistory(ineqkey, intern) {
            if (model.werkmodus === 'volg') {
                if (!intern) //zou niet moeten voorkomen, want de hideAll wordt al in de canvascontroller afgevangen
                {
                    simulatiebericht('hideIneqHistory', {
                        ineqkey: ineqkey
                    });
                    return;
                }
            }
            modelctrl.removeSimulation('[historyineq="' + ineqkey + '"]'); //weg met alle nodes van deze history
            if (simulatie.ineqHistory && simulatie.ineqHistory[ineqkey]) {
                simulatie.ineqHistory[ineqkey].visible = false;
            }
            //laatste?
            pscope.history.ineq = ineqhistory = modelctrl.simulationContent('[historyineq]').nonempty();
            if (!intern) {
                planStuursimulatiestatus(); //bijwerken
            }
        }

        //public versie voor aanroep vanuit volger via simulatiebericht
        pub.volgerHideIneqHistory = function (ineqkey) {
            hideIneqHistory(ineqkey); //eigenlijk heel eenvoudig
        };

        /************** selectestate *************************/

        /**
         * Check stateselect hash in de parentscope op selectieflags. Op dit moment vlaggen we 'alles', 'niets', 'endings' en 'pad'
         */
        function checkStateSelect() {
            //alle geselecteerde states inclusief scenario
            var selectie = simcy.$('node[type = "state"]:selected');
            //alle states zonder scenario
            var realstates = simcy.nodes('node[type = "state"][id != "sn0"]');
            //het is een pad als er 1 kop en 1 staart is en alles ertussenin alleen koppelt met andere elementen van het pad

            pscope.stateselect = {

                //alles geselecteerd, input state maakt ons niet uit
                alles: realstates.nonempty() && realstates.filter(':unselected').empty(),

                //niets geselecteerd, input state dus ook niet (maar wel bij tellen states)
                niets: realstates.nonempty() && simcy.nodes('node[type = "state"]:selected').empty(),

                //alleen eindes geselecteerd, input ook niet geselecteerd
                endings: realstates.filter('[[outdegree = 0]]:selected').nonempty() &&
                    realstates.filter('[[outdegree = 0]]:unselected').empty() &&
                    simcy.nodes('node[type = "state"][[outdegree > 0]]:selected').empty(),

                //het is een pad als er 1 kop en 1 staart is, en elk element (behalve de staart) een geselecteerde outgoer heeft
                //en elk element (behalve de kop) een geselecteerde incomer heeft
                //we checken niet op && selectie.nodes('[[outdegrees > 1]]').empty() want er kunnen cycles in zitten (A --> B --> C --> [A,D] ...)
                pad: (selectie.roots().length == 1 && selectie.leaves().length == 1 && selectie.every(function (ele) {
                    //of leaf of minstens 1 outgour selected
                    return (ele.is('[[outdegree = 0]]') || ele.outgoers('node:selected').nonempty()) &&
                        (ele.is('[[indegree = 0]]') || ele.incomers('node:selected').nonempty()); //
                }))
            };
        }

        /************************* HELPERS *****************/
        /**
         * Regel een goede id
         * @param prefix
         * @return {*|string}
         */
        function getId(prefix) {
            return cytoscape.getId(prefix, simcy);
        }

        /**
         * clone een object
         * @param obj
         */
        function clone(obj) {
            return angular.merge({}, obj);
        }

        /********************* PUBLICI EXPORTS ********************/
        //paar functies die alleen in de export bestaan

        /**
         * Geef een referentie naar het cytocanvas-object terug. Niet gebruiken om zelf nodes toe te voegen! Maar wel voor ctrl.cy().collection() etc.
         * @return {*}
         */
        pub.cy = function () {
            return simcy;
        }

        /**
         * Stuur een simulatiebericht naar de leider / volger
         * @param actie
         * @param args
         */
        function simulatiebericht(actie, args) {
            var berichtargs = angular.extend({
                brontype: model.werkmodus,
                actie: actie,
                actieId: api.unique + '-' + (Date.now().toString(16)), //nieuw berichtid
                modelId: model.id,
                notifyId: model.notifyId
            }, args);


            api.simulatiebericht(berichtargs);
        }

    }]);
