/* 
* lesdirect.controller.js
* 27-9-2023 - Jelmer Jellema - Spin in het Web B.V.
*
* Lesdirect-actie vanuit backend (vanuit material): een directe login op een specifiek project, via een backendkey
*/

dynalearn.controller('lesdirect',['$scope', '$state', '$stateParams', 'sihwlog', 'api', function ($scope, $state, $stateParams, sihwlog, api) {
    let log = sihwlog.logLevel('debug');
    log.debug(`lesdirect`, $stateParams);

    api.logout(); //wat er ook was
    if (! $stateParams.lesdirectkey)
    {
        $state.go('login');
        return;
    }
    api.lesdirectlogin($stateParams.lesdirectkey).then(
        () => {
            log.debug(`Ingelogd via lesdirect`);
            $state.go('build');
        },
        err => {
            //mislukt
            log.error(err);
            log.debug(`Naar loginscherm`);
            //mislukt = mislukt
            $state.go('login');
        }
    )
}]);
