/*
7-2-2021 Jelmer
aparte state voor het invoeren van een nieuw wachtwoord. Zodat het ook lekker werkt op mobiel
 */

dynalearn.controller('wwlink', ['$scope', '$state', '$stateParams', 'sihwlog', 'api', function ($scope, $state, $stateParams, sihwlog, api) {
    let log = sihwlog.logLevel('debug');
    $scope.model = {
        ww: null,
        ww2: null
    };
    log.debug('wwlink', $stateParams);

    if (!$stateParams.wwlink) {
        $state.go('login');
        return;
    }

    let token = $stateParams.wwlink;

    /**
     * Regel een via de mail teruggekomen wwlink
     * @param wwlink
     */
    function init() {
        //1. Check even of deze link nog geldig is
        //2. Vraag een nieuw wachtwoord
        //3. Dien hem in.
        //4. Feedback. Niet automatisch inloggen ivm domeinen etc.

        api.isWachtwoordlink(token).then(res => {
            if (!res) {
                $scope.fout = "WACHTWOORDVERGETEN.WIJZIG.ONGELDIG"
            }
        }).catch(e => {
            log.error(e);
            $scope.fout = "ERROR.MISC";
        });
    }

    /**
     * Verstuur het wachtwoord
     */
    $scope.verstuurWachtwoord = function () {
        $scope.fout = $scope.succes = false;
        api.updateWachtwoord(token, $scope.model.ww).then(res => {
            if (res) {
                $scope.succes = "WACHTWOORDVERGETEN.WIJZIG.GEWIJZIGD"
            } else {
                $scope.fout = "WACHTWOORDVERGETEN.WIJZIG.ONGELDIG";
            }
        }).catch(e => {
            log.error(e);
            $scope.fout = "ERROR.MISC";
        });
    }

    init();


}]);
