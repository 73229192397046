/* 
* modelaction.directive.js
* 21-11-2022 - Jelmer Jellema - Spin in het Web B.V.
*
* Helper for dashboard: shows the information for 1 model
*/

dynalearn.directive('modelaction', ['sihwlog', '$interval',
    function (sihwlog, $interval) {
        let log = sihwlog.logLevel('debug');
        const kleur_goed = "#008000";
        const kleur_fout = "#FF0000";
        const kleur_x_as = "#000000";
        const kleur_actieticks = "#000000";
        const asbreedte = 25; //de hoeveelheid x voor een y-as (dus lijn + cijfers etc)
        const as_extra = 10; //de hoeveelheid x rechts van elke y-as
        const tickbreedte = 10; //op de y-assen dus

        const tikhoogte = 10; //x-as komt halverwege de tikhoogte
        const extraruimte = 5; //tussen tik en aantal / fout = 0

        return {
            restrict: 'E', //element
            templateUrl: 'app/directives/dashboardv2/modelaction.html',
            scope: {
                model: '=', //the dynalearn model for which we show the data
                actions: '=', //action and error data for this model
                start: '=', //string for startmoment
                end: '=' //string for endmoment,
            },
            link: function ($scope, el) {

                $scope.van = "modelaction";

                //globals
                const canvas = el.find('canvas')[0];
                const renderer = canvas.getContext('2d');
                let canvasWidth = 0;
                let canvasHeight = 0;

                let m_start = null;
                let m_end = null;

                let laatste_punt = null; //x, y_aantal, y_fout. Voor uitbreiding van de nulijn
                let tellingen = []; //count en x waarop het wijzigt

                let listener = null;
                //watch wijziging in model, zodat we naar redrawModel scope event kunnen luisteren
                $scope.$watch('model', function () {
                    const broadcastkey = `modelaction_redraw_${$scope.model.id}`;
                    if (listener) {
                        //deregistreer de oude listener
                        listener();
                        listener = null;
                    }
                    //nieuwe zetten
                    listener = $scope.$on(broadcastkey, function (event) {
                        redraw();
                    });
                    //en we plannen dit ook in via een uitgesteld broadcast naar onszelf
                    setTimeout(() => {
                        $scope.$broadcast(broadcastkey)
                    }, 20);
                });

                //watch the actions
                //we watchen niets, we verwachten een redraw
                // $scope.$watch('actions', redraw, true); //deep
                // $scope.$watchGroup(['start', 'end'], redraw);


                $scope.count = {
                    totaal: '?',
                    aantal: '?',
                    fout: '?'
                };

                //ruimte links voordat de graph begint ook in de scope, voor het beginmoment
                //2 assen en etra ruimte ertussen en na
                $scope.x_start = 2 * asbreedte + 2 * as_extra;

                //doortrekken naar nu
                let tillNowInterval = $interval(tillNow, 60000); //periodiek doortrekken naar nu
                $scope.$on('$destroy', _ => {
                    if (tillNowInterval) {
                        $interval.cancel(tillNowInterval);
                    }
                    if (listener) {
                        listener();
                    }
                });

                //mouseevents
                $scope.popup = null;

                $(canvas).on('mousemove', e => {
                    $scope.$applyAsync(() => {
                        if (!(m_start && m_end)) {
                            return; //niets te doen nog
                        }
                        const x_start = 2 * asbreedte + 2 * as_extra;
                        const graphwidth = canvasWidth - x_start;
                        if (e.offsetX < x_start) {
                            $scope.popup = null;//weg
                            return;
                        }
                        $scope.popup = {
                            left: e.offsetX < (canvasWidth / 2) ? e.offsetX : null,
                            right: e.offsetX >= (canvasWidth / 2) ? canvasWidth - e.offsetX : null,
                            top: e.offsetY < (canvasHeight / 2) ? e.offsetY + 20 : null,
                            bottom: e.offsetY >= (canvasHeight / 2) ? canvasHeight - e.offsetY + 20: null
                        };
                        $scope.popup.tijdstip = m_start.clone().add(m_end.diff(m_start, 'seconds', true) * (e.offsetX - x_start) / graphwidth, "seconds").format('D-M-YY HH:mm:ss');
                        //vind de laatste telling
                        let telling = null;
                        for (let i = 0; i < tellingen.length; i++) {
                            if (tellingen[i].x <= e.offsetX) {
                                telling = tellingen[i];
                            } else {
                                break;
                            }
                        }
                        $scope.popup.telling = telling;
                    });
                });
                $(canvas).on('mouseout', _e => {
                    $scope.$applyAsync(() => {
                        $scope.popup = null;
                    });
                });

                function redraw() {
                    if (!($scope.actions && $scope.start && $scope.end)) {
                        log.debug(`skip redraw: missing data`, $scope.actions, $scope.start, $scope.end);
                        return; //not drawing
                    }

                    if (!calcCanvasDimensions()) //set canvasHeight and canvasWidth
                    {
                        log.debug(`skip redraw - geen canvasparent`);
                        return; //kunnen niet tekenen
                    }

                    m_start = moment($scope.start);
                    m_end = moment($scope.end);
                    tellingen = []; //weer op niks

                    //we hebben links 2 assen en nog wat ruimte
                    const x_start = 2 * asbreedte + 2 * as_extra;
                    const graphwidth = canvasWidth - x_start;
                    const y_ruimte = canvasHeight - tikhoogte - extraruimte; //altijd boven de ticks

                    renderer.lineWidth = 1;
                    renderer.strokeStyle = kleur_x_as;
                    renderer.clearRect(x_start, 0, canvasWidth, canvasHeight);
                    renderer.beginPath();
                    renderer.moveTo(x_start, canvasHeight - tikhoogte / 2 - 0.5);
                    renderer.lineTo(canvasWidth, canvasHeight - tikhoogte / 2 - 0.5);
                    renderer.stroke();

                    renderer.lineWidth = 1;

                    //create the ticks
                    for (let action of $scope.actions?.actions || []) {
                        if (!action.m) {
                            action.m = moment(action.moment); //cache
                        }
                        if (action.m.isBetween(m_start, m_end)) {
                            renderer.beginPath();
                            let pos = Math.round(x_start + graphwidth * relativeTime(action.m));
                            renderer.strokeStyle = kleur_actieticks;
                            renderer.moveTo(pos, canvasHeight);
                            renderer.lineTo(pos, canvasHeight - tikhoogte);
                            renderer.stroke();
                        }
                    }


                    //we gaan errors en aantallen doorlopen

                    //totaal aantal normingrediënten halen we uit de laaste normcount (stel dat we anders zijn gaan tellen, dan is de laatste de goede)
                    let normelements = 10; //standaard
                    if ($scope.actions?.errors?.length) {
                        normelements = $scope.actions.errors[$scope.actions.errors.length - 1].count.totaal;
                    }
                    let elementschaal = normelements + (5 - normelements % 5); // Math.max(10, normelements) + 5;
                    // zoveel elementen kunnen we verwachten
                    let foutschaal = 10; // Math.round(Math.max(10, normelements / 2));

                    /**
                     * Helper voor de 2 assen
                     */
                    function y_as(x, schaal, kleur) {
                        let as_x = x + asbreedte - (1 + tickbreedte / 2);
                        let text_breedte = as_x - tickbreedte / 2 - 5;
                        renderer.beginPath();
                        renderer.lineWidth = 1;
                        renderer.strokeStyle = kleur;
                        renderer.moveTo(as_x, y_ruimte); //daarvoor komt de tekt
                        renderer.lineTo(as_x, 0);
                        renderer.stroke();
                        renderer.lineWidth = 1;
                        //stappen
                        //maximaal 5 tiks in veelvoud van 5 (met 0)
                        let stap = Math.ceil(schaal / 5);
                        if (stap % 5) {
                            //omhoog op vijftal
                            stap += (5 - stap % 5);
                        }

                        let tick_y = 0;
                        while (true) {
                            let y = (1 - tick_y / schaal) * y_ruimte;
                            if (y === 0) {
                                //dan moet hij iets omlaag:
                                y = 1;
                            }
                            renderer.beginPath();
                            renderer.moveTo(as_x - (tickbreedte / 2), y);
                            renderer.lineTo(as_x + (tickbreedte / 2), y);
                            renderer.stroke();
                            renderer.font = "10px";
                            renderer.fillStyle = kleur;
                            renderer.fillText(`${tick_y}`, x + 3, y < 10 ? 10 : y + 2, text_breedte);

                            //anders door
                            if (tick_y >= schaal) {
                                break;
                            }
                            tick_y += stap;
                            if (tick_y > schaal) {
                                tick_y = schaal; //de laatste
                            }
                        }
                    }

                    y_as(0, elementschaal, kleur_goed);
                    y_as(asbreedte + as_extra, foutschaal, kleur_fout);

                    //en tekenen
                    $scope.count = {
                        totaal: '?',
                        aantal: '?',
                        fout: '?'
                    };

                    laatste_punt = null; //full reset
                    let laatste_telling = null;
                    for (let counter of $scope.actions?.errors || []) {
                        if (!counter.m) {
                            counter.m = moment(counter.moment); //cached
                        }
                        if (counter.m.isBefore(m_start)) {
                            laatste_telling = $scope.count = counter.count;
                            tellingen.push({x: x_start, count: counter.count});
                            let aantal_goed = Math.max(0, counter.count.aantal - counter.count.fout);
                            //we zetten wel de laatste_punt module-global, niet heel efficient, maar goed
                            laatste_punt = {
                                x: x_start,
                                y_aantal: Math.round((1 - aantal_goed / elementschaal) * y_ruimte),
                                y_fout: Math.round((1 - counter.count.fout / foutschaal) * y_ruimte)
                            };
                        } else if (counter.m.isSameOrBefore(m_end)) {
                            //bepaal het punt
                            $scope.count = counter.count; //deze is nu actueel
                            let x = x_start + relativeTime(counter.m) * graphwidth;
                            if (laatste_punt && x < laatste_punt.x) {
                                continue; //skip dubbele
                            }
                            //is telling anders?
                            if (!angular.equals(laatste_telling, counter.count)) {
                                laatste_telling = counter.count;
                                tellingen.push({x: x, count: counter.count});
                            }
                            //goed teller, klopt niet, want meerdere fouten in zelfde ding mogelijk, maar zo doen we de aantal lijn
                            let aantal_goed = Math.max(0, counter.count.aantal - counter.count.fout);
                            let y_aantal = Math.round((1 - aantal_goed / elementschaal) * y_ruimte);
                            let y_fout = Math.round((1 - counter.count.fout / foutschaal) * y_ruimte);

                            //teken aantal
                            renderer.beginPath();
                            renderer.strokeStyle = kleur_goed;
                            if (laatste_punt) {

                                let y = laatste_punt.y_aantal + 0.5;
                                if (y <= 0.5) {
                                    //te hoog: stippellijn
                                    y = 0.5;
                                    renderer.setLineDash([5, 2]);
                                }
                                renderer.moveTo(laatste_punt.x + 0.5, y);
                                renderer.lineTo(x + 0.5, y);
                                renderer.stroke();
                                renderer.beginPath();
                                renderer.moveTo(x + 0.5, y);
                                renderer.setLineDash([]);
                                if (y_aantal !== laatste_punt.y_aantal) {
                                    renderer.lineTo(x + 0.5, y_aantal + 0.5);
                                }
                                renderer.stroke();
                            }

                            //anders is dit het begin

                            //teken fouten
                            renderer.beginPath();
                            renderer.strokeStyle = kleur_fout;
                            if (laatste_punt) {
                                let y = laatste_punt.y_fout + 0.5;
                                if (y <= 0.5) {
                                    //te hoog: stippellijn
                                    y = 0.5;
                                    renderer.setLineDash([5, 2]);
                                }
                                renderer.moveTo(laatste_punt.x + 0.5, y);
                                renderer.lineTo(x + 0.5, y);
                                renderer.stroke();
                                renderer.beginPath();
                                renderer.moveTo(x + 0.5, y);
                                renderer.setLineDash([]);
                                if (y_fout !== laatste_punt.y_fout) {
                                    renderer.lineTo(x + 0.5, y_fout + 0.5);
                                }
                                renderer.stroke();
                            }
                            laatste_punt = {
                                x: x,
                                y_aantal: y_aantal,
                                y_fout: y_fout
                            };
                        }
                    }
                    //doortrekken naar nu:
                    tillNow();
                }

                /**
                 * Trek door vanaf Laatste punt naar nu
                 */
                function tillNow() {
                    if (laatste_punt) {
                        const x_start = 2 * asbreedte + 2 * as_extra;
                        const graphwidth = canvasWidth - x_start;

                        let nu = Math.min(1, relativeTime(moment()));
                        let nu_x = x_start + nu * graphwidth;

                        if (nu_x < laatste_punt.x) {
                            return; //niet nodig
                        }
                        let y = 0;
                        renderer.beginPath();
                        renderer.strokeStyle = kleur_goed;
                        y = laatste_punt.y_aantal + 0.5;
                        if (y <= 0.5) {
                            //te hoog: stippellijn
                            y = 0.5;
                            renderer.setLineDash([5, 2]);
                        }
                        renderer.moveTo(laatste_punt.x + 0.5, y);
                        renderer.lineTo(nu_x + 0.5, y);
                        renderer.stroke();
                        renderer.setLineDash([]);
                        renderer.beginPath();
                        renderer.strokeStyle = kleur_fout;
                        y = laatste_punt.y_fout + 0.5;
                        if (y <= 0.5) {
                            //te hoog: stippellijn
                            y = 0.5;
                            renderer.setLineDash([5, 2]);
                        }
                        renderer.moveTo(laatste_punt.x + 0.5, y);
                        renderer.lineTo(nu_x + 0.5, y);
                        renderer.stroke();
                        renderer.setLineDash([]);
                    }
                }

                /**
                 * Recalculate the canvas pixels before repaint
                 */
                function calcCanvasDimensions() {
                    let parent = canvas.offsetParent;
                    if (!parent) {
                        return false;
                    }
                    canvasWidth = parent.offsetWidth;
                    canvasHeight = parent.offsetHeight;
                    canvas.width = canvasWidth;
                    canvas.height = canvasHeight;
                    return true;
                }

                /**
                 * Relativetime between start en end moment
                 * @param timestamp
                 * @returns {number}
                 */
                function relativeTime(timestamp) {
                    if (!($scope.start && m_start && m_end)) {
                        return 0;
                    }
                    if (!(timestamp instanceof moment)) {
                        timestamp = moment(timestamp);
                    }
                    return timestamp.diff(m_start, "seconds", true) / (m_end.diff(m_start, 'seconds', true) || 1); //fix divide by zero
                }
            }
        }
    }
]);
