/**
 * Created by Lennaerd on 26-1-2016.
 * deze controller vangt de events uit het modalMenu op.
 * $uibModalInstance injectable to access the modal instance. - zodat we $uibModalInstance.close() kunnen gebruiken om het scherm
 * te sluiten via een knop of in een andere functie.
 *
 * Initvalues:
 * - model: object met metadata (titel, modelleerniveau) voor defaults. Verplicht
 * - aanhef: locale-string voor de te gebruiken aanhef (of niets als geen aanheftekst)
 */
dynalearn.controller('modelPropsController', ['$scope', '$uibModalInstance', '$translate', '$sce', 'api', 'sihwlog', 'initvalues', function ($scope, $uibModalInstance, $translate, $sce, api, sihwlog, initvalues) {

    const log = sihwlog.logLevel('debug');

    $scope.model = initvalues.model;
    $scope.aanhef = initvalues.aanhef;
    $scope.titelDisabled = (!initvalues.wijzigtitel);
    $scope.modelleerniveau_schrijfbaar = api.userdata.projectdata.modelleerniveau_overschrijfbaar;
    $scope.simulatiepreferentie_schrijfbaar = api.userdata.projectdata.simulatiepreferentie_overschrijfbaar;

    //een localestring, de preferentie moet dus wel een bestaand nummer zijn
    //$translate sanitized een eventuele & naar &amp; (via escape). Daar moeten we iets aan doen, want dit is voor optietext. We kunnen DomParser gebruiken (https://stackoverflow.com/questions/1912501/unescape-html-entities-in-javascript), maar ook gewoon &amp; replacen...
    $scope.projectsimulatiepreferentie = (api.userdata.projectdata.simulatiepreferentie ? $translate.instant("SIMULATIEPREFERENTIE_" + api.userdata.projectdata.simulatiepreferentie) : "").replace('&amp;','&');
    //de niveaus moeten we even in een data-object hebben, inclusief afbeeldingen
    let projectmodelleerniveau = api.userdata.projectdata.modelleerniveau || 20; //als we het niet weten, dan standaard
    //val er in ivm string vs number
    $scope.niveaus = {
        0: {val: 0, naam: `${__("ALS_PROJECT")} (${__("MODELLEERNIVEAU_" + projectmodelleerniveau)})`},
        10: {val: 10, naam: __("MODELLEERNIVEAU_10"), disabled: true},
        20: {val: 20, naam: __("MODELLEERNIVEAU_20"), icons: ['causal_plus.svg', 'causal_min.svg']},
        25: {val: 25, naam: __("MODELLEERNIVEAU_25"), icons: ['causal_plus.svg', 'causal_min.svg','inequality_equal.svg']},
        30: {
            val: 30,
            naam: __("MODELLEERNIVEAU_30"),
            icons: ['menuitems/waardenNieuw/quantityspace.svg']
        },
        40: {
            val: 40,
            naam: __("MODELLEERNIVEAU_40"),
            icons: ['influence_positive.svg', 'proportionality_positive.svg']
        },
        50: {
            val: 50,
            naam: __("MODELLEERNIVEAU_50"),
            icons: [],
            letters: ['?', '!']
        }
    };

    //icons bij 0
    $scope.niveaus[0].icons = $scope.niveaus[projectmodelleerniveau].icons;

    $scope.fastest_path_overschrijfbaar = api.userdata.projectdata.fastest_path_overschrijfbaar;
    $scope.project_fastest_path = $translate.instant(`FASTESTPATH.${api.userdata.projectdata.fastest_path ? 'AAN' : 'UIT'}`);

    //init onze miniselect voor niveau (houtje touwtje nepcontrol)
    function cancelNiveauselect() {
        if ($scope.selectopen) {
            $scope.selectopen = false;
            $scope.$apply();
        }
    }

    $('body').on('click', cancelNiveauselect);
    //en bij destroy weer weg
    $scope.$on('$destroy', () => {
        $('body').off('click', cancelNiveauselect);
    });

    //miniselect open
    $scope.toggleNiveauselect = function ($event) {
        if ($scope.modelleerniveau_schrijfbaar) {
            $scope.selectopen = !$scope.selectopen;
            $event.stopPropagation();
        }
        //anders niet open
    };

    /**
     * Klik op niveau
     * @param niveau het hele niveau, we gebruiken de .val prop, zodat het numeriek is
     * @param $event
     */
    $scope.niveauselectclick = function (niveau, $event) {
        $event.stopPropagation();
        if (!niveau.disabled) { //niveau 10 is er wel maar mag nooit
            $scope.selectopen = false; //weer dicht

            //model
            $scope.model.modelleerniveau = niveau.val;
        }
    };


    $scope.cancelPrompt = function () {
        $uibModalInstance.close(false);
    };
    /*niewuwe waarde submitten*/
    $scope.submitPrompt = function () {
        //we sluiten het model en sturen het resultaat terug naar de controller die ons aanriep.

        $uibModalInstance.close($scope.model);
    };

    $scope.close = function () {
        $uibModalInstance.dismiss('cancel');
    };

    function __() {
        return $translate.instant.apply($translate, arguments);
    }
}]);
