/**
 * Elementservice, angular-wrapper om lib/elements.
 *
 * lib/elements is wel in babel terechtgekomen, dus we werken vanaf _libs.Elements
 */
dynalearn.service('elementService', ['$translate', 'sihwlog', function ($translate, sihwlog) {
	let Elements = _libs.Elements;
//	let $log = sihwlog.logLevel('warn');

	//de methoden zijn vooral wrappers, alleen getDefinition wijkt af ivm translate
	return {

		/**
		 * Geeft de definitie van een nodetype terug
		 * @param nodetype
		 */
		getDefinition: function (nodetype) {
			let r = Elements.getDefinition(nodetype);

			if (r.nodename) {
				r.nodename = $translate.instant(r.nodename); //sync! Maar gaat wel goed want ruim na laden https://github.com/angular-translate/angular-translate/issues/473

			}
			return r;
		},

		/**
		 * Returnt true als type in de hierarchie van parenttype valt
		 * @param typeOrDef
		 * @param parenttype
		 * @return {boolean}
		 */
		isSubtypeOf: function (typeOrDef, parenttype) {
			return Elements.isSubtypeOf(typeOrDef, parenttype);
		},

		/**
		 * Geef alle subtypen van een type terug in een array
		 * @param typeOrDef
		 */
		alleSubs: function (typeOrDef) {
			return Elements.alleSubs(typeOrDef);
		},

		alleUps: function (typeOrDef) {
			return Elements.alleUps(typeOrDef);
		},

		//wrappers:

		/**
		 * True als het opgegeven type (of def) een topelement is
		 * @param typeOrDef
		 * @returns {boolean}
		 */
		isTopelement: function(typeOrDef) {
			return Elements.isTopelement(typeOrDef);
		},

		/**
		 * True als het opgegeven type (of def) een subelement is
		 * @param typeOrDef
		 * @returns {boolean}
		 */
		isSubelement: function(typeOrDef) {
		return Elements.isSubelement(typeOrDef);
		},

		/**
		 * True als het opgegeven type (of een hele definitie) een relatie is
		 * @param typeOrDef
		 * @returns {*}
		 */
		isRelation: function (typeOrDef) {
			return Elements.isRelation(typeOrDef);
		}
	}
}]);
