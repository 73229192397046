/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 16-8-2016
 * Time: 11:59
 *
 * Algemene wrapper om de cytoscape lib (die ook geladen moet zijn). Voor gebruik door de modelview en simview services
 * Wel speciaal voor dynalearn: laadt ook de elementservice
 * De service hoeft alleen maar injected te zijn (in de een of andere module) om de uitbreidingen op cytoscape-niveau toegankelijk te hebben
 * we hebben echter ook eigen functies die echt in de service worden aangeroepen
 *
 * EVENTS
 *
 * We emitten de volgende events op de bijbehorende cy-canvas
 * initAutomove: event op de node die de mainnode is in een automove-actie. Bij initialiseren van de automove
 * inAutomove: event op de node die de mainnode is in een automove-actie. Bij verplaatsen in een automove
 * endAutomove: event op de node die de mainnode is in een automove-actie. Bij einde van automove.
 */

dynalearn.factory('cytoscape', ['$rootScope', 'elementService', 'sihwlog', '$timeout', function ($rootScope, elementService, sihwlog, $timeout) {

    var log = sihwlog.logLevel('debug');
    /**** Extra methoden op cytoscape collections **?

     /**
     * cy.els.toIdArray: map een collection op een array met id's
     */
    cytoscape('collection', 'toIdArray', function () {
        return this.map(function (n) {
            return n.id();
        });
    });

    /**** Methoden op losse nodes *****************/

    /**
     * cy.el.definition definition ophalen. Gebruik deze ipv scratch('definition'), want we laden hem automatisch
     */

    cytoscape('collection', 'definition', function () {
        var sdef = this.scratch('definition');
        if (!sdef) {
            //is er nog niet. Zoek hem op
            this.scratch('definition', sdef = elementService.getDefinition(this.data('type')));
        }
        return sdef;
    });

    /**
     * Onze versie van outgoers, houdt rekening met edgeHandles
     */
    cytoscape('collection', 'outElements', function (selector) {
        var $this = this;
        var thiscy = $this.cy();
        var outg = thiscy.collection(); //dit wordt het
        //we moeten het element voor element doen
        $this.forEach(function (element) {
            //1: onze "uitgangen: het element zelf en zijn edgehandles
            var uitgangen = element.add('[type="edgeHandle"][handleParent="' + element.id() + '"]');
            //2: directe overkanten: de outgoers van onze uitgangen
            outg = outg.add(uitgangen.outgoers('[type!="edgeHandle"]'));
            //3: overkanten met edgehandles
            uitgangen.outgoers('[type="edgeHandle"]').forEach(function (otherHandle) {
                outg = outg.add(thiscy.getElementById(otherHandle.data('handleParent')));
            });
        });
        //filteren
        return outg.filter(selector);
    });

    /**
     * Onze versie van incomers, houdt rekening met edgeHandles
     */
    cytoscape('collection', 'inElements', function (selector) {
        var $this = this;
        var thiscy = $this.cy();
        var inc = thiscy.collection(); //dit wordt het
        //we moeten het element voor element doen
        $this.forEach(function (element) {
            //1: onze "uitgangen: het element zelf en zijn edgehandles
            var uitgangen = element.add('[type="edgeHandle"][handleParent="' + element.id() + '"]');
            //2: directe overkanten: de incomers van onze uitgangen
            inc = inc.add(uitgangen.incomers('[type!="edgeHandle"]'));
            //3: overkanten met edgehandles
            uitgangen.incomers('[type="edgeHandle"]').forEach(function (otherHandle) {
                inc = inc.add(thiscy.getElementById(otherHandle.data('handleParent')));
            });
        });
        //filteren
        return inc.filter(selector);
    });

    /**
     * geef de slope (m) en intercept (b) van een edge terug, zodat de lijn waarop de het lijnstuk tussen source en target ligt wordt gedefineerd als y = mx + b. Bij vertikale lijn wordt niet {m,b} maar {x} teruggegeven. Check daar op dus
     */
    cytoscape('collection', 'lijnfunctie', function () {
            let edge = this.first();
            if (!edge.is('edge')) {
                throw "lijnfunctie() is alleen voor edges";
            }
            let A = edge.source().position();
            let B = edge.target().position();
            let dy = B.y - A.y;
            let dx = B.x - A.x;
            if (dx !== 0) {
                //geen vertikale lijn, misschien horizontaal maar dat geeft niet
                let m = dy / dx; //slope
                let b = A.y - m * A.x; //intercept
                return {m: m, b: b}; //slope en intercept
            } else {
                return {x: A.x};
            }
        }
    );

    /* en op de core */
    /**
     * emptyArea: check of er *NODES* zijn in een gebied
     * standaard kijken we naar zichtbare nodes
     * maar er kan een andere selector worden meegegeven
     */
    cytoscape('core', 'emptyArea', function (left, right, top, bottom, selector) {
        //this is hier de cy
        selector = selector ?? ':visible';
        var overlap = false;
        this.nodes(selector).forEach(function (node) {
            var npos = node.position();
            var nwidth = node.outerWidth();
            var nheight = node.outerHeight();
            var nleft = npos.x - nwidth / 2;
            var nright = npos.x + nwidth / 2;
            var ntop = npos.y - nheight / 2;
            var nbottom = npos.y + nheight / 2;

            if (
                ((nleft >= left && nleft <= right) ||
                    (nright >= left && nright <= right) ||
                    (nleft <= left && nright >= right)) //aan beide kanten eruit
                &&
                ((ntop >= top && ntop <= bottom) ||
                    (nbottom >= top && nbottom <= bottom) ||
                    (ntop <= top && nbottom >= bottom))
            ) {
                overlap = true;
                return false; //break;
            }
        });
        return !overlap;
    });

    /**
     * Reken rendered coords uit een DOM-event e.d. om naar zoompos
     * Houdt geen rekening met scaling van de container
     */
    cytoscape('core', 'renderedToModelCoords', function (renderedPos) {
        var cy = this;
        var pan = cy.pan();
        var zoom = cy.zoom();
        return {
            x: (renderedPos.x - pan.x) / zoom,
            y: (renderedPos.y - pan.y) / zoom
        };
    });

    /**
     * fixToMaxZoom(maxZoom, onlyIfNeeded): fit het model in de canvas, maar zoom niet meer in dan maxZoom (standaard 3).
     * onlyIfNeeded: als true dan wordt gecheckt of er wel een element buiten de extend is
     */
    cytoscape('core', 'fitToMaxZoom', function (maxZoom, onlyIfNeeded) {
        let padding = 44; //hoeveel padding aan alle kanten?
        var cy = this;
        if (onlyIfNeeded) {
            //is het nodig?
            var vp = cy.extent();
            var bb = cy.elements().boundingBox();

            if (!(bb.x1 < (vp.x1 + padding) || bb.x2 > (vp.x2 - padding) || bb.y1 < (vp.y1 + padding) || bb.y2 > (vp.y2 - padding))) {
                return;
            }
        }
        //fit alles
        cy.fit(null, padding);
        maxZoom = maxZoom || 3;

        //laat hij dit eerst maar even uitrekenen
        $timeout(function () {
            cy.center();
            log.debug('NA FIT zoom', cy.zoom(), 'maxzoom', maxZoom);
            if (cy.zoom() > maxZoom) {
                cy.zoom(maxZoom);
                cy.center()
            }

        }, 100);
    });


    /***************** AUTOMOVE ***************************/

    /* automove handlers v2 */

    /**
     * Al het werk gebeurt bij het echte draggen: doAutomove
     * bij init (drag) slaan we evende huidige positie van de betreffende node op, zodat we bij drag kunnen zien hoe hij gewijzigd is
     * bij stop (free) halen we onze data weg, zodat doAutomove weet dat hij opnieuw moet intialiseren
     * grab gebeurt bij mousedown, free bij mouseup, er is maar één grab, ook bij multipleselectie (en meerdere drags)
     * draggen van multiple selectie zou dus kunnen zijn: grab-free,grab-free,grab-grab-graf-drag,drag,drag,free,free,free
     * we doen daarom zo weinig mogelijk bij init van de automove, het is vooral resetten
     */

    /**
     * grab van een node: we bewaren zijn huidige positie tbv de delta
     */
    function initAutomove() {
        this.scratch('prevposition', clone(this.position()));
    }

    /**
     * Aangeroepen bij drag - haal de automovedata op, of maak hem als hij voor deze dragsessie nog uitgerekend moet worden
     * @param eventtarget de node waarop de drag plaatsvindt
     */
    function getAutomove(eventtarget) {
        var canvas = eventtarget.cy(); //welk canvas
        var options = canvas.scratch('_cytoscape_service') || {};

        var automove = canvas.scratch('automove');
        if (!automove) {
            //genereren
            automove = {
                mainnode: eventtarget, //dit wordt de node waarvan we de beweging volgen
                movegroup: canvas.collection(), //de nodes die moeten meebewegen
                all: canvas.collection() //en alle nodes, ook echte geselecteerde
            };
            //de movegroup is alles wat moet meebewegen met alle grabbed nodes
            //maar niet de grabbed nodes zelf, want dat regelt cytoscape

            var done = []; //snel zoeken

            var agenda = [];
            //in elk geval wat er nu geselecteerd is
            //die multiselect is nodig voor simulate, waar dat kan.
            //multiselect: ook selected nodes, anders alleen grabbed
            canvas.$(options.multiselect ? ':grabbed, :selected' : ':grabbed').forEach(function (n) {
                agenda.push(n);
                automove.all = automove.all.add(n);
            });
            var node;
            var anticrash = 0;

            //fix missende automoveorders
            canvas.nodes('[automovegroup][^automoveorder]').data('automoveorder', 0);
            while (node = agenda.pop()) {
                if (++anticrash > 10000) {
                    log.error('Anticrash: te grote loop');
                    break;
                }
                var nodeId = node.id();
                if (done.indexOf(nodeId) !== -1) {
                    //is er al
                    continue;
                }
                done.push(nodeId); //hoe dan ook maar 1x
                //toevoegen als deze niet grabbed is
                if (!node.grabbed()) {
                    automove.movegroup = automove.movegroup.add(node);
                }
                automove.all = automove.all.add(node);

                //in de agenda: alle outElements die mogen moven with parent
                agenda.push.apply(agenda, node.outElements('node[^nomovewithparent]').toArray()); //toArray hebben we bovenin op collection gedefinieer
                //en eventuele edgeHandles
                agenda.push.apply(agenda, canvas.nodes('node.edgeHandle[handleParent="' + nodeId + '"]').toArray());
                //en juist hun parent
                if (node.data('handleParent') && (!node.data('freeMove'))) //freeMove op true om een handle goed te positioneren
                {
                    agenda.push(canvas.getElementById(node.data('handleParent')));
                }

                //voeg toe: alles uit de automovegroup met lagere of gelijke order
                //als geen order, dan is het ook goed
                if (node.data('automovegroup')) {
                    agenda.push.apply(agenda, canvas.nodes('[automovegroup="' + node.data('automovegroup') + '"][automoveorder<=' + node.data('automoveorder') + ']').toArray());
                }
            }

            canvas.scratch('automove', automove);
            //we doen geen trigger, maar een scope broadcast. Dat werkt beter met argumenten
            //we doen een emit op de betreffende cy
            canvas.emit('initAutomove', [automove]);
            // $rootScope.$broadcast('cy.initAutomove',automove);
        }
        return automove;
    }

    /**
     * Handle drag en automove.
     * Als dit de eerste keer is van de huidige drag-actie gaan we initialiseren: we bepalen welke nodes moeten, en kiezen één van de grabbed nodes
     * als degene naar wie we luisteren
     * Elke keer laten we hele groep dezelfde beweging maken als de hoofdnode
     */
    function doAutomove() {
        var dragged = this;
        var canvas = dragged.cy(); //bepaal welke canvas, sim of model
        var automove = getAutomove(dragged);

        //moeten we iets met deze node? We letten alleen op de mainnode
        if (!dragged.same(automove.mainnode)) {
            return; //we bemoeien ons er niet mee
        }
        canvas.emit('inAutomove', [automove]);
        // $rootScope.$broadcast('cy.inAutomove',automove);
        var pos = dragged.position();
        var prevpos = dragged.scratch('prevposition');

        var dx = pos.x - prevpos.x;
        var dy = pos.y - prevpos.y;

        if (dx == 0 && dy == 0) {
            //geen beweging, klaar
            return;
        }
        dragged.scratch('prevposition', clone(pos)); //de nieuwe prevpos

        //we gaan alle nodes in de groep lekker bewegen
        automove.movegroup.forEach(function (node) {
            var prevpos = node.position();
            node.position({x: prevpos.x + dx, y: prevpos.y + dy});
        })

    }

    function endAutomove() {
        var canvas = this.cy(); //bepaal welke canvas, sim of model
        //is de drag ook echt geweest?
        var automove = canvas.scratch('automove');
        if (!automove) {
            return;
        }
        //weg met de data
        canvas.removeScratch('automove');

        canvas.emit('endAutomove', [automove]);
        // $rootScope.$broadcast('cy.endAutomove',automove);
    }

    /***** HELPERS **********/
    /**
     * clone een object
     * @param obj
     */
    function clone(obj) {
        return angular.merge({}, obj);
    }


    var service = { //de export

        /**
         * Run de libraryfunctie van cytoscape
         * @param {*}[] argumenten voor de oorspronkelijke cytoscape(...) functie
         * @return {*}
         */
        lib: function () {
            return cytoscape.apply(cytoscape, arguments);
        },

        /**
         * Initialiseer onze features op een specifieke cy-instance (gekoppelde canvas)
         *  waaronder de automove-functionaliteit
         * @param cy de geinitialiseerde cytoscape-instance
         * @param {object} [options] Specifieke opties
         * @param {boolean} options.multiselect Als true, dan worden bij automove ook de huidig geselecteerde items meegenomen. Anders (default) alleen de gegrabbede node en zijn automovegroep
         */
        initFeatures: function (cy, options) {
            /*
             Automove
             Elementen in dezelfde automovegroup en alle child elementen bewegen automatisch
             bij automove beweegt alles in dezelfde group met dezelfde of lagere order
             automoveorder: [int] default 0

             Als een node beweegt, zullen alle nodes met dezelfde automovegroup en een automoveorder die gelijk is aan
             of lager is dan de automoveorder van de bewegende node, dezelfde dx en dy bewegen.

             Door een element een hoge automoveorder te geven zorg je dat andere nodes met hem meebewegen, maar hij
             niet met de andere.

             children bewegen altijd mee met hun parent
             */

            cy.scratch('_cytoscape_service', options || {});
            cy.on('grab', 'node', initAutomove);
            cy.on('drag', 'node', doAutomove);
            cy.on('free', 'node', endAutomove);
        },


        /**
         * Genereer een id die past in ons model, ook voor prolog. Id's moeten een lowercasestring + getal zijn (e232)
         * @param {string} [prefix] default 'e'
         * @param {Cy} [cyobject] default het modelcanvas, maar kan ook simulatie zijn
         * @returns {string}
         */
        getId: function (prefix, cyobject) {
            prefix = prefix || 'e';
            cyobject = cyobject || cy;
            var id = prefix + Date.now();
            if (cyobject.hasElementWithId(id)) {
                var teller = 2;
                while (cyobject.hasElementWithId(id + teller)) {
                    teller++;
                }
                id = id + teller;
            }
            return id;
        }
    };
    return service;
}]);
