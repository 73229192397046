/**
 * Created by Lennaerd on 26-1-2016.
 *
 * een algemene (parent) controller die we aan de <body> hangen. op die manier zijn functies binnen deze controller
 * in iedere andere controller en views te gebruiken.
 *
 * deze controller bevat $uibModal (vanuit de ui-bootstrap) om het modal menu te instantiëren
 * docs:
 * modal: https://github.com/angular-ui/bootstrap/tree/master/src/modal/docs
 * angular-bootstrap: https://angular-ui.github.io/bootstrap/
 * standaard openClass is 'modal-open'
 *
 */
dynalearn.controller('mainController', ['$rootScope', '$scope', '$uibModal', 'api', function ($rootScope, $scope, $uibModal, api) {



}])
;