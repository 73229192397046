/**
 * Created by Lennaerd on 26-1-2016.
 * deze controller vangt de events uit het modalMenu op.
 * $uibModalInstance injectable to access the modal instance. - zodat we $uibModalInstance.close() kunnen gebruiken om het scherm
 * te sluiten via een knop of in een andere functie.
 */
dynalearn.controller('modalLoadMenuController', ['$scope', '$uibModalInstance', 'sihwlog', 'api', 'data', function ($scope, $uibModalInstance, sihwlog, api, data) {
    var $log = sihwlog.logLevel('debug');

    function parsedatetonldate(date) {
        var parts = date.split('-');
        var year = parts[0];
        var month = parts[1];//0-based januari = [0] is t januari dan maken we er 1 van
        var day = parts[2].split('T')[0];//blijft nog wat achter de dag hangen (namelijk de tijd)
        return day + '-' + month + '-' + year;//dd-mm-yyy
    }

    $scope.filter = {model: ""}; //zetten, zodat het goed in de repeat-scope komt

    $scope.meekijken = data.meekijken; //is het meekijkmodus?
    $scope.deletemode = {
        active: false,
        modelid: null,
        btn: null
    };
    $scope.parsedatetonldate = parsedatetonldate;
    //alle modellen behalve de huidige
    $scope.modellen = $.grep(data.modellen, function (m) {
        return m.id != data.huidige;
    });

    $scope.selected = {
        model: null,
        fordelete: null
    };

    $scope.startNewModel = function () {
        $uibModalInstance.close(false);
    };
    $scope.openModel = function () {
        //we sluiten het model en sturen het resultaat terug naar de mainController - loadmenu.result.then() vangt dit resultaat op.
        $uibModalInstance.close($scope.selected.model);

    };

    $scope.close = function () {
        $uibModalInstance.dismiss('cancel');
    };

    /**
     * Geklikt op regel met model - we selecteren. Als laden true is laden we ook (dubbelklik)
     * @param $event
     * @param model
     * @param laden
     */
    $scope.clickModel = function (model, laden) {
        //deletion ongedaan maken
        $scope.selected.fordelete = null;

        if (model.inDelete) {
            return; //skippen, want wordt verwijderd
        }
        if (laden) {
            //sluiten en gaan
            $uibModalInstance.close(model);
            return;
        }
        $scope.selected.model = model;
    }

    /*
     * Als er op een deleteknop in de actioncolumn gedrukt wordt
     * gaan we kijken of we de */
    $scope.deleteModel = function (model) {
        //er is op een deletebtn gedrukt
        //was er al op een deleteknop gedrukt?

        if ($scope.meekijken)
        {
            return; //no way
        }
        if ($scope.selected.fordelete === model) {
            //ja, deze kan weg
            model.inDelete = true; //zodat selectie niet doorgaat (en hide in dialoog)
            api.deleteModel(model.id).catch(function () {
                model.inDelete = false; //niet meer
            });
        }
        else {
            //nu voor delete
            $scope.selected.fordelete = model;
        }
    }

    $scope.doefilter = function(model)
    {
        //filter het model op het modelfilter
        //we splitten het modelfilter
        if (! ($scope.filter.model && $scope.filter.model.length))
        {
            return true;
        }
        for(let str of $scope.filter.model.toLowerCase().split(/\s+/))
        {
            if (
                model.titel.toLowerCase().indexOf(str) === -1
                &&
                ((! model.owner) || model.owner.toLowerCase().indexOf(str) === -1)
            )
            {
                //niet in titel en ook niet in eventuele owner
                return false;
            }
        }
        return true;
    }
}]);
