/**
 * Created by Lennaerd on 7-10-2015.
 *
 * algemene configuratie. De states staan in states/states.js
 */
dynalearn.config(['$locationProvider', '$translateProvider',
    function ($locationProvider, $translateProvider) {
        //$locationProvider.html5Mode(true);
        //we laden vertalingen in uit de locale-map tov dir met index
        $translateProvider.useStaticFilesLoader({
            prefix: 'app/locale/',
            suffix: '.json'
        }).useSanitizeValueStrategy('sanitizeParameters')
        //de beschikbare talen moet hier ook genoemd worden
        //we werken met bcp47: dus nl-NL, pt-BR etc
        //https://r12a.github.io/app-subtags/
            .registerAvailableLanguageKeys(['en', 'nl', 'de', 'fr', 'pt-BR','el'], {
            //omschrijven van taal met regio naar gewoon taal.
                'en-*': 'en',
                'nl-*': 'nl',
                'de-*': 'de',
                'fr-*': 'fr',
                'pt-*': 'pt-BR',  //voor nu de portugese als standaard, dus ook -->
                'pt': 'pt-BR',
                'el-*': 'el',
                "*": "en" //anders engels (dat is iets anders dan fallback - fallback is als een string mist in de juiste taal
            })
            .uniformLanguageTag('bcp47') //tags altijd nl-NL etc, hyphen en 2e deel upper
            .determinePreferredLanguage()
            .fallbackLanguage(['en', 'nl']);
        console.log("Taal: ", $translateProvider.preferredLanguage());
        moment.locale($translateProvider.preferredLanguage()); //de hierboven geregistreerde available languages moeten als moment/locale geladen zijn in index
        console.log("Moment locale geladen. Een uur geleden is: ", moment().subtract(1, 'hour').fromNow());
    }]);
