/**
 * Created by Lennaerd on 15-10-2015.
 *
 * maincontroller van het bouw- en simuleerscherm. Communiceert veel met de childcontrollers via de model-service
 * en hun exported interface in publicI (modelctrl, simctrl)
 * //we zijn bezig de termen slave en master kwijt te raken ivm historische connotatie. We gebruiken nu sub en primary. In het datamodel kan het helaas nog niet
 */

dynalearn.controller("cytocanvas",
    ['$scope', '$document', '$stateParams', '$location', '$timeout', 'sihwlog', 'publicI', 'api', 'elementService', 'model', 'cytoscape', '$translate', '$state', '$q', '$uibModal', 'sihwconfirm', 'erroralert', 'multilangfile',
        function ($scope, $document, $stateParams, $location, $timeout, sihwlog, publicI, api, elementService, model, cytoscape, $translate, $state, $q, $uibModal, sihwconfirm, erroralert, multilangfile) {

            let log = sihwlog.logLevel('debug');
            let insaveGraph = false; //1 keer uitvoeren

            log.debug('Build', $stateParams);

            $scope.model = model; //geeft veel info terug, wordt gebruikt in de view - werkt erg handig

            //$scope.dashboard wordt gezet als exposable api van dashboard-criteria
            $scope.simulateOn = false;
            $scope.chat = {
                uit: false,
                bericht: false,
                tekst: ""
            };
            $scope.devmodus = api.debug;
            $scope.video = {
                play: false,
                video: null,
                picker: false //de videopicker
            };

            var modelctrl = null; //publicI-interface
            var simctrl = null;
            let lastSimFull = false; //is er voor het laatste gestart met fullsim of stap? Van belang voor herstart bij wijzigen fastpath

            //als er is ingelogd en de public interfaces van onze subcontrollers er zijn kunnen we verder
            api.isLoggedIn().then(function (res) {
                if (!res) {
                    log.error('Niet ingelogd, geen toegang tot build');
                    $state.go('login', {
                        openAction: $stateParams.openAction,
                        openArg: $stateParams.openArg
                    });
                } else {
                    //door als de publieke interfaces er zijn
                    publicI.when(['modelctrl', 'simctrl']).then(init);
                }
            });


            function init() {
                if (!api.userdata) {
                    //snel wegwezen
                    $state.go('login', $stateParams); //met de oorspronkelijke params mee
                    return;
                }

                $scope.userdata = api.userdata;

                //we werken de projecten voor de projectswitch nog even handig uit
                $scope.switchprojecten = {};
                if (api.userdata && api.userdata.switchprojecten) {
                    for (let sp of api.userdata.switchprojecten) {
                        if (sp.project_id !== api.userdata.projectdata.id) {
                            ($scope.switchprojecten[sp.domein_naam] = $scope.switchprojecten[sp.domein_naam] || []).push(sp);
                        }
                    }
                }

                //we sturen een log
                model.directlogAction('init_app', $stateParams, 'app', 'app', true);

                /**
                 * canvas initialiseren
                 */
                //zet refs naar public interfaces van de subcontrollers. Die zijn er want deze init draait in de when
                modelctrl = publicI.i('modelctrl');
                /*SIMULATIEMODUS*/
                simctrl = publicI.i('simctrl');

                //bij het begin alles al resetten, niet via newModel, want dat geeft gedoe met back
                resetState();
                model.new();

                //verbonden?
                $scope.connected = api.connected(); //voor nu
                $scope.$on('SpinJS2.connectChanged', function () {
                    $scope.connected = api.connected();
                    log.debug(`Cytocanvas: we zijn ${$scope.connected ? 'weer' : 'niet meer'} verbonden`);
                });

                //19-8-2022: opslaan uitgeschakeld. UI knop is ook weg, het is altijd hori
                // $scope.resizeDirection = localStorage['resizeDirection'] || 'hori';//default;
                $scope.resizeDirection = 'hori'; //dus nu de standaard
                setResizeEvents();
                updateWindowSplit();


                $scope.$on('model.contentChanged', function () {
                    toggleSimulationMode(false);
                });

                $scope.$on('model.propsChanged', function (_e, data) {
                    toggleSimulationMode(false);
                });

                //we luisteren naar simulatieberichten, want coordineren de simulatie via de leider
                $scope.$on('api.notify.simulatiebericht', function (_e, data) {
                    onSimulatiebericht(data);
                });
                //openacties
                switch ($stateParams.openAction) {
                    case "openModel":  //open een model op id, met normale toestemmingen
                    case "fromAdmin": //open een model vanuit admin met een speciale toegangskey
                        if ($stateParams.openArg) {
                            //we laden het als template, backend pikt dat wel hopelijk
                            model.newUitTemplate($stateParams.openArg, $stateParams.openAction === 'fromAdmin');
                        }
                        break;
                    case "saveMaterial":
                        //sla 1 of meer modellen op in deze account uit materiaal en open de eerste
                        //openArg is een versleutelde want al geautoriseerde set modellen
                        if ($stateParams.openArg) {
                            //uitpakken van een btoa json encode
                            let arg;
                            try {
                                arg = JSON.parse(atob($stateParams.openArg));
                            } catch (e) {
                                log.error(e);
                            }
                            if (!(arg && arg.models)) {
                                return;
                            }

                            return sihwconfirm(__('MATERIAAL.IMPORTEREN'), __('MATERIAAL.IMPORTAANTAL', {aantalmodellen: arg.models.length}),
                                __('ALGEMEEN.JA'), __('ALGEMEEN.NEE')
                            ).then(confirm => {
                                if (confirm) {
                                    return api.send("model", "saveMaterial", {
                                        key: arg.auth,
                                        models: arg.models
                                    }).then(res => {
                                        if (res.opgeslagen && res.opgeslagen.length) {
                                            return sihwconfirm(__('MATERIAAL.IMPORTEREN'), __(res.opgeslagen.length === 1 ? 'MATERIAAL.GEIMPORTEERD_EENMODEL' : 'MATERIAAL.GEIMPORTEERD_MEERDERE'),
                                                __('BTN_CONFIRM'), false).then(() => {
                                                return model.load(res.opgeslagen[0]);
                                            });
                                        }
                                    });
                                }
                            }).catch(erroralert);
                        }
                        break;
                    default:
                        standaardStartup();
                }
            }

            /**
             * Als er niets speciaals aan de hand is, starten we gewoon op
             */
            function standaardStartup() {
                let project = api.userdata.projectdata;
                //1. is er een debug mode?
                if (api.debug && api.quickLoad) {
                    //we gaan een model laden in debug
                    //quickload is of gewoon een model-id, of een hash met usernaam:modelid
                    let modelid = angular.isObject(api.quickLoad.model) ? api.quickLoad.model[api.userdata.naam] : api.quickLoad.model;
                    if (modelid) {
                        log.debug(`Debug load model ${modelid}`);
                        model.load(modelid).then(function () {
                            if (api.quickLoad.startSim) {
                                setTimeout(simulateFullOrStop, 1000);
                            }
                        });
                        return; //klaar
                    }
                }

                //popup?
                if (project.popup) {
                    //we openen de popup en handelen wat zaken erin af
                    $uibModal.open({
                        backdrop: 'static',
                        keyboard: false,
                        windowClass: 'startuppopup',
                        templateUrl: 'app/states/canvas/startuppopup.html',
                        controller: ['$scope', '$uibModalInstance', function ($dlgscope, $dlg) {
                            $dlgscope.project = project;
                            $dlgscope.userdata = api.userdata;

                            $dlgscope.startvideo = function () {
                                $dlg.close();
                                //we starten de video, en daarachter openen we gewoon een default ofzo
                                //we doen altijd het standaardmodel, tenzij je precies 1 model hebt. Dan pakket we die
                                if (api.userdata.nummodellen && api.userdata.enkelmodel)
                                {
                                    model.load(api.userdata.enkelmodel); //dat telt dus alleen bij init van de applicatie, maar na een reload komt de userdata toch opnieuw
                                }
                                else
                                {
                                    //geen of meerdere modellen. De normale startprocedure
                                    $dlgscope.standaardmodel(); //zelfde als de start
                                    //de nieuw-knop kan nog altijd een leeg model maken, tenzij forceer_start in het project aan astaan
                                }
                                $scope.initVideo(); //mainscope
                            }

                            /**
                             * Open de modeldialoog, of gewoon het model als er maar 1 is
                             */
                            $dlgscope.openmodel = function () {
                                $dlg.close();
                                if (api.userdata.enkelmodel) {
                                    model.load(api.userdata.enkelmodel);
                                } else {
                                    //meerdere
                                    //we laden eerst het standaardmodel
                                    checkOpenStandaardmodel().then(_ => {
                                        $scope.openLoadMenu(false); //main scope
                                    });
                                }
                            }

                            /**
                             * Expliciet opnieuw openen standaardmodel
                             */
                            $dlgscope.standaardmodel = function () {
                                $dlg.close();
                                checkOpenStandaardmodel();
                            }

                        }]
                    });
                } else {
                    checkOpenStandaardmodel();
                    // video
                    $scope.initVideo();
                }

                //tijdelijk
                if (api.flag('dashboard') && api.flag('opendashboard')) {
                    $timeout(() => {
                        log.warn(`Open dashboard vanwege config`, $scope.dashboard2);
                        $scope.cancelVideo();
                        $scope.dashboard2.open();
                    }, 600);
                }
            }

            /**
             * Controleer of er een standaardmodel is dat open moet.
             * Als dit wordt aangeroepen dan doen we dit altijd
             */
            async function checkOpenStandaardmodel() {
                if (api.userdata.projectdata.standaardmodel) {
                    //goed, we openen een norm of een model
                    if (api.userdata.projectdata.standaardmodel_type === "norm") {
                        log.debug(`Open standaard norm`);
                        return model.newMetNormmodel(api.userdata.projectdata.standaardmodel);
                    } else if (api.userdata.projectdata.standaardmodel_type === "template") {
                        log.debug(`Open standaard template`);
                        return model.newUitTemplate(api.userdata.projectdata.standaardmodel);
                    } else {
                        log.warn(`Standaardmodel is norm noch template`);
                    }
                }
            }

            /**
             * draai het simulatiecanvas van horizontaal naar verticaal en viceversa.
             * @param {string} [forcedDirection] als we een specifieke kant willen
             * @deprecated Uitgeschakeld in UI
             */
            function flipSimCanvas(forcedDirection) {
                //bij een flip moeten we zorgen dat alles goed staat

                //reset de elementen (resize wijzigingen worden toegevoegd aan de style attr)

                var nieuwerichting = forcedDirection || ($scope.resizeDirection === 'hori' ? 'verti' : 'hori');
                if ($scope.resizeDirection === nieuwerichting) {
                    return; //niets te doen
                }
                $scope.resizeDirection = nieuwerichting;
                localStorage['resizeDirection'] = $scope.resizeDirection;
                updateWindowSplit();
                simctrl.doLayout(); //opnieuw laten tekenen
            }

            $scope.internSetSimdirection = flipSimCanvas; //voor subscopes (simview)

            /**
             * Vanuit de ui: flip de resizeDirection en stuur dat evt naar volgers
             * UITGESCHAKELD IN UI (19-9-2022)
             */
            $scope.flipSimCanvas = function () {
                if (model.werkmodus === 'volg') {
                    //bericht naar de leider
                    simulatiebericht('flipSimCanvas'); //die doet het wel
                    return;
                }
                //uitvoeren
                flipSimCanvas();
                //en verkort opsturen
                if (model.werkmodus === 'leid') {
                    simulatiebericht('flipSimCanvas', {richting: $scope.resizeDirection});
                }
            };


            /**
             * maak het hoofdcanvas en simulatiecanvas resizable.
             * horizontaal (links naar rechts)
             */
            function setResizeEvents() {
                var cyholder = $('#cytocanvasholder'),
                    simholder = $('#simcanvasholder'),
                    draghandle = $('#splitter'), //de splitter in de simcanvasholder
                    container = $('#canvasholder'),
                    simcss = null,
                    cytocss = null,
                    containerW, containerH; //worden bij mousedown gezet
                var isDragging = false;

                var mousestart, //positie van de klik,
                    dragstart; //formaat van het simwindow in de juiste richting

                //helperfuncties
                function eventPos(e) {
                    //helper, geef de x en y van een event terug
                    if (e.originalEvent && e.originalEvent.changedTouches) {
                        return {
                            x: e.originalEvent.changedTouches[0].pageX,
                            y: e.originalEvent.changedTouches[0].pageY
                        };
                    } else {
                        return {
                            x: e.pageX,
                            y: e.pageY
                        }
                    }
                }

                //afhandelen starten van draggen met mouse of touch
                function startHandleDrag(e) {
                    var ePos = eventPos(e);
                    if ($scope.resizeDirection == 'hori') {
                        mousestart = ePos.x;
                        dragstart = simholder.width();
                    } else {
                        mousestart = ePos.y;
                        dragstart = simholder.height();
                    }
                    containerW = container.width();
                    containerH = container.height();
                    //zet de scopevars
                    isDragging = true;
                    e.preventDefault();
                }

                //afhandelen stoppen van draggen met mouse of touch
                function endHandleDrag() {
                    if (isDragging) {
                        isDragging = false;
                        resizeCanvasses(); //alleen als we aan het draggen waren
                        //en bewaar de simcss en cytocss in localstorage
                        if (simcss && cytocss) {
                            //ze zijn gezet
                            localStorage["splitwindow_" + $scope.resizeDirection] = JSON.stringify({
                                sim: simcss,
                                cyto: cytocss
                            });
                        }
                    }
                }

                //afhandelen van de drag
                function handleDrag(e) {
                    if (!isDragging) return;
                    var ePos = eventPos(e);
                    var cysize, simsize, mousenu;
                    if ($scope.resizeDirection == "hori") {
                        //dragrichting is horizontaal als de X kleiner wordt, wordt het simscherm groter
                        mousenu = ePos.x;
                        simsize = dragstart + (mousestart - mousenu); //als mousenu afneemt, wordt de sim breder
                        if (simsize < 80) simsize = 80;
                        if (simsize > (containerW - 80)) simsize = containerW - 80;
                        //maar we doen in percentages zodat het scherm kan resizen
                        simsize = simsize / containerW * 100;
                        cysize = 100 - simsize; //de rest is voor het model
                        //we zetten geen breedtes, maar left en right
                        simcss = {
                            left: cysize + '%',
                            right: 0,
                            top: 0,
                            bottom: 0
                        };
                        cytocss = {
                            left: 0,
                            right: simsize + '%',
                            top: 0,
                            bottom: 0
                        };
                    } else {
                        //dragrichting is verticaal als de Y groter wordt, wordt het simscherm groter

                        mousenu = ePos.y;
                        simsize = dragstart + mousenu - mousestart; //als mousenu toeneemt, wordt de sim hoger
                        if (simsize < 200) simsize = 200;
                        if (simsize > (containerH - 200)) simsize = containerH - 200;
                        //percentages
                        simsize = simsize / containerH * 100;
                        cysize = 100 - simsize; //de rest is voor het model

                        //we zetten geen hoogtes, maar bottom en top etc
                        simcss = {
                            top: 0,
                            bottom: cysize + '%',
                            left: 0,
                            right: 0
                        };
                        cytocss = {
                            top: simsize + '%',
                            bottom: 0,
                            left: 0,
                            right: 0
                        };
                    }

                    //en zet de css
                    simholder.css(simcss);
                    cyholder.css(cytocss);
                }

                //events voor mouse
                draghandle.mousedown(startHandleDrag);
                $(document).mousemove(handleDrag).mouseup(endHandleDrag);

                //touch
                draghandle.on('touchstart', startHandleDrag);
                draghandle.on('touchmove', handleDrag);
                draghandle.on('touchend', endHandleDrag);

                resizeCanvasses();
            }

            /**
             * resize de canvassen
             */
            function resizeCanvasses() {
                //dat doen we bij de childcontrollers
                modelctrl.resizeCanvas();
                simctrl.resizeCanvas();
            }

            /**
             * Zorg dat de verhouding tussen sim- en modelscherm klopt
             */
            function updateWindowSplit() {
                var sim = $('#simcanvasholder');
                var cyto = $('#cytocanvasholder');

                sim.attr('style', '');
                cyto.attr('style', '');
                $('#splitter').attr('style', '');
                //van richting wisselen == andere css cls

                if ($scope.simulateOn) {
                    //we moeten zorgen dat de split te zien is
                    sim.show();

                    // = JSON.stringify({sim: simcss, cyto: cytocss});
                    var prevcss;
                    if ($scope.resizeDirection === "hori") {
                        //hebben we nog formaten in localstorage?
                        prevcss = localStorage["splitwindow_hori"];
                        if (prevcss) {
                            prevcss = JSON.parse(prevcss);
                        } else {
                            prevcss = {
                                sim: {
                                    top: 0,
                                    bottom: 0,
                                    right: 0,
                                    left: '80%'
                                },
                                cyto: {
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: '20%'
                                }
                            };
                        }
                        sim.css(prevcss.sim);
                        cyto.css(prevcss.cyto);
                    } else {
                        //hebben we nog formaten in localstorage?
                        prevcss = localStorage["splitwindow_verti"];
                        if (prevcss) {
                            prevcss = JSON.parse(prevcss);
                        } else {
                            prevcss = {
                                sim: {
                                    top: 0,
                                    bottom: '70%',
                                    left: 0,
                                    right: 0
                                },
                                cyto: {
                                    top: '30%',
                                    bottom: 0,
                                    left: 0,
                                    right: 0
                                }
                            };
                        }
                        sim.css(prevcss.sim);
                        cyto.css(prevcss.cyto);
                    }
                } else {
                    sim.hide();
                    cyto.css(    //neem de volledige ruimte
                        {
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0
                        }
                    )
                }
                resizeCanvasses();
            }

            function __() {
                return $translate.instant.apply($translate, arguments);
            }

            /***************************** STATE *****************************************/
            /**
             * Reset alles naar het begin
             */
            function resetState() {
                toggleSimulationMode(false, false, true);
                $scope.chat.uit = $scope.chat.bericht = false;
                $scope.chat.tekst = "";
                modelctrl.resetState();
            }

            /***************************** UNDO / REDO ***********************************/

            //canUndo / canRedo altijd via model en onafhankelijk van samenwerkmodus
            $scope.canUndo = function () {
                return model.canUndo();
            };

            $scope.canRedo = function () {
                return model.canRedo();
            };

            /**
             * Undo-actie laten van aan de modelview-interface over
             */
            $scope.undo = function () {
                modelctrl.undo();
            };

            $scope.redo = function () {
                modelctrl.redo();
            };

            /***************************** MODEL ****************************************/
            /**
             * We beginnen een nieuw model.
             * We resetten dus de toestand en
             * Leeg de graaf en zet de standaardsettings
             * Begin een nieuw model, kijk of de oude moet worden opgeslagen
             *
             * @param {string} [templateid] id van te laden model dat als template dient
             * @param {boolean} [alsNorm] als gegeven en true: probeer dit model als normmodel te laden
             *
             * return Promise met true of false van succesvolle reset
             */
            function newModel(templateid, alsNorm) {

                //zet de standaardsettings, aan het begin of om de graaf te legen
                //vanwege alle extras doen we onze eigen deleteNode-code, die regelt qtips enzo
                //oude opslaan (automatisch)
                return checkSaveModel().then(function (gelukt) {
                    resetState();
                    if (gelukt) {
                        if (!templateid) {
                            //gewoon nieuw
                            return model.new();
                        }
                        if (alsNorm) {
                            return model.newMetNormmodel(templateid);
                        } else {
                            return model.newUitTemplate(templateid); //als template
                        }
                    } else {
                        return false; //gestopt wegens opslaanfout
                    }
                });
            }

            /**
             * Regel het (controleren op) opslaan van het model
             * levert een promise op die resolvet met true/false of het gelukt is
             * @param {boolean} [force] -- expliciet opslaan, ook als we geen wijziging / id hebben
             * @param {boolean} [irrelevant] -- als true wordt dit niet als een relevante wijziging gezien
             * @returns {$q} promise true/false succes van opslaan
             */
            //todo (versie 2024): helemaal onderbrengen bij wie er over gaat
            function checkSaveModel(force, irrelevant) {
                //img sturen we als generator mee naar save, wordt dan uitgevoerd na opslaan
                return model.save(modelctrl.modelData(), !irrelevant, true, force, function () {
                    return modelctrl.graphJpg();
                }) //direct opslaan, en foceer nieuw als dat is meegestuurd
            }

            /**
             * Handler van saveknop, deze forceert een save
             * @returns {$q}
             */
            function saveGraph() {
                if (insaveGraph) {
                    return; //laat maar
                }

                insaveGraph = true;
                $scope.cancelVideo();
                //pas over een tijdje weer
                setTimeout(() => {
                    insaveGraph = false
                }, 5000);
                //we vragen een naam als hij nog ongesaved is
                if (!model.isSaved()) {
                    return model.propertiesDialog("MODELPROPS_NIEUW")
                        .then(function (props) {
                            if (props) {
                                log.debug(`Aanroep checkSaveModel`);
                                return checkSaveModel(true).then(success => {
                                    log.debug(`saveGraph: terug van checkSaveModel`, success, model.isSaved());
                                    if ((!success) && (!model.isSaved())) {
                                        //nog steeds niet opgeslagen
                                        sihwconfirm($translate.instant("ERROR.TITEL"), $translate.instant("ERROR.SAVE_MODEL_NOT_SAVED_YET"), $translate.instant("ALGEMEEN.OK"), false);
                                    }
                                    return success;
                                });
                            } else { //weigeren bij cancel of lege string
                                return false;
                            }
                        });
                } else {
                    return checkSaveModel(); //we forceren geen opslaan, maar doen het wel altijd direct
                }
            }

            $scope.saveGraph = saveGraph;

            /**
             * Sla het model in huidige toestand op, en ga verder in een kopie
             * Dit is feitelijk: opslaan oude model - weghalen id - vragen nieuwe naam - opslaan
             * Undo blijft gewoon doorlopen. Je kan dus 3x undo, die kopie opslaan, dan weer redo
             * @returns {$q.promise}
             */
            function copyModel() {
                //sla het huidige model nog een keer op
                $scope.cancelVideo();
                checkSaveModel().then(function (result) {
                    if (!result) {
                        log.error("fout bij opslaan - stop kopie");
                        return;
                    }
                    //het oude model is opgeslagen
                    //we vragen om de nieuwe naam
                    //zit er een cijfer in het model?
                    var m, titelsuggestie;
                    if (m = $scope.model.titel.match(/^(.*) \- (\d)+$/)) {
                        titelsuggestie = m[1] + " - " + (parseInt(m[2]) + 1);
                    } else {
                        titelsuggestie = $scope.model.titel + " - 2";
                    }
                    model.propertiesDialog('MODELPROPS_COPY',
                        {
                            titel: titelsuggestie
                        },
                        true //NIET toepassen op het model
                    ).then(function (props) {
                        if (props) {
                            //er is niet geannuleerd, we zetten het model op "nieuw"
                            //de actionbuffer is leeg, want opgeslagen. Wij zeggen dat we gekopieerd hebben
                            model.logaction('copymodel', 'model', null, 'model', {
                                from: $scope.model.id,
                                fromname: $scope.model.titel
                            }, false);
                            model.ontkoppel();
                            model.setProperties(props); //en wijzig de teruggegeven properties
                            checkSaveModel(true) //opnieuw opslaan, onder nieuwe naam
                                .then(success => {
                                    if (!success) {
                                        sihwconfirm($translate.instant("ERROR.TITEL"), $translate.instant("ERROR.SAVE_MODEL_NOT_SAVED_YET"), $translate.instant("ALGEMEEN.OK"), false);
                                    }
                                });
                        }
                    });
                });
            }

            $scope.copyModel = copyModel;

            /******************** Switch project ***************************/
            $scope.magSwitchProject = function () {
                return api.userdata && api.userdata.switchprojecten; //be stuurt dit alleen terug als het er meer dan 1 is
            }

            $scope.projectSwitchMetDomein = function () {
                return Object.keys(this.switchprojecten).length > 1;
            }

            /**
             * Toggle de projectlijst
             */
            let toggleSwitchProjectTimer = null;
            $scope.toggleSwitchProject = function () {
                if (toggleSwitchProjectTimer) {
                    $timeout.cancel(toggleSwitchProjectTimer);
                    toggleSwitchProjectTimer = null;
                }
                $scope.switchProjectOpen = !$scope.switchProjectOpen; //weg ermee
            }

            $scope.switchProjectLijstActief = function () {
                if (toggleSwitchProjectTimer) {
                    log.debug(`Kill switchproject timer`);
                    $timeout.cancel(toggleSwitchProjectTimer);
                    toggleSwitchProjectTimer = null;
                }
            }

            $scope.switchProjectLijstInactief = function () {
                log.debug(`Reset switchproject timer`);
                if (toggleSwitchProjectTimer) {
                    $timeout.cancel(toggleSwitchProjectTimer);
                    toggleSwitchProjectTimer = null;
                }
                toggleSwitchProjectTimer = $timeout(_ => {
                    //even wachten met sluiten dus
                    log.debug(`We sluiten de lijst`);
                    $scope.switchProjectOpen = false;
                    toggleSwitchProjectTimer = null;
                }, 5000);
            }

            /**
             * Voer het switchen van het project uit. Dat is feitelijk een speciaal soort logout
             * @param project_id
             */
            $scope.switchProject = function (project_id) {
                //menu dicht
                if (toggleSwitchProjectTimer) {
                    $timeout.cancel(toggleSwitchProjectTimer);
                    toggleSwitchProjectTimer = null;
                }
                $scope.switchProjectOpen = false;
                checkSaveModel().then(res => {
                    model.new(true); //wis de modeldata  voor het geval reload niet werkt
                    api.switchProject(project_id).then(res => {
                        if (res) {
                            log.debug(`SWITCH`);
                            $state.reload();
                            //gelukt
                            //harde reload nadat auth is bijgewerkt
                            // location.reload();
                        } else {
                            //mislukt. We doen een logout
                            log.warn(`Switch project mislukt`);
                            $scope.logout();
                        }
                    });
                });
            }

            /**
             * Logout: opslaan, uitloggen en reloaden
             */
            $scope.logout = function () {
                checkSaveModel().then(function (res) {
                    if (res) {
                        //als gelukt, dan pas uitloggen
                        model.new(true) //wis de modeldata  voor het geval reload niet werkt
                            .then(_ => {
                                api.logout();
                                $state.go('login');
                                location.reload(); //en keihard reloaden
                            });
                    }
                });
            };

            /**
             * New model vanuit scope. Dat kan ook betekenen dat er een template geladen kan worden
             */
            $scope.openNewMenu = function () {
                //is dit forceerstartup?
                if (api.userdata.projectdata.forceer_start)
                {
                    //een new model, daarna een harde statereload
                    //zodat we weer in de loop van opstarten komen
                    newModel().then(res => {
                        if (res)
                        {
                            //we willen de userdata bijgewerkt hebben
                            log.debug(`Reload location`);
                            location.reload(); //zodat userdata ook opnieuw is
                            // $state.reload();
                        }
                    });
                    return;
                }
                ///getAllTemplates: geeft templates en normmodellen terug
                api.getAllTemplates().then(function (templates) {
                    $scope.cancelVideo();
                    if (!templates.length) {
                        //geen templates: gewoon model openen
                        newModel().then(function (res) {
                            if (res) {
                                model.logaction('newmodel', 'model', null, 'model', {modelleerniveau: model.effectief_modelleerniveau}, false); //pas als hij helemaal klaar is, anders nog in oude model

                                //video mag op leeg scherm
                                // $scope.initVideo();  //geen video bij nieuw model
                            }
                        });
                    } else {

                        $uibModal.open({
                            animation: true,
                            templateUrl: 'app/states/templatepicker/templatepicker.html',
                            controller: 'templatePickerController',
                            backdrop: 'static',
                            resolve: {
                                data: {
                                    templates: templates,
                                    huidige: $scope.model.id
                                }
                            }
                        }).result.then(function (template) {
                            //nieuw dus
                            newModel(template.id, template.norm).then(function (res) {
                                if (res) {
                                    model.logaction('newmodel', 'model', null, 'model', {template: template, modelleerniveau: model.effectief_modelleerniveau}, false);
                                    if (!(template && template.id)) {
                                        // $scope.initVideo(); //dan weer de video
                                    }
                                }
                            });
                        });
                    }
                });
            };

            /**
             * modal menu met een lijst met alle modellen. hieruit kan 1 geselecteerd worden om te laden.
             * @package {boolean} meekijken=false Open voor meekijken
             * */
            $scope.openLoadMenu = async function (meekijken) {
                //let op async en await:niet waar $q digestion gedaan moet worden
                //alle modellen voor deze gebruiker ophalen.
                $scope.cancelVideo();
                let models = await api.getAllModels(meekijken);
                var loadmenu = $uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    templateUrl: 'app/states/modalloadmenu/modalloadmenu.html',
                    controller: 'modalLoadMenuController',
                    resolve: {
                        data: {
                            modellen: models,
                            huidige: $scope.model.id,
                            meekijken: meekijken
                        }
                    }
                });
                /**
                 * wat krijgen we terug als we het modal loadmenu hebben gesloten?
                 */
                try {
                    let selecteditem = await loadmenu.result;
                    //selectedItem is false als we een nieuw willen starten, anders een object met modelmetadata
                    //hier gaan we dus opnieuw beginnen
                    //reset het hele model hier:
                    if ((await newModel()) && selecteditem) {
                        //het oude model is zonodig opgeslagen en de boel is gereset
                        //ophalen maar
                        if (meekijken) {
                            //opzetten als samenwerken via een aparte call
                            try {
                                let subAndPrimary = await api.gaMeekijken(selecteditem.id);
                                //gelukt! sub id is het nieuwe model
                                await model.load(subAndPrimary.slave); //laadt het submodel, dat zichzelf kopieert van de master
                                //en registreer de samenwerking op master-id
                                model.notifyId = subAndPrimary.master;
                                await model.registreerSamenwerken();
                            } catch (_e) {
                                log.warn("Fout bij meekijken", _e);
                                //lekker doorvallen
                            }
                        } else {
                            model.load(selecteditem.id); //die regelt het verder
                        }
                    }
                } catch (_e) {
                    if (_e !== 'cancel' && _e !== 'escape key press') {
                        log.warn(_e);
                    }
                }
            };

            /**
             * Handler van dashboard on-meekijken: open een model voor meekijken
             * @param meekijkModel
             */
            $scope.openMeekijken = function (meekijkModel) {
                if (! api.userdata?.meekijker)
                {
                    return; //mag niet
                }
                log.debug(`Canvas: openMeekijken`, meekijkModel);
                //async, maar we gebruiken maar de ng1 $q
                $scope.dashboard.close(); //deze éérst dicht
                $scope.dashboard2.close(); //en deze ook
                if (meekijkModel) {
                    return newModel().then(_ => {
                        return api.gaMeekijken(meekijkModel)
                    }).then(
                        primaryAndSecondary => {
                            return model.load(primaryAndSecondary.slave).then(_ => {
                                model.notifyId = primaryAndSecondary.master;
                                return model.registreerSamenwerken();
                            });
                        }).catch(_e => {
                        log.warn("Fout bij meekijken vanuit dashboard", _e);
                    });
                }
            }

            /**
             * Handler van change name button in view
             */
            function openChangeName() {
                $scope.cancelVideo();
                //als we niet opgeslagen zijn, is dit gewoon de opslaan-functionaliteit. Die vraag ook om een naam
                if (!$scope.model.id) {
                    return saveGraph();
                }
                var oudetitel = model.titel;
                model.propertiesDialog("MODELPROPS_WIJZIG").then(function (props) {
                    if (props) {
                        model.logaction('modifymodel', 'model', null, 'model', {
                            oldname: oudetitel,
                            name: model.titel //aangepast
                        }, false);
                        //en opslaan
                        checkSaveModel();
                    }
                });
            }

            $scope.openChangeName = openChangeName;

            /**
             * Check of samenwerken mag: het moet aanstaan of het model moet al aan het samenwerken zijn
             * @returns {null|*}
             */
            $scope.magSamenwerken = function () {
                return (api.userdata && api.userdata.projectdata.samenwerken) ||
                    model.samenwerking !== 'single';
            };
            $scope.openSamenwerken = function () {
                //open de samenwerkdialoog, moet eerst opslaan als dit geen nieuw model is
                // api.actionStats(model.id); //test
                if ((!api.userdata.projectdata.samenwerken) && model.samenwerking === 'single') {
                    return;
                }
                $scope.cancelVideo();
                toggleSimulationMode(false); //weg met simulatie
                checkSaveModel(false).then(function (gelukt) {
                    if (gelukt) {
                        //opgeslagen, door naar de dialoog
                        //doen we hier nieuwe stijl met een inline controller
                        samenwerkdialoog(); //onderaan
                    }
                });
            };

            /**
             * Heeft deze user bepaalde flags in zijn userdata?
             * @param {'meekijker','dashboard1','dashboard2'} flag
             */
            $scope.heeftFlag = function (flag) {
                return api.userdata && api.userdata[flag];
            };

            /**
             * Scope: hebben we een leeg model?
             * @return {boolean}
             */
            $scope.emptyModel = function () {
                return (!modelctrl) || modelctrl.emptyModel();
            }

            $scope.debugnormtelling = function() {
                log.debug(model.norm.teller);
            }

            ////chat

            $scope.magChat = function () {
                return api?.userdata?.projectdata?.chat === 'altijd' || (api?.userdata?.projectdata?.chat === 'samenwerken' && model?.samenwerking !== 'single');
            }
            /**
             * Open of sluit de chat
             */
            $scope.clickChat = function () {
                if ($scope.magChat()) {
                    $scope.chat.uit = !$scope.chat.uit;
                    $scope.chat.bericht = false; //hoe dan ook weer weg
                    if ($scope.chat.uit) {
                        updateChatGelezen();
                        scrollChat();
                    }
                }
            }

            /**
             * Geef de juiste weergave van het tijdstip
             * @param m
             * @returns {string}
             */
            $scope.chatMoment = function (m) {
                //nooit een tijd in de toekomst. Dat kan als er wat tijdverschil is tussen de klokken op het be en fe.
                let mm = moment(m);
                let nu = moment();
                if (mm.isAfter(nu)) {
                    mm = nu;
                }
                return mm.fromNow();
            }

            $scope.chatsend = function () {
                log.debug($scope.chat.tekst);
                if ($scope.model?.isSaved() && $scope.chat.tekst?.length) {
                    api.chatSend($scope.chat.tekst, $scope.model);
                    $scope.chat.tekst = "";
                }
            }

            $scope.chatsendEnter = function ($event) {
                if ($event.keyCode === 13) {
                    log.debug($event);
                    $scope.chatsend();
                    $event.preventDefault();
                }
            }

            //chat
            $scope.$on('api.notify.chat', function (_e, data) {
                log.debug(`CHAT`, data);
                if ($scope.magChat() && data.model === $scope.model?.notifyId) {
                    log.debug(`toevoegen aan chat`);
                    $scope.model.chat.push(data);
                    $scope.chat.bericht = true; //voor het kleurtje
                    if ($scope.chat.uit) {
                        updateChatGelezen(); //laat maar weten dat we het gelezen hebben
                    }
                    scrollChat(); //en scrollen
                } else {
                    log.debug(`Chat mag niet. Data model ${data.model} notifyId ${$scope.model?.notifyId}`);
                }
            });
            $scope.$on('model.changed', function () {
                //kijk of er nieuwe chat bij zit
                log.debug(`CHECK CHAT IN NEW MODEL`, $scope.model?.chat);
                $scope.chat.bericht = $scope.model?.chat.some(c => c.nieuw);
                if ($scope.chat.uit) {
                    updateChatGelezen();
                }
            });

            /**
             * Stuur naar achteren dat alle chats gelezen zijn, dus de nieuwe
             */
            function updateChatGelezen() {
                if (!$scope.model?.chat) {
                    return;
                }
                let updaten = [];
                for (let c of $scope.model.chat.filter(c => c.nieuw)) {
                    updaten.push(c.id);
                    c.nieuw = false;
                }
                $scope.chat.bericht = false; //alles is bij
                if (updaten.length) {
                    api.chatGelezen(updaten); //stuur maar naar achteren
                }
            }

            /**
             * Helper: scroll even de chatter naar beneden
             */
            function scrollChat() {
                if (!$scope.chat.uit) {
                    return; //niets te doen
                }
                $timeout(function () {
                    let chatlog = $('#chatlog');
                    if (chatlog.length) {
                        log.debug(chatlog);
                        log.debug(chatlog[0].scrollHeight);
                        chatlog.scrollTop(chatlog[0].scrollHeight);
                    }
                }, 50);
            }

            $(document).ready(function () {
                $timeout(scrollChat, 1000);
            }); //even aan het begin

            /***** CONTROLE VAN DE SIMULATIE *********
             * Scopefuncties en helpers bij het runnen van de simctrl
             */

            /**
             * Start of stop de simulatiemodus: toon/hide het simulatiescherm en begin de simulatie
             * @param {boolean} [newmode] simulatie aan of uit. Standaard is toggle
             * @param {boolean} [fullsim] bij starten simulatie: voer hem meteen helemaal uit ja / nee
             * @param {boolean} [skipSamenwerking] als true, dan *bij uitzetten* geen simulatiebericht naar de leider / volgers
             * @param {boolean} [force] Voer de simulatie opnieuw uit, ook als volgens ons de simulatiemode al goed staat
             */
            function toggleSimulationMode(newmode, fullsim, skipSamenwerking, force) {
                if (!simctrl) {
                    return; //angular zit aan de knop, nog voor de ready
                }

                if (typeof (newmode) === "undefined") {
                    newmode = (!$scope.simulateOn);
                }
                if (newmode === $scope.simulateOn && (!force)) {
                    return; //niets te doen toch
                }
                if (!newmode) {//uitzetten

                    $scope.simulateOn = false;//simulatiemodus uitzetten
                    simctrl.reset(skipSamenwerking);
                    modelctrl.stopSimulatie();
                    updateWindowSplit();
                } else {//aanzetten
                    $scope.simulateOn = true;//simulatiemodus aanzetten
                    //als we de simualatiemodus aanzetten dan openen we automatisch het simulatiescherm. via ng-show op simulateON
                    //simulatie starten kan alleen bij opgeslagen modellen, dus dat moet eerst geregeld zijn. We forceren het

                    checkSaveModel(true).then(function () {
                        api.simulate($scope.model.id, force).then(function (res) {
                            if (!res.result) {
                                //$scope.simerror.show = true;
                                //$scope.simerror.message = res.message + '. Probeer opnieuw.';
                                $scope.simulateOn = false;
                                //TODO: foutmelding
                                log.warn('Fout bij genereren simulatie ', res);
                                return false;
                            }
                            updateWindowSplit();
                            //meld de subcontrols het een en ander
                            modelctrl.startSimulatie();
                            simctrl.startSimulatie(res.simulatie, fullsim);
                            lastSimFull = !!fullsim; //onthouden
                        });
                    });
                }
            }

            /**
             * Interface: doe een volledige simulatie als die er nog niet is, of haal de simulatie juist weg als hij er wel is
             * @param [volger] id van volger die dit geiniteerd heeft
             */
            function simulateFullOrStop(volger) {
                log.debug(`simulateFullOrStop`);
                if (model.werkmodus === 'volg') {
                    simulatiebericht('fullOrStop');
                    return;
                }
                if ($scope.simulateOn) {
                    //weg
                    model.directlogAction('stop_sim', {}, 'model', 'model', false, volger); //moet direct, omdat er geen opslag is
                    toggleSimulationMode(false);
                } else {
                    model.directlogAction('start_sim', {
                        fullsim: true
                    }, 'model', 'model', false, volger); //moet direct, omdat er niet per se opslag is
                    toggleSimulationMode(true, true); //deze doet ook opslaan
                }
            }

            $scope.simulateFullOrStop = simulateFullOrStop;

            /**
             * Scopefunctie: doe een volgende stap in de simulatie
             */
            function simulateNextStep(volger) {
                if (model.werkmodus === 'volg') {
                    simulatiebericht('nextstep');
                    return;
                }
                if (!$scope.simulateOn) {
                    //dan starten we hem en dat was het
                    model.directlogAction('start_sim', {
                        fullsim: false
                    }, 'model', 'model', false, volger); //moet direct, omdat er niet per se opslag is
                    toggleSimulationMode(true, false);
                } else {
                    //anders: uitvoeren
                    model.directlogAction('sim_nextstep', {}, 'model', 'model', false, volger); //moet direct, omdat er niet per se opslag is
                    simctrl.nextStep();
                }
            }

            $scope.simulateNextStep = simulateNextStep;

            //en voor de scoping uitleesbaar:
            $scope.getNextSimStep = function () {
                return simctrl && simctrl.getNextSimStep ? simctrl.getNextSimStep() : "";
            }

            /**
             * Fit de states in de simulatiemodus, expliciete knop
             */
            function fitSimcy() {
                simctrl.doLayout();
            }

            $scope.fitSimcy = fitSimcy;

            /**
             * Scopefunctie: toggle fastest path
             *
             */

            /**
             * Toggle de prop van het model voor fastest path
             */
            $scope.toggleFastestPath = function () {
                if (model.werkmodus === 'volg') {
                    simulatiebericht('toggleFastestPath');
                } else {
                    toggleSimulationMode(false); //eerst uit
                    model.fastest_path = !model.fastest_path; //niet via setproperties
                    simulatiebericht('SetFastestPath', {fastest_path: model.fastest_path});
                    // checkSaveModel(true, true).then(_ => {
                    //reset simulatie
                    // simctrl.reset();
                    //en weer aan
                    toggleSimulationMode(true, lastSimFull, false, true);
                    // });
                }

            }

            /**
             * Scopefunctie: selecteer alle states
             */
            $scope.simulateSelectAll = function () {
                if (model.werkmodus === 'volg') {
                    simulatiebericht('selectAllstates');
                    return;
                }
                simctrl.selectAllStates();
            };

            /**
             * Scopefunctie: deselecteer alles in het simulatiecanvas
             */
            $scope.simulateSelectNone = function () {
                if (model.werkmodus === 'volg') {
                    simulatiebericht('selectNone');
                    return;
                }
                simctrl.selectNone();
            };

            /**
             * Scopefunctie: selecteer alle eindnodes in de simulatie
             */
            $scope.simulateSelectEndings = function () {
                if (model.werkmodus === 'volg') {
                    simulatiebericht('selectEndings');
                    return;
                }
                simctrl.selectEndings();
            };

            /**
             * Scopefunctie: selecteer een pad door de geselecteerde states
             */
            $scope.simulateSelectPad = function () {
                if (model.werkmodus === 'volg') {
                    simulatiebericht('selectPad');
                    return;
                }
                simctrl.selectPad();
            };

            /**
             * snap de states weer naar hun standaard lay-out
             * @param volger
             */
            function snapStates(volger) {
                if (model.werkmodus === 'volg') {
                    simulatiebericht('snapStates');
                    return;
                }
                model.directlogAction('snap_states', {}, 'model', 'model', false, volger);
                simctrl.doLayout();
            }

            $scope.snapStates = snapStates;

            /**
             * Probeer een mooie lay-out van de states te maken
             * @param volger
             */
            function niceStates(volger) {
                if (model.werkmodus === 'volg') {
                    simulatiebericht('niceStates');
                    return;
                }
                model.directlogAction('nice_states', {}, 'model', 'model', false, volger);
                simctrl.niceStates();
            }

            $scope.niceStates = niceStates;

            /**
             *
             */


            /**
             * Toon of verberg alle values histories
             */
            function simulateToggleValueHistory(volger) {
                if (model.werkmodus === 'volg') {
                    simulatiebericht('toggleValueHistory');
                    return;
                }
                model.directlogAction('sim_toggle_vh', {}, 'model', 'model', false, volger); //moet direct, omdat er niet per se opslag is
                simctrl.toggleAllValueHistory();
            }

            $scope.simulateToggleValueHistory = simulateToggleValueHistory;

            function simulateToggleIneqHistory(volger) {
                if (model.werkmodus === 'volg') {
                    simulatiebericht('toggleIneqHistory');
                    return;
                }
                model.directlogAction('sim_toggle_ih', {}, 'model', 'model', false, volger); //moet direct, omdat er niet per se opslag is
                simctrl.toggleAllIneqHistory();
            }

            $scope.simulateToggleIneqHistory = simulateToggleIneqHistory;

            /**
             * Scopefunctie in de samenwerkweergave - geeft een rgb-waarde terug
             * @param balans waarde 0-100 voor de disbalans. Perfecte balans = 0;
             */
            $scope.samenwerkbalans = function (balans) {
                var groen = [112, 167, 124];
                var rood = [250, 204, 46];

                var res = [];
                for (var i = 0; i < 3; i++) {
                    res.push(Math.round(groen[i] + (rood[i] - groen[i]) * (balans / 100)));
                }
                return "rgb(" + res.join(',') + ")";
            }

            /**** samenwerkdialoog met inline controller ***/
            function samenwerkdialoog() {
                var dlg = $uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    templateUrl: 'app/states/canvas/samenwerkdialoog.html',
                    controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                        /*
                         Inline controller. $scope is de scope van de dialoog, niet van de cytocanvas-controller
                         voor de rest hebben we door scoping wel alle props en injected van de maincontroller.
                         */

                        //init scope
                        function init() {
                            updateSamenwerkinfo();
                            $scope.slavecode = [];
                            $scope.meekijkmodel = model.meekijkmodel;
                            $scope.meekijker = false; //kan er iemand meekijken?
                            $scope.verplichtSingle = false; // !!modelctrl.heeftCondtags(); //dan mag het niet (4-2023: waarom niet?)

                            $scope.$on('api.notify.wijzigSamenwerkmodus', updateSamenwerkinfo);
                            $scope.$on('api.notify.clientOnline', updateSamenwerkinfo);
                        }

                        init();

                        /**
                         * Haal de samenwerkState (opnieuw) op en werk de scope bij
                         */
                        function updateSamenwerkinfo() {
                            if (model.isSaved()) {
                                $scope.st = false; //loading
                                api.samenwerkState(model.id).then(function (st) {
                                    log.debug(`samenwerkstate`, st);
                                    $scope.st = st;
                                    model.samenwerking = st.samenwerking; //meteen even bijwerken... tamelijk dirty
                                    if (st.samenwerking === 'master') {
                                        st.masteruser = api.userdata.displayname
                                    } else {
                                        st.masteruser = st.master && st.master.user;
                                    }
                                    //en door
                                    return api.checkMeekijken(model.id);
                                }).then(function (meekijker) {
                                    $scope.meekijker = meekijker && meekijker.result;
                                });
                            } else {
                                //vers, onopgeslagen model
                                $scope.st = {
                                    samenwerking: model.samenwerking //is altijd single, maar okee
                                }
                            }
                        }

                        $scope.maakmaster = function () {
                            //wil master worden, is als het goed is alleen zichtbaar als single, maar be checkt het
                            //eerst opslaan
                            saveGraph().then(function (props) {
                                if (!props) {
                                    dlg.dismiss();
                                    return;
                                }
                                return api.setSamenwerkmaster(model.id).then(function () {
                                    updateSamenwerkinfo();
                                    return api.registreerSamenwerken(model.id).then(function () {
                                        model.reload();
                                    });

                                })
                            }).catch(function () {
                                $scope.st.error = "SAMENWERKEN.ERROR"
                            });
                        };

                        $scope.maakslave = function () {
                            //wil slave worden. Als het goed is alleen zichtbaar als single. Als dit lukt, gaan we ook een ander model herladen...

                            //we slaan niet op, dat is al gebeurd. Pech bij nieuw model
                            api.createSamenwerkslave($scope.slavecode[0] + '-' + $scope.slavecode[1] + "-" + $scope.slavecode[2]).then(function (masterAndSlave) {
                                //gelukt! Slave id is het nieuwe model
                                $uibModalInstance.close(true);
                                model.load(masterAndSlave.slave); //laadt het slavemodel, dat zichzelf kopieert van de master
                                //en registreer de samenwerking op master-id
                                model.notifyId = masterAndSlave.master;
                                api.registreerSamenwerken(masterAndSlave.master);
                            }).catch(function (err) {
                                    log.warn("Fout bij maakslave", err);
                                    $scope.st.error = 'SAMENWERKEN.ERROR.' + (err.code || "ALGEMEEN");
                                }
                            );
                        };

                        /**
                         * Ontkoppel de samenwerking na bevestiging
                         */
                        $scope.ontkoppelsamenwerking = function () {
                            //(title, tekst, confirmtekst, canceltekst, windowClass)
                            sihwconfirm(__("SAMENWERKEN.ONTKOPPELEN"), __($scope.st.samenwerking === "master" ? "SAMENWERKEN.ONTKOPPELPROMPT_MASTER" : "SAMENWERKEN.ONTKOPPELPROMPT_SLAVE"), __('ALGEMEEN.JA'), __('ALGEMEEN.NEE')).then(function (conf) {
                                if (conf) {
                                    //men wil doorgaan
                                    api.ontkoppelSamenwerking(model.id).then(function () {
                                        //gewoon reloaden, dan klopt alles weer
                                        model.reload();
                                        updateSamenwerkinfo();
                                        $uibModalInstance.close(true);
                                    })
                                }
                            });
                        };

                        $scope.cancelPrompt = function () {
                            $uibModalInstance.close(false);
                        };
                    }]
                });
            }

            /*********************** Syncsim *************************************
             * Simulatie wordt synchroon gehouden binnen een samenwerking
             * Deze controller stuurt acties en handelt berichten af
             * de simulatiecontroller zelf stuurt ook wel berichten, vooral de simulatiestatus steeds
             *
             */

            /**
             * Stuur een simulatiebericht naar de leider / volger
             * @param actie
             * @param args
             */
            function simulatiebericht(actie, args) {
                var berichtargs = angular.extend({
                    brontype: model.werkmodus,
                    actie: actie,
                    actieId: api.unique + '-' + (Date.now().toString(16)), //nieuw berichtid
                    modelId: model.id,
                    notifyId: model.notifyId
                }, args);


                return api.simulatiebericht(berichtargs);
            }

            /**
             * Dispatch simulatiebericht
             * @param data
             * @param [poging2] true als we het met een timeout opnieuw proberen
             */
            function onSimulatiebericht(data, poging2) {
                if (model.werkmodus === 'leid' && data.brontype === 'volg') {
                    onSimulatievolgbericht(data);
                } else if (model.werkmodus === 'volg' && data.brontype === 'leid') {
                    onSimulatieleidbericht(data);
                } else {
                    if (poging2) {
                        log.warn("Ongeldig simulatiebericht", data);
                    } else {
                        $timeout(function () {
                            onSimulatiebericht(data, true)
                        }, 1000); //zo nog een keer
                    }
                }
            }

            /**
             * Handel simulatiebericht van volger aan leider af
             * @param data
             */
            function onSimulatievolgbericht(data) {
                switch (data.actie) {
                    case 'fullOrStop':
                        simulateFullOrStop(data.afzender);
                        break;
                    case 'snapStates':
                        snapStates(data.afzender);
                        break;
                    case 'niceStates':
                        niceStates(data.afzender);
                        break;
                    case 'nextstep':
                        simulateNextStep(data.afzender);
                        break;
                    case 'selectAllstates':
                        simctrl.selectAllStates(data.afzender);
                        break;
                    case 'selectNone':
                        simctrl.selectNone(data.afzender);
                        break;
                    case 'selectEndings':
                        simctrl.selectEndings(data.afzender);
                        break;
                    case 'selectPad':
                        simctrl.selectPad(data.afzender);
                        break;
                    case 'toggleValueHistory':
                        simulateToggleValueHistory(data.afzender);
                        break;
                    case 'toggleIneqHistory':
                        simulateToggleIneqHistory(data.afzender);
                        break;
                    case 'setSelected':
                        simctrl.setSelected(data.selected, data.selectievolgorde, data.afzender);
                        break;
                    case 'hideValueHistory':
                        simctrl.volgerHideValueHistory(data.historyNode);
                        break;
                    case 'hideIneqHistory':
                        simctrl.volgerHideIneqHistory(data.ineqkey);
                        break;
                    case 'positie':
                        simctrl.setPositie(data.posities);
                        break;
                    case 'flipSimCanvas':
                        //volger wil dat we flippen, dat doen we, en we sturen
                        flipSimCanvas();
                        simulatiebericht('flipSimCanvas', {richting: $scope.resizeDirection});
                        break;
                    case 'simfeedbackactie':
                        //volger doet een ux in de simfeedback
                        //dat regelt onze simctrl
                        simctrl.onSimfeedbackactie(data.feedbackactie, data.feedbackarg, data.afzender);
                        break;
                    case 'toggleFastestPath':
                        $scope.toggleFastestPath();
                        break;
                }
            }

            /**
             * Handel simulatiebericht van leider aan volger af
             * @param data
             */
            function onSimulatieleidbericht(data) {
                log.debug(`onSimulatiebericht`, data);
                switch (data.actie) {
                    case 'simstatus':
                        updateSimstatus(data.status);
                        break;
                    case 'positie':
                        simctrl.setPositie(data.posities);
                        break;
                    case 'flipSimCanvas':
                        flipSimCanvas(data.richting);
                        break;
                    case 'simfeedback':
                        //volgers moeten hun simfeedback bijwerken
                        modelctrl.updateSimfeedback(data.status);
                        break;
                    case 'SetFastestPath':
                        //zet even de fp property van het model goed, want dat heeft leider gedaan
                        model.fastest_path = data.fastest_path; //puur voor ux
                        break;
                }
            }

            /**
             * Leider heeft een hele simulatiestatus gestuurd. Wij gaan die updaten. Deels zelf, en deel via de simview en modelview
             * @param status
             */
            function updateSimstatus(status) {
                if ($scope.simulateOn !== status.simuleren) {
                    $scope.simulateOn = status.simuleren;
                    updateWindowSplit();
                    //de leider stuurt feedback wel naar de volgers, dus dat doen we niet hier
                    /*   //meld de modelctrl ook even iets
                       if ($scope.simulateOn)
                       {
                           modelctrl.startSimulatie();
                       }
                       else {
                           modelctrl.stopSimulatie();
                       }*/
                }
                //de rest doet de simcontrol
                simctrl.updateSimstatus(status);
                //regel ook de feedback
                modelctrl.updateSimfeedback(status.simfeedback);
            }

            /**
             * Geeft true als video getoond mag worden
             */
            $scope.magVideo = function () {
                return api.userdata && api.userdata.projectdata && api.userdata.projectdata.startvideo
                    && (api.userdata.projectdata.modelleerniveau >= 20);
            }
            /**
             * Cancel de video, kan ook vanuit allerlei knoppen e.d.
             */
            $scope.cancelVideo = function () {
                if ($scope.video.play) {
                    model.directlogAction('cancel_video', {}, 'app', 'app', true);
                }
                $scope.video.play = false;
                $scope.video.picker = false; //also

            }

            /**
             * Toggle de video via de knop
             */
            $scope.toggleVideo = function () {
                if ($scope.video.picker) {
                    //close picker
                    log.debug(`close picker`);
                    $scope.video.picker = false;
                } else if ($scope.video.play) {
                    $scope.cancelVideo();
                } else {
                    log.debug(`Toggle video voor niveau ${model.platModelleerNiveau()}`);
                    //is dit niveauplat 2+  Dan tonen we de subvideos
                    if (model.platModelleerNiveau() >= 2) {
                        log.debug(`Open picker`);
                        $scope.video.picker = true;
                    } else {
                        //play
                        $scope.initVideo();
                    }
                }
            }

            /**
             * Van videopicker
             * @param video
             */
            $scope.pickVideo = function (video) {
                if ($scope.magVideo()) {
                    //speelt er iets? dan eerst weg
                    if ($scope.video.play) {
                        $scope.video.play = false;
                        //niet via cancel ivm logging en dichtzetten picker
                        $timeout(() => $scope.pickVideo(video), 50);
                        return;
                    }
                    //we kijken of we ze hebben
                    multilangfile.getSource('/content/video', video, 'mp4', true).then(naam => {
                        if (naam) {
                            $scope.video.video = `${naam}`;
                            $scope.video.play = true;
                            log.debug(`Now playing`, $scope.video.video);
                            model.directlogAction('play_video', $scope.video, 'app', 'app', true);
                            //sluit de picker
                            $scope.video.picker = false;
                        } else {
                            log.warn(`Video url niet gevonden`);
                        }
                    });
                }
            }


            /**
             * Herstart de juiste video voor het project-niveau
             */
            $scope.initVideo = function () {
                if (!$scope.magVideo()) {
                    //uitgeschakeld
                    $scope.video.play = false;
                    return;
                }
                let niveauplat = model.platModelleerNiveau();

                $scope.video.video = null;
                switch (niveauplat) {
                    case 1:
                        //op zich komt dit niet door magVideo
                        break;
                    case 2:
                        $scope.pickVideo('level2/examples');
                        break;
                    case 3:
                        $scope.pickVideo('level3/examples');
                        break;
                    default: //anders de hoogste die er is, via pickvideo
                        $scope.pickVideo('level4/examples');
                        return;
                        break;
                }
                $scope.video.play = !!$scope.video.video;
                if ($scope.video.play) {
                    log.debug(`Now playing`, $scope.video.video);
                    model.directlogAction('play_video', $scope.video, 'app', 'app', true);
                }
            }

            /**
             * Tijdelijk: toon debuginfo in de canvas
             */
            $scope.debuginfo = function () {
                let info = api.userdata ? api.userdata.naam : "?";
                if (model) {
                    info += " ";
                    info += model.id || "?";

                    if (model.notifyId && model.notifyId !== model.id) {
                        info += " == ";
                        info += model.notifyId;
                    }
                }
                return info;
            }
        }

    ])
;
